import { saveAs } from "file-saver";
import { Configuration, Control_Panel_Sizing, Fan_Fit, Fan_Model_File, Product } from "../generated/graphql";
import { calculateInterpolatedFla, calculateMOCP } from "./generate-configuration-query";
import { getPanelSizeAndWeight } from "./calculate-custom-controls";

const root = process.env.REACT_APP_AZURE_FUNCTIONS_URL || "";

export const generateQuoteDXF = ({
  fanFitData,
  fanModelFile,
  fileName,
  products,
  sizings,
}: {
  config?: Configuration;
  fanFitData?: Fan_Fit[];
  fanModelFile?: Fan_Model_File;
  fileName: string;
  products: Product[];
  sizings?: Control_Panel_Sizing[];
}) => {
  return fetch(`${root}/api/GenerateQuoteDXF`, {
    method: "POST",
    body: JSON.stringify({
      fileName: fileName,
      fanModelFile: fanModelFile,
      products: products.filter(p => p.configuration).map((p) => {
        const config = p.configuration
        const fanFit = fanFitData?.find(
          (f) =>
            f.fan_diameter === config?.blade_diameter &&
            f.manufacturer === config?.manufacturer
        );
        const fla = calculateInterpolatedFla(config.min_v_fla, config.max_v_fla, p.voltage_override ?? config.fla_460 ?? config.fla_400)
        // const mocp = calculateMOCP({fla : config.fla_460 ?? config.fla_400, fanCount: p.configured_fan_count});
        const mocp = calculateMOCP({fla : fla, fanCount: p.configured_fan_count});
        const controlSizing = getPanelSizeAndWeight(p.configured_fan_count, mocp, sizings, p.mas_ec_plus);
        if (!fanFit) throw new Error(`No fan fit data found`);
        if (!config) throw new Error(`No configuration found`);
        return {
          Tag: p.tag,
          BulkheadWidth: p.width,
          LeftPadding: fanFit.left_padding,
          MiddleColumnPadding: fanFit.middle_column_padding,
          RightPadding: fanFit.right_padding,
          PlateDimension: config.plate_dimensions,
          BladeDiameter: config.blade_diameter,
          NumberOfFans: p.configured_fan_count,
          BulkheadHeight: p.height,
          TopPadding: fanFit.top_padding,
          MiddleRowPadding: fanFit.middle_row_padding,
          BottomPadding: fanFit.bottom_padding,
          FanDepth: config.depth,
          FanModel: config.model,
          HasDampers: p.backdraft_dampers,
          PanelWidth: controlSizing.width,
          PanelHeight: controlSizing.height,
          PanelDepth: controlSizing.depth,
          ECPlus: p.mas_ec_plus,
        };
      })
    }),
  }).then((response) => {
    if (!response.ok) {
      console.error(response);
      return Promise.reject(new Error(response.statusText));
    }
    // const contentDispositionHeader = response.headers.get('content-disposition');
    // const file_name = contentDispositionHeader ? contentDispositionHeader.split('filename=')[1] : fileName;
    const file_name = fileName;

    return response.blob().then(blob => ({ file_name, blob }));
  })
    .then(({ file_name, blob }) => {
      saveAs(blob, file_name);
    });

}
