import React, { useState } from 'react';
import { Button, Col, Input } from 'reactstrap';

import _ from 'lodash';

import { Configuration_Insert_Input, useMarkConfigurationsChangedMutation, useUpsertConfigurationMutation, useUpsertControlPanelSizingMutation, useUpsertCostsMutation, useUpsertFanFitMutation } from '../generated/graphql';
import { CostData, FanFitData, GeneralFan, GeneralFanData, isValidControlPanelFile,  isValidCostFile, isValidFanFitFile, isValidGeneralFile, Padding } from '../helpers/file-checker';

const UploadConfigurationsInput = () => {
  const [purpose, setPurpose] = useState<'general' | 'fan-fit' | 'control-panel' | 'costs'>('general');
  const [file, setFile] = useState<any>('');

  const [uploaded, setUploaded] = useState(false);

  const [upsertConfigurations, { loading: upsertingConfigurations }] = useUpsertConfigurationMutation();
  const [markConfigurations] = useMarkConfigurationsChangedMutation();
  
  const [upsertFanFits, { loading: upsertingFanFits }] = useUpsertFanFitMutation();
  const [upsertControlPanels, { loading: upsertingControlPanels }] = useUpsertControlPanelSizingMutation();
  const [upsertCosts, { loading: upsertingCostsPanels }] = useUpsertCostsMutation();

  const captureFilePurpose = (e: any) => setPurpose(e.target.value);

  const captureFile = (e: any) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = fr => setFile(fr?.target?.result);

    setUploaded(false);
  };

  const isFileValid = () => {
    console.log("IS FILE VALID ", purpose)
    console.log(file)
    if (!file) return false;

    switch (purpose) {
      case 'general':
        return isValidGeneralFile(file);
      case 'fan-fit':
        return isValidFanFitFile(file);
      case 'control-panel':
        return isValidControlPanelFile(file);
      case 'costs':
        return isValidCostFile(file);
    }
  };

  const handleGeneralData = async () => {
    const data: GeneralFanData = JSON.parse(file);

    const configurations = data.fans.map((f: GeneralFan) => {
      return {
        model: f.Model,
        manufacturer: f.Manufacturer,
        blade_diameter: f.Blade_Diameter,
        blade_material: f.Blade_Material,
        price: f.Cost,
        fan_weight: f.Fan_Weight,
        nominal_hp: f.Nominal_HP,
        max_rpm: f.Max_RPM,
        voltage: f.Voltage,
        fla_400: 0,
        fla_460: f['460 FLA'],
        plate_dimensions: f.Plate_Dimensions,
        depth: f.Depth,
        rmp_coefficients: JSON.stringify(f.RPM_Coefficients),
        damper_id: f.Damper_ID,
        k_factor: f.K_Factor,
        min_v_fla: f.Min_V_FLA,
        max_v_fla: f.Max_V_FLA,
        products: undefined,
      } as Configuration_Insert_Input;
    });

    const upsertedConfigurations = await upsertConfigurations({
      variables: {
        objects: configurations
      },
      update: (cache) => cache.evict({ fieldName: "configuration", broadcast: false })
    });

    setFile(null);
    setUploaded(true);

    const linkedConfigurations = upsertedConfigurations.data?.insert_configuration?.returning?.filter(c => !!c.products_aggregate.aggregate?.count);

    if (!!linkedConfigurations?.length) {
      markConfigurations({
        variables: {
          ids: linkedConfigurations.map(lc => lc.id)
        }
      });
    }
  };

  const handleFanFitData = async () => {
    const data: FanFitData = JSON.parse(file);

    const fanFitCollections = data.Fan_Fit.map((f) => {
      const fanFits = [];
      const diameters = Object.keys(f.Fan_Diameter);

      for (let diameter of diameters) {
        const fanDiameter = (f.Fan_Diameter as any)[diameter] as Padding;
        
        fanFits.push({
          manufacturer: f.Manufacturer,
          fan_diameter: parseInt(diameter),
          left_padding: fanDiameter.Left_Padding,
          right_padding: fanDiameter.Right_Padding,
          top_padding: fanDiameter.Top_Padding,
          bottom_padding: fanDiameter.Bottom_Padding,
          middle_column_padding: fanDiameter.Middle_Column_Padding,
          middle_row_padding: fanDiameter.Middle_Row_Padding,
        });
      }

      return fanFits;
    });

    upsertFanFits({
      variables: {
        objects: _.flatten(fanFitCollections)
      },
      update: (cache) => cache.evict({ fieldName: "fan_fit", broadcast: false })
    });

    setFile(null);
    setUploaded(true);
  };

  const handleControlPanelData = async () => {
    const panels: any = [];
    const json = JSON.parse(file);

    for (let panelsize of Object.values(json)) {
      for (let [fanArrayKey,fanArray] of Object.entries(panelsize as Object)) {
        for (let [fan_key, fan] of Object.entries(fanArray as any)) {
          if (fan_key === 'Cost') continue;
          if ((fan as any).Height === 'Custom') continue;
          if ((fan as any).Width === 'Custom') continue;
          if ((fan as any).Depth === 'Custom') continue;
          if ((fan as any).Weight === 'Custom') continue;
          panels.push({
            fan_count: parseInt(fanArrayKey.split(' ')[0] || '0', 0),
            amperage: fan_key.substring(0, fan_key.length - 1),
            cost: (fanArray as any).Cost,
            height: (fan as any).Height,
            width: (fan as any).Width,
            depth: (fan as any).Depth,
            weight: (fan as any).Weight,
          })
        }
      }
    }
  
    if (panels.length) {
      upsertControlPanels({
        variables: {
          objects: panels
        },
        update: (cache) => cache.evict({ fieldName: "control_panel_sizing", broadcast: false })
      });
    }

    setFile(null);
    setUploaded(true);
  };

  const handleCostsData = async () => {
    const json: CostData = JSON.parse(file);
    let costs = [];
    for (let [k, v] of Object.entries(json)){
      costs.push({name: k, value: v})
    }
    upsertCosts({
      variables: {
        objects: costs
      },
      update: (cache) => cache.evict({ fieldName: "cost", broadcast: false })
    });

    setFile(null);
    setUploaded(true);
  };

  const upload = () => {
    switch (purpose) {
      case 'general':
        handleGeneralData();
        break;
      case 'fan-fit':
        handleFanFitData();
        break;
      case 'control-panel':
        handleControlPanelData();
        break;
      case 'costs':
        handleCostsData();
        break;
    }
  };

  const loading = upsertingConfigurations || upsertingFanFits || upsertingControlPanels || upsertingCostsPanels;

  return (
    <div className="d-flex flex-column align-items-start mb-3">
      <h4>Upload Configurations</h4>
      <Col
        className='d-flex flex-column align-items-start'
        lg={6}
        md={6}
        sm={6}>
        <Input
          className="mb-3"
          type='select'
          value={purpose}
          onChange={captureFilePurpose}>
          <option value="general">General Fan Data</option>
          <option value="fan-fit">Fan Fit Data</option>
          <option value="control-panel">Control Panel Data</option>
          <option value="costs">Cost Data</option>
        </Input>
        <Input
          className="mb-3"
          type="file"
          accept='.json'
          onChange={captureFile}
        />
        <div className="d-flex align-items-center">
          <Button disabled={!isFileValid() || loading} onClick={upload}>Upload</Button>
          {uploaded && <p className="mx-3">Uploaded!!!</p>}
        </div>
      </Col>
    </div>
  );
};

export default UploadConfigurationsInput;