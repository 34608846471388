import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bpchar: any;
  money: any;
  numeric: any;
  timestamptz: any;
  uuid: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "bpchar". All fields are combined with logical 'AND'. */
export type Bpchar_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bpchar']>;
  _gt?: InputMaybe<Scalars['bpchar']>;
  _gte?: InputMaybe<Scalars['bpchar']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['bpchar']>;
  _in?: InputMaybe<Array<Scalars['bpchar']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['bpchar']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['bpchar']>;
  _lt?: InputMaybe<Scalars['bpchar']>;
  _lte?: InputMaybe<Scalars['bpchar']>;
  _neq?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['bpchar']>;
  _nin?: InputMaybe<Array<Scalars['bpchar']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['bpchar']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['bpchar']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['bpchar']>;
};

/** specifies technical details for a product */
export type Configuration = {
  __typename?: 'configuration';
  blade_diameter: Scalars['numeric'];
  blade_material: Scalars['String'];
  damper_id?: Maybe<Scalars['numeric']>;
  depth: Scalars['numeric'];
  fan_weight: Scalars['numeric'];
  fla_400: Scalars['numeric'];
  fla_460: Scalars['numeric'];
  has_changed: Scalars['Boolean'];
  id: Scalars['Int'];
  k_factor?: Maybe<Scalars['Int']>;
  manufacturer: Scalars['String'];
  max_rpm: Scalars['numeric'];
  max_v_fla?: Maybe<Scalars['numeric']>;
  min_v_fla?: Maybe<Scalars['numeric']>;
  model: Scalars['String'];
  nominal_hp: Scalars['numeric'];
  plate_dimensions: Scalars['numeric'];
  price: Scalars['money'];
  /** An array relationship */
  products: Array<Product>;
  /** An aggregate relationship */
  products_aggregate: Product_Aggregate;
  rmp_coefficients: Scalars['String'];
  voltage: Scalars['numeric'];
};


/** specifies technical details for a product */
export type ConfigurationProductsArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


/** specifies technical details for a product */
export type ConfigurationProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};

/** aggregated selection of "configuration" */
export type Configuration_Aggregate = {
  __typename?: 'configuration_aggregate';
  aggregate?: Maybe<Configuration_Aggregate_Fields>;
  nodes: Array<Configuration>;
};

/** aggregate fields of "configuration" */
export type Configuration_Aggregate_Fields = {
  __typename?: 'configuration_aggregate_fields';
  avg?: Maybe<Configuration_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Configuration_Max_Fields>;
  min?: Maybe<Configuration_Min_Fields>;
  stddev?: Maybe<Configuration_Stddev_Fields>;
  stddev_pop?: Maybe<Configuration_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Configuration_Stddev_Samp_Fields>;
  sum?: Maybe<Configuration_Sum_Fields>;
  var_pop?: Maybe<Configuration_Var_Pop_Fields>;
  var_samp?: Maybe<Configuration_Var_Samp_Fields>;
  variance?: Maybe<Configuration_Variance_Fields>;
};


/** aggregate fields of "configuration" */
export type Configuration_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Configuration_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Configuration_Avg_Fields = {
  __typename?: 'configuration_avg_fields';
  blade_diameter?: Maybe<Scalars['Float']>;
  damper_id?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  fan_weight?: Maybe<Scalars['Float']>;
  fla_400?: Maybe<Scalars['Float']>;
  fla_460?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  k_factor?: Maybe<Scalars['Float']>;
  max_rpm?: Maybe<Scalars['Float']>;
  max_v_fla?: Maybe<Scalars['Float']>;
  min_v_fla?: Maybe<Scalars['Float']>;
  nominal_hp?: Maybe<Scalars['Float']>;
  plate_dimensions?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  voltage?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "configuration". All fields are combined with a logical 'AND'. */
export type Configuration_Bool_Exp = {
  _and?: InputMaybe<Array<Configuration_Bool_Exp>>;
  _not?: InputMaybe<Configuration_Bool_Exp>;
  _or?: InputMaybe<Array<Configuration_Bool_Exp>>;
  blade_diameter?: InputMaybe<Numeric_Comparison_Exp>;
  blade_material?: InputMaybe<String_Comparison_Exp>;
  damper_id?: InputMaybe<Numeric_Comparison_Exp>;
  depth?: InputMaybe<Numeric_Comparison_Exp>;
  fan_weight?: InputMaybe<Numeric_Comparison_Exp>;
  fla_400?: InputMaybe<Numeric_Comparison_Exp>;
  fla_460?: InputMaybe<Numeric_Comparison_Exp>;
  has_changed?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  k_factor?: InputMaybe<Int_Comparison_Exp>;
  manufacturer?: InputMaybe<String_Comparison_Exp>;
  max_rpm?: InputMaybe<Numeric_Comparison_Exp>;
  max_v_fla?: InputMaybe<Numeric_Comparison_Exp>;
  min_v_fla?: InputMaybe<Numeric_Comparison_Exp>;
  model?: InputMaybe<String_Comparison_Exp>;
  nominal_hp?: InputMaybe<Numeric_Comparison_Exp>;
  plate_dimensions?: InputMaybe<Numeric_Comparison_Exp>;
  price?: InputMaybe<Money_Comparison_Exp>;
  products?: InputMaybe<Product_Bool_Exp>;
  products_aggregate?: InputMaybe<Product_Aggregate_Bool_Exp>;
  rmp_coefficients?: InputMaybe<String_Comparison_Exp>;
  voltage?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "configuration" */
export enum Configuration_Constraint {
  /** unique or primary key constraint on columns "model", "manufacturer" */
  ConfigurationManufacturerModelKey = 'configuration_manufacturer_model_key',
  /** unique or primary key constraint on columns "id" */
  ConfigurationPkey = 'configuration_pkey'
}

/** input type for incrementing numeric columns in table "configuration" */
export type Configuration_Inc_Input = {
  blade_diameter?: InputMaybe<Scalars['numeric']>;
  damper_id?: InputMaybe<Scalars['numeric']>;
  depth?: InputMaybe<Scalars['numeric']>;
  fan_weight?: InputMaybe<Scalars['numeric']>;
  fla_400?: InputMaybe<Scalars['numeric']>;
  fla_460?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  k_factor?: InputMaybe<Scalars['Int']>;
  max_rpm?: InputMaybe<Scalars['numeric']>;
  max_v_fla?: InputMaybe<Scalars['numeric']>;
  min_v_fla?: InputMaybe<Scalars['numeric']>;
  nominal_hp?: InputMaybe<Scalars['numeric']>;
  plate_dimensions?: InputMaybe<Scalars['numeric']>;
  price?: InputMaybe<Scalars['money']>;
  voltage?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "configuration" */
export type Configuration_Insert_Input = {
  blade_diameter?: InputMaybe<Scalars['numeric']>;
  blade_material?: InputMaybe<Scalars['String']>;
  damper_id?: InputMaybe<Scalars['numeric']>;
  depth?: InputMaybe<Scalars['numeric']>;
  fan_weight?: InputMaybe<Scalars['numeric']>;
  fla_400?: InputMaybe<Scalars['numeric']>;
  fla_460?: InputMaybe<Scalars['numeric']>;
  has_changed?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  k_factor?: InputMaybe<Scalars['Int']>;
  manufacturer?: InputMaybe<Scalars['String']>;
  max_rpm?: InputMaybe<Scalars['numeric']>;
  max_v_fla?: InputMaybe<Scalars['numeric']>;
  min_v_fla?: InputMaybe<Scalars['numeric']>;
  model?: InputMaybe<Scalars['String']>;
  nominal_hp?: InputMaybe<Scalars['numeric']>;
  plate_dimensions?: InputMaybe<Scalars['numeric']>;
  price?: InputMaybe<Scalars['money']>;
  products?: InputMaybe<Product_Arr_Rel_Insert_Input>;
  rmp_coefficients?: InputMaybe<Scalars['String']>;
  voltage?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Configuration_Max_Fields = {
  __typename?: 'configuration_max_fields';
  blade_diameter?: Maybe<Scalars['numeric']>;
  blade_material?: Maybe<Scalars['String']>;
  damper_id?: Maybe<Scalars['numeric']>;
  depth?: Maybe<Scalars['numeric']>;
  fan_weight?: Maybe<Scalars['numeric']>;
  fla_400?: Maybe<Scalars['numeric']>;
  fla_460?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  k_factor?: Maybe<Scalars['Int']>;
  manufacturer?: Maybe<Scalars['String']>;
  max_rpm?: Maybe<Scalars['numeric']>;
  max_v_fla?: Maybe<Scalars['numeric']>;
  min_v_fla?: Maybe<Scalars['numeric']>;
  model?: Maybe<Scalars['String']>;
  nominal_hp?: Maybe<Scalars['numeric']>;
  plate_dimensions?: Maybe<Scalars['numeric']>;
  price?: Maybe<Scalars['money']>;
  rmp_coefficients?: Maybe<Scalars['String']>;
  voltage?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Configuration_Min_Fields = {
  __typename?: 'configuration_min_fields';
  blade_diameter?: Maybe<Scalars['numeric']>;
  blade_material?: Maybe<Scalars['String']>;
  damper_id?: Maybe<Scalars['numeric']>;
  depth?: Maybe<Scalars['numeric']>;
  fan_weight?: Maybe<Scalars['numeric']>;
  fla_400?: Maybe<Scalars['numeric']>;
  fla_460?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  k_factor?: Maybe<Scalars['Int']>;
  manufacturer?: Maybe<Scalars['String']>;
  max_rpm?: Maybe<Scalars['numeric']>;
  max_v_fla?: Maybe<Scalars['numeric']>;
  min_v_fla?: Maybe<Scalars['numeric']>;
  model?: Maybe<Scalars['String']>;
  nominal_hp?: Maybe<Scalars['numeric']>;
  plate_dimensions?: Maybe<Scalars['numeric']>;
  price?: Maybe<Scalars['money']>;
  rmp_coefficients?: Maybe<Scalars['String']>;
  voltage?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "configuration" */
export type Configuration_Mutation_Response = {
  __typename?: 'configuration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Configuration>;
};

/** input type for inserting object relation for remote table "configuration" */
export type Configuration_Obj_Rel_Insert_Input = {
  data: Configuration_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Configuration_On_Conflict>;
};

/** on_conflict condition type for table "configuration" */
export type Configuration_On_Conflict = {
  constraint: Configuration_Constraint;
  update_columns?: Array<Configuration_Update_Column>;
  where?: InputMaybe<Configuration_Bool_Exp>;
};

/** Ordering options when selecting data from "configuration". */
export type Configuration_Order_By = {
  blade_diameter?: InputMaybe<Order_By>;
  blade_material?: InputMaybe<Order_By>;
  damper_id?: InputMaybe<Order_By>;
  depth?: InputMaybe<Order_By>;
  fan_weight?: InputMaybe<Order_By>;
  fla_400?: InputMaybe<Order_By>;
  fla_460?: InputMaybe<Order_By>;
  has_changed?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  k_factor?: InputMaybe<Order_By>;
  manufacturer?: InputMaybe<Order_By>;
  max_rpm?: InputMaybe<Order_By>;
  max_v_fla?: InputMaybe<Order_By>;
  min_v_fla?: InputMaybe<Order_By>;
  model?: InputMaybe<Order_By>;
  nominal_hp?: InputMaybe<Order_By>;
  plate_dimensions?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  products_aggregate?: InputMaybe<Product_Aggregate_Order_By>;
  rmp_coefficients?: InputMaybe<Order_By>;
  voltage?: InputMaybe<Order_By>;
};

/** primary key columns input for table: configuration */
export type Configuration_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "configuration" */
export enum Configuration_Select_Column {
  /** column name */
  BladeDiameter = 'blade_diameter',
  /** column name */
  BladeMaterial = 'blade_material',
  /** column name */
  DamperId = 'damper_id',
  /** column name */
  Depth = 'depth',
  /** column name */
  FanWeight = 'fan_weight',
  /** column name */
  Fla_400 = 'fla_400',
  /** column name */
  Fla_460 = 'fla_460',
  /** column name */
  HasChanged = 'has_changed',
  /** column name */
  Id = 'id',
  /** column name */
  KFactor = 'k_factor',
  /** column name */
  Manufacturer = 'manufacturer',
  /** column name */
  MaxRpm = 'max_rpm',
  /** column name */
  MaxVFla = 'max_v_fla',
  /** column name */
  MinVFla = 'min_v_fla',
  /** column name */
  Model = 'model',
  /** column name */
  NominalHp = 'nominal_hp',
  /** column name */
  PlateDimensions = 'plate_dimensions',
  /** column name */
  Price = 'price',
  /** column name */
  RmpCoefficients = 'rmp_coefficients',
  /** column name */
  Voltage = 'voltage'
}

/** input type for updating data in table "configuration" */
export type Configuration_Set_Input = {
  blade_diameter?: InputMaybe<Scalars['numeric']>;
  blade_material?: InputMaybe<Scalars['String']>;
  damper_id?: InputMaybe<Scalars['numeric']>;
  depth?: InputMaybe<Scalars['numeric']>;
  fan_weight?: InputMaybe<Scalars['numeric']>;
  fla_400?: InputMaybe<Scalars['numeric']>;
  fla_460?: InputMaybe<Scalars['numeric']>;
  has_changed?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  k_factor?: InputMaybe<Scalars['Int']>;
  manufacturer?: InputMaybe<Scalars['String']>;
  max_rpm?: InputMaybe<Scalars['numeric']>;
  max_v_fla?: InputMaybe<Scalars['numeric']>;
  min_v_fla?: InputMaybe<Scalars['numeric']>;
  model?: InputMaybe<Scalars['String']>;
  nominal_hp?: InputMaybe<Scalars['numeric']>;
  plate_dimensions?: InputMaybe<Scalars['numeric']>;
  price?: InputMaybe<Scalars['money']>;
  rmp_coefficients?: InputMaybe<Scalars['String']>;
  voltage?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Configuration_Stddev_Fields = {
  __typename?: 'configuration_stddev_fields';
  blade_diameter?: Maybe<Scalars['Float']>;
  damper_id?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  fan_weight?: Maybe<Scalars['Float']>;
  fla_400?: Maybe<Scalars['Float']>;
  fla_460?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  k_factor?: Maybe<Scalars['Float']>;
  max_rpm?: Maybe<Scalars['Float']>;
  max_v_fla?: Maybe<Scalars['Float']>;
  min_v_fla?: Maybe<Scalars['Float']>;
  nominal_hp?: Maybe<Scalars['Float']>;
  plate_dimensions?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  voltage?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Configuration_Stddev_Pop_Fields = {
  __typename?: 'configuration_stddev_pop_fields';
  blade_diameter?: Maybe<Scalars['Float']>;
  damper_id?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  fan_weight?: Maybe<Scalars['Float']>;
  fla_400?: Maybe<Scalars['Float']>;
  fla_460?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  k_factor?: Maybe<Scalars['Float']>;
  max_rpm?: Maybe<Scalars['Float']>;
  max_v_fla?: Maybe<Scalars['Float']>;
  min_v_fla?: Maybe<Scalars['Float']>;
  nominal_hp?: Maybe<Scalars['Float']>;
  plate_dimensions?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  voltage?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Configuration_Stddev_Samp_Fields = {
  __typename?: 'configuration_stddev_samp_fields';
  blade_diameter?: Maybe<Scalars['Float']>;
  damper_id?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  fan_weight?: Maybe<Scalars['Float']>;
  fla_400?: Maybe<Scalars['Float']>;
  fla_460?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  k_factor?: Maybe<Scalars['Float']>;
  max_rpm?: Maybe<Scalars['Float']>;
  max_v_fla?: Maybe<Scalars['Float']>;
  min_v_fla?: Maybe<Scalars['Float']>;
  nominal_hp?: Maybe<Scalars['Float']>;
  plate_dimensions?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  voltage?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "configuration" */
export type Configuration_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Configuration_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Configuration_Stream_Cursor_Value_Input = {
  blade_diameter?: InputMaybe<Scalars['numeric']>;
  blade_material?: InputMaybe<Scalars['String']>;
  damper_id?: InputMaybe<Scalars['numeric']>;
  depth?: InputMaybe<Scalars['numeric']>;
  fan_weight?: InputMaybe<Scalars['numeric']>;
  fla_400?: InputMaybe<Scalars['numeric']>;
  fla_460?: InputMaybe<Scalars['numeric']>;
  has_changed?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  k_factor?: InputMaybe<Scalars['Int']>;
  manufacturer?: InputMaybe<Scalars['String']>;
  max_rpm?: InputMaybe<Scalars['numeric']>;
  max_v_fla?: InputMaybe<Scalars['numeric']>;
  min_v_fla?: InputMaybe<Scalars['numeric']>;
  model?: InputMaybe<Scalars['String']>;
  nominal_hp?: InputMaybe<Scalars['numeric']>;
  plate_dimensions?: InputMaybe<Scalars['numeric']>;
  price?: InputMaybe<Scalars['money']>;
  rmp_coefficients?: InputMaybe<Scalars['String']>;
  voltage?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Configuration_Sum_Fields = {
  __typename?: 'configuration_sum_fields';
  blade_diameter?: Maybe<Scalars['numeric']>;
  damper_id?: Maybe<Scalars['numeric']>;
  depth?: Maybe<Scalars['numeric']>;
  fan_weight?: Maybe<Scalars['numeric']>;
  fla_400?: Maybe<Scalars['numeric']>;
  fla_460?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  k_factor?: Maybe<Scalars['Int']>;
  max_rpm?: Maybe<Scalars['numeric']>;
  max_v_fla?: Maybe<Scalars['numeric']>;
  min_v_fla?: Maybe<Scalars['numeric']>;
  nominal_hp?: Maybe<Scalars['numeric']>;
  plate_dimensions?: Maybe<Scalars['numeric']>;
  price?: Maybe<Scalars['money']>;
  voltage?: Maybe<Scalars['numeric']>;
};

/** update columns of table "configuration" */
export enum Configuration_Update_Column {
  /** column name */
  BladeDiameter = 'blade_diameter',
  /** column name */
  BladeMaterial = 'blade_material',
  /** column name */
  DamperId = 'damper_id',
  /** column name */
  Depth = 'depth',
  /** column name */
  FanWeight = 'fan_weight',
  /** column name */
  Fla_400 = 'fla_400',
  /** column name */
  Fla_460 = 'fla_460',
  /** column name */
  HasChanged = 'has_changed',
  /** column name */
  Id = 'id',
  /** column name */
  KFactor = 'k_factor',
  /** column name */
  Manufacturer = 'manufacturer',
  /** column name */
  MaxRpm = 'max_rpm',
  /** column name */
  MaxVFla = 'max_v_fla',
  /** column name */
  MinVFla = 'min_v_fla',
  /** column name */
  Model = 'model',
  /** column name */
  NominalHp = 'nominal_hp',
  /** column name */
  PlateDimensions = 'plate_dimensions',
  /** column name */
  Price = 'price',
  /** column name */
  RmpCoefficients = 'rmp_coefficients',
  /** column name */
  Voltage = 'voltage'
}

export type Configuration_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Configuration_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Configuration_Set_Input>;
  /** filter the rows which have to be updated */
  where: Configuration_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Configuration_Var_Pop_Fields = {
  __typename?: 'configuration_var_pop_fields';
  blade_diameter?: Maybe<Scalars['Float']>;
  damper_id?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  fan_weight?: Maybe<Scalars['Float']>;
  fla_400?: Maybe<Scalars['Float']>;
  fla_460?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  k_factor?: Maybe<Scalars['Float']>;
  max_rpm?: Maybe<Scalars['Float']>;
  max_v_fla?: Maybe<Scalars['Float']>;
  min_v_fla?: Maybe<Scalars['Float']>;
  nominal_hp?: Maybe<Scalars['Float']>;
  plate_dimensions?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  voltage?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Configuration_Var_Samp_Fields = {
  __typename?: 'configuration_var_samp_fields';
  blade_diameter?: Maybe<Scalars['Float']>;
  damper_id?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  fan_weight?: Maybe<Scalars['Float']>;
  fla_400?: Maybe<Scalars['Float']>;
  fla_460?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  k_factor?: Maybe<Scalars['Float']>;
  max_rpm?: Maybe<Scalars['Float']>;
  max_v_fla?: Maybe<Scalars['Float']>;
  min_v_fla?: Maybe<Scalars['Float']>;
  nominal_hp?: Maybe<Scalars['Float']>;
  plate_dimensions?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  voltage?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Configuration_Variance_Fields = {
  __typename?: 'configuration_variance_fields';
  blade_diameter?: Maybe<Scalars['Float']>;
  damper_id?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  fan_weight?: Maybe<Scalars['Float']>;
  fla_400?: Maybe<Scalars['Float']>;
  fla_460?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  k_factor?: Maybe<Scalars['Float']>;
  max_rpm?: Maybe<Scalars['Float']>;
  max_v_fla?: Maybe<Scalars['Float']>;
  min_v_fla?: Maybe<Scalars['Float']>;
  nominal_hp?: Maybe<Scalars['Float']>;
  plate_dimensions?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  voltage?: Maybe<Scalars['Float']>;
};

/** defines the known control panel sizes, imported from a json configuration file */
export type Control_Panel_Sizing = {
  __typename?: 'control_panel_sizing';
  amperage: Scalars['Int'];
  cost: Scalars['Int'];
  depth: Scalars['Int'];
  fan_count: Scalars['Int'];
  height: Scalars['Int'];
  id: Scalars['Int'];
  weight: Scalars['Int'];
  width: Scalars['Int'];
};

/** aggregated selection of "control_panel_sizing" */
export type Control_Panel_Sizing_Aggregate = {
  __typename?: 'control_panel_sizing_aggregate';
  aggregate?: Maybe<Control_Panel_Sizing_Aggregate_Fields>;
  nodes: Array<Control_Panel_Sizing>;
};

/** aggregate fields of "control_panel_sizing" */
export type Control_Panel_Sizing_Aggregate_Fields = {
  __typename?: 'control_panel_sizing_aggregate_fields';
  avg?: Maybe<Control_Panel_Sizing_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Control_Panel_Sizing_Max_Fields>;
  min?: Maybe<Control_Panel_Sizing_Min_Fields>;
  stddev?: Maybe<Control_Panel_Sizing_Stddev_Fields>;
  stddev_pop?: Maybe<Control_Panel_Sizing_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Control_Panel_Sizing_Stddev_Samp_Fields>;
  sum?: Maybe<Control_Panel_Sizing_Sum_Fields>;
  var_pop?: Maybe<Control_Panel_Sizing_Var_Pop_Fields>;
  var_samp?: Maybe<Control_Panel_Sizing_Var_Samp_Fields>;
  variance?: Maybe<Control_Panel_Sizing_Variance_Fields>;
};


/** aggregate fields of "control_panel_sizing" */
export type Control_Panel_Sizing_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Control_Panel_Sizing_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Control_Panel_Sizing_Avg_Fields = {
  __typename?: 'control_panel_sizing_avg_fields';
  amperage?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  fan_count?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "control_panel_sizing". All fields are combined with a logical 'AND'. */
export type Control_Panel_Sizing_Bool_Exp = {
  _and?: InputMaybe<Array<Control_Panel_Sizing_Bool_Exp>>;
  _not?: InputMaybe<Control_Panel_Sizing_Bool_Exp>;
  _or?: InputMaybe<Array<Control_Panel_Sizing_Bool_Exp>>;
  amperage?: InputMaybe<Int_Comparison_Exp>;
  cost?: InputMaybe<Int_Comparison_Exp>;
  depth?: InputMaybe<Int_Comparison_Exp>;
  fan_count?: InputMaybe<Int_Comparison_Exp>;
  height?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  weight?: InputMaybe<Int_Comparison_Exp>;
  width?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "control_panel_sizing" */
export enum Control_Panel_Sizing_Constraint {
  /** unique or primary key constraint on columns "amperage", "fan_count" */
  ControlPanelSizingFanCountAmperageKey = 'control_panel_sizing_fan_count_amperage_key',
  /** unique or primary key constraint on columns "id" */
  ControlPanelSizingPkey = 'control_panel_sizing_pkey'
}

/** input type for incrementing numeric columns in table "control_panel_sizing" */
export type Control_Panel_Sizing_Inc_Input = {
  amperage?: InputMaybe<Scalars['Int']>;
  cost?: InputMaybe<Scalars['Int']>;
  depth?: InputMaybe<Scalars['Int']>;
  fan_count?: InputMaybe<Scalars['Int']>;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  weight?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "control_panel_sizing" */
export type Control_Panel_Sizing_Insert_Input = {
  amperage?: InputMaybe<Scalars['Int']>;
  cost?: InputMaybe<Scalars['Int']>;
  depth?: InputMaybe<Scalars['Int']>;
  fan_count?: InputMaybe<Scalars['Int']>;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  weight?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Control_Panel_Sizing_Max_Fields = {
  __typename?: 'control_panel_sizing_max_fields';
  amperage?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['Int']>;
  depth?: Maybe<Scalars['Int']>;
  fan_count?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Control_Panel_Sizing_Min_Fields = {
  __typename?: 'control_panel_sizing_min_fields';
  amperage?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['Int']>;
  depth?: Maybe<Scalars['Int']>;
  fan_count?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "control_panel_sizing" */
export type Control_Panel_Sizing_Mutation_Response = {
  __typename?: 'control_panel_sizing_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Control_Panel_Sizing>;
};

/** on_conflict condition type for table "control_panel_sizing" */
export type Control_Panel_Sizing_On_Conflict = {
  constraint: Control_Panel_Sizing_Constraint;
  update_columns?: Array<Control_Panel_Sizing_Update_Column>;
  where?: InputMaybe<Control_Panel_Sizing_Bool_Exp>;
};

/** Ordering options when selecting data from "control_panel_sizing". */
export type Control_Panel_Sizing_Order_By = {
  amperage?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  depth?: InputMaybe<Order_By>;
  fan_count?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** primary key columns input for table: control_panel_sizing */
export type Control_Panel_Sizing_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "control_panel_sizing" */
export enum Control_Panel_Sizing_Select_Column {
  /** column name */
  Amperage = 'amperage',
  /** column name */
  Cost = 'cost',
  /** column name */
  Depth = 'depth',
  /** column name */
  FanCount = 'fan_count',
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  Weight = 'weight',
  /** column name */
  Width = 'width'
}

/** input type for updating data in table "control_panel_sizing" */
export type Control_Panel_Sizing_Set_Input = {
  amperage?: InputMaybe<Scalars['Int']>;
  cost?: InputMaybe<Scalars['Int']>;
  depth?: InputMaybe<Scalars['Int']>;
  fan_count?: InputMaybe<Scalars['Int']>;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  weight?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Control_Panel_Sizing_Stddev_Fields = {
  __typename?: 'control_panel_sizing_stddev_fields';
  amperage?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  fan_count?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Control_Panel_Sizing_Stddev_Pop_Fields = {
  __typename?: 'control_panel_sizing_stddev_pop_fields';
  amperage?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  fan_count?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Control_Panel_Sizing_Stddev_Samp_Fields = {
  __typename?: 'control_panel_sizing_stddev_samp_fields';
  amperage?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  fan_count?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "control_panel_sizing" */
export type Control_Panel_Sizing_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Control_Panel_Sizing_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Control_Panel_Sizing_Stream_Cursor_Value_Input = {
  amperage?: InputMaybe<Scalars['Int']>;
  cost?: InputMaybe<Scalars['Int']>;
  depth?: InputMaybe<Scalars['Int']>;
  fan_count?: InputMaybe<Scalars['Int']>;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  weight?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Control_Panel_Sizing_Sum_Fields = {
  __typename?: 'control_panel_sizing_sum_fields';
  amperage?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['Int']>;
  depth?: Maybe<Scalars['Int']>;
  fan_count?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

/** update columns of table "control_panel_sizing" */
export enum Control_Panel_Sizing_Update_Column {
  /** column name */
  Amperage = 'amperage',
  /** column name */
  Cost = 'cost',
  /** column name */
  Depth = 'depth',
  /** column name */
  FanCount = 'fan_count',
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  Weight = 'weight',
  /** column name */
  Width = 'width'
}

export type Control_Panel_Sizing_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Control_Panel_Sizing_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Control_Panel_Sizing_Set_Input>;
  /** filter the rows which have to be updated */
  where: Control_Panel_Sizing_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Control_Panel_Sizing_Var_Pop_Fields = {
  __typename?: 'control_panel_sizing_var_pop_fields';
  amperage?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  fan_count?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Control_Panel_Sizing_Var_Samp_Fields = {
  __typename?: 'control_panel_sizing_var_samp_fields';
  amperage?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  fan_count?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Control_Panel_Sizing_Variance_Fields = {
  __typename?: 'control_panel_sizing_variance_fields';
  amperage?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  fan_count?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** This tables contains pricing keys and values */
export type Cost = {
  __typename?: 'cost';
  id: Scalars['Int'];
  name: Scalars['String'];
  value: Scalars['money'];
};

/** aggregated selection of "cost" */
export type Cost_Aggregate = {
  __typename?: 'cost_aggregate';
  aggregate?: Maybe<Cost_Aggregate_Fields>;
  nodes: Array<Cost>;
};

/** aggregate fields of "cost" */
export type Cost_Aggregate_Fields = {
  __typename?: 'cost_aggregate_fields';
  avg?: Maybe<Cost_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Cost_Max_Fields>;
  min?: Maybe<Cost_Min_Fields>;
  stddev?: Maybe<Cost_Stddev_Fields>;
  stddev_pop?: Maybe<Cost_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Cost_Stddev_Samp_Fields>;
  sum?: Maybe<Cost_Sum_Fields>;
  var_pop?: Maybe<Cost_Var_Pop_Fields>;
  var_samp?: Maybe<Cost_Var_Samp_Fields>;
  variance?: Maybe<Cost_Variance_Fields>;
};


/** aggregate fields of "cost" */
export type Cost_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Cost_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Cost_Avg_Fields = {
  __typename?: 'cost_avg_fields';
  id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "cost". All fields are combined with a logical 'AND'. */
export type Cost_Bool_Exp = {
  _and?: InputMaybe<Array<Cost_Bool_Exp>>;
  _not?: InputMaybe<Cost_Bool_Exp>;
  _or?: InputMaybe<Array<Cost_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<Money_Comparison_Exp>;
};

/** unique or primary key constraints on table "cost" */
export enum Cost_Constraint {
  /** unique or primary key constraint on columns "name" */
  CostNameKey = 'cost_name_key',
  /** unique or primary key constraint on columns "id" */
  CostPkey = 'cost_pkey'
}

/** input type for incrementing numeric columns in table "cost" */
export type Cost_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['money']>;
};

/** input type for inserting data into table "cost" */
export type Cost_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['money']>;
};

/** aggregate max on columns */
export type Cost_Max_Fields = {
  __typename?: 'cost_max_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['money']>;
};

/** aggregate min on columns */
export type Cost_Min_Fields = {
  __typename?: 'cost_min_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['money']>;
};

/** response of any mutation on the table "cost" */
export type Cost_Mutation_Response = {
  __typename?: 'cost_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Cost>;
};

/** on_conflict condition type for table "cost" */
export type Cost_On_Conflict = {
  constraint: Cost_Constraint;
  update_columns?: Array<Cost_Update_Column>;
  where?: InputMaybe<Cost_Bool_Exp>;
};

/** Ordering options when selecting data from "cost". */
export type Cost_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: cost */
export type Cost_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "cost" */
export enum Cost_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "cost" */
export type Cost_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['money']>;
};

/** aggregate stddev on columns */
export type Cost_Stddev_Fields = {
  __typename?: 'cost_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Cost_Stddev_Pop_Fields = {
  __typename?: 'cost_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Cost_Stddev_Samp_Fields = {
  __typename?: 'cost_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "cost" */
export type Cost_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cost_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Cost_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['money']>;
};

/** aggregate sum on columns */
export type Cost_Sum_Fields = {
  __typename?: 'cost_sum_fields';
  id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['money']>;
};

/** update columns of table "cost" */
export enum Cost_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value'
}

export type Cost_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Cost_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Cost_Set_Input>;
  /** filter the rows which have to be updated */
  where: Cost_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Cost_Var_Pop_Fields = {
  __typename?: 'cost_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Cost_Var_Samp_Fields = {
  __typename?: 'cost_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Cost_Variance_Fields = {
  __typename?: 'cost_variance_fields';
  id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "fan_fit" */
export type Fan_Fit = {
  __typename?: 'fan_fit';
  bottom_padding: Scalars['numeric'];
  fan_diameter: Scalars['numeric'];
  id: Scalars['Int'];
  left_padding: Scalars['numeric'];
  manufacturer: Scalars['String'];
  middle_column_padding: Scalars['numeric'];
  middle_row_padding: Scalars['numeric'];
  right_padding: Scalars['numeric'];
  top_padding: Scalars['numeric'];
};

/** aggregated selection of "fan_fit" */
export type Fan_Fit_Aggregate = {
  __typename?: 'fan_fit_aggregate';
  aggregate?: Maybe<Fan_Fit_Aggregate_Fields>;
  nodes: Array<Fan_Fit>;
};

/** aggregate fields of "fan_fit" */
export type Fan_Fit_Aggregate_Fields = {
  __typename?: 'fan_fit_aggregate_fields';
  avg?: Maybe<Fan_Fit_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Fan_Fit_Max_Fields>;
  min?: Maybe<Fan_Fit_Min_Fields>;
  stddev?: Maybe<Fan_Fit_Stddev_Fields>;
  stddev_pop?: Maybe<Fan_Fit_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Fan_Fit_Stddev_Samp_Fields>;
  sum?: Maybe<Fan_Fit_Sum_Fields>;
  var_pop?: Maybe<Fan_Fit_Var_Pop_Fields>;
  var_samp?: Maybe<Fan_Fit_Var_Samp_Fields>;
  variance?: Maybe<Fan_Fit_Variance_Fields>;
};


/** aggregate fields of "fan_fit" */
export type Fan_Fit_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Fan_Fit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Fan_Fit_Avg_Fields = {
  __typename?: 'fan_fit_avg_fields';
  bottom_padding?: Maybe<Scalars['Float']>;
  fan_diameter?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  left_padding?: Maybe<Scalars['Float']>;
  middle_column_padding?: Maybe<Scalars['Float']>;
  middle_row_padding?: Maybe<Scalars['Float']>;
  right_padding?: Maybe<Scalars['Float']>;
  top_padding?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "fan_fit". All fields are combined with a logical 'AND'. */
export type Fan_Fit_Bool_Exp = {
  _and?: InputMaybe<Array<Fan_Fit_Bool_Exp>>;
  _not?: InputMaybe<Fan_Fit_Bool_Exp>;
  _or?: InputMaybe<Array<Fan_Fit_Bool_Exp>>;
  bottom_padding?: InputMaybe<Numeric_Comparison_Exp>;
  fan_diameter?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  left_padding?: InputMaybe<Numeric_Comparison_Exp>;
  manufacturer?: InputMaybe<String_Comparison_Exp>;
  middle_column_padding?: InputMaybe<Numeric_Comparison_Exp>;
  middle_row_padding?: InputMaybe<Numeric_Comparison_Exp>;
  right_padding?: InputMaybe<Numeric_Comparison_Exp>;
  top_padding?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "fan_fit" */
export enum Fan_Fit_Constraint {
  /** unique or primary key constraint on columns "fan_diameter", "manufacturer" */
  FanFitManufacturerFanDiameterKey = 'fan_fit_manufacturer_fan_diameter_key',
  /** unique or primary key constraint on columns "id" */
  FanFitPkey = 'fan_fit_pkey'
}

/** input type for incrementing numeric columns in table "fan_fit" */
export type Fan_Fit_Inc_Input = {
  bottom_padding?: InputMaybe<Scalars['numeric']>;
  fan_diameter?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  left_padding?: InputMaybe<Scalars['numeric']>;
  middle_column_padding?: InputMaybe<Scalars['numeric']>;
  middle_row_padding?: InputMaybe<Scalars['numeric']>;
  right_padding?: InputMaybe<Scalars['numeric']>;
  top_padding?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "fan_fit" */
export type Fan_Fit_Insert_Input = {
  bottom_padding?: InputMaybe<Scalars['numeric']>;
  fan_diameter?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  left_padding?: InputMaybe<Scalars['numeric']>;
  manufacturer?: InputMaybe<Scalars['String']>;
  middle_column_padding?: InputMaybe<Scalars['numeric']>;
  middle_row_padding?: InputMaybe<Scalars['numeric']>;
  right_padding?: InputMaybe<Scalars['numeric']>;
  top_padding?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Fan_Fit_Max_Fields = {
  __typename?: 'fan_fit_max_fields';
  bottom_padding?: Maybe<Scalars['numeric']>;
  fan_diameter?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  left_padding?: Maybe<Scalars['numeric']>;
  manufacturer?: Maybe<Scalars['String']>;
  middle_column_padding?: Maybe<Scalars['numeric']>;
  middle_row_padding?: Maybe<Scalars['numeric']>;
  right_padding?: Maybe<Scalars['numeric']>;
  top_padding?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Fan_Fit_Min_Fields = {
  __typename?: 'fan_fit_min_fields';
  bottom_padding?: Maybe<Scalars['numeric']>;
  fan_diameter?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  left_padding?: Maybe<Scalars['numeric']>;
  manufacturer?: Maybe<Scalars['String']>;
  middle_column_padding?: Maybe<Scalars['numeric']>;
  middle_row_padding?: Maybe<Scalars['numeric']>;
  right_padding?: Maybe<Scalars['numeric']>;
  top_padding?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "fan_fit" */
export type Fan_Fit_Mutation_Response = {
  __typename?: 'fan_fit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Fan_Fit>;
};

/** on_conflict condition type for table "fan_fit" */
export type Fan_Fit_On_Conflict = {
  constraint: Fan_Fit_Constraint;
  update_columns?: Array<Fan_Fit_Update_Column>;
  where?: InputMaybe<Fan_Fit_Bool_Exp>;
};

/** Ordering options when selecting data from "fan_fit". */
export type Fan_Fit_Order_By = {
  bottom_padding?: InputMaybe<Order_By>;
  fan_diameter?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  left_padding?: InputMaybe<Order_By>;
  manufacturer?: InputMaybe<Order_By>;
  middle_column_padding?: InputMaybe<Order_By>;
  middle_row_padding?: InputMaybe<Order_By>;
  right_padding?: InputMaybe<Order_By>;
  top_padding?: InputMaybe<Order_By>;
};

/** primary key columns input for table: fan_fit */
export type Fan_Fit_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "fan_fit" */
export enum Fan_Fit_Select_Column {
  /** column name */
  BottomPadding = 'bottom_padding',
  /** column name */
  FanDiameter = 'fan_diameter',
  /** column name */
  Id = 'id',
  /** column name */
  LeftPadding = 'left_padding',
  /** column name */
  Manufacturer = 'manufacturer',
  /** column name */
  MiddleColumnPadding = 'middle_column_padding',
  /** column name */
  MiddleRowPadding = 'middle_row_padding',
  /** column name */
  RightPadding = 'right_padding',
  /** column name */
  TopPadding = 'top_padding'
}

/** input type for updating data in table "fan_fit" */
export type Fan_Fit_Set_Input = {
  bottom_padding?: InputMaybe<Scalars['numeric']>;
  fan_diameter?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  left_padding?: InputMaybe<Scalars['numeric']>;
  manufacturer?: InputMaybe<Scalars['String']>;
  middle_column_padding?: InputMaybe<Scalars['numeric']>;
  middle_row_padding?: InputMaybe<Scalars['numeric']>;
  right_padding?: InputMaybe<Scalars['numeric']>;
  top_padding?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Fan_Fit_Stddev_Fields = {
  __typename?: 'fan_fit_stddev_fields';
  bottom_padding?: Maybe<Scalars['Float']>;
  fan_diameter?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  left_padding?: Maybe<Scalars['Float']>;
  middle_column_padding?: Maybe<Scalars['Float']>;
  middle_row_padding?: Maybe<Scalars['Float']>;
  right_padding?: Maybe<Scalars['Float']>;
  top_padding?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Fan_Fit_Stddev_Pop_Fields = {
  __typename?: 'fan_fit_stddev_pop_fields';
  bottom_padding?: Maybe<Scalars['Float']>;
  fan_diameter?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  left_padding?: Maybe<Scalars['Float']>;
  middle_column_padding?: Maybe<Scalars['Float']>;
  middle_row_padding?: Maybe<Scalars['Float']>;
  right_padding?: Maybe<Scalars['Float']>;
  top_padding?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Fan_Fit_Stddev_Samp_Fields = {
  __typename?: 'fan_fit_stddev_samp_fields';
  bottom_padding?: Maybe<Scalars['Float']>;
  fan_diameter?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  left_padding?: Maybe<Scalars['Float']>;
  middle_column_padding?: Maybe<Scalars['Float']>;
  middle_row_padding?: Maybe<Scalars['Float']>;
  right_padding?: Maybe<Scalars['Float']>;
  top_padding?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "fan_fit" */
export type Fan_Fit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Fan_Fit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Fan_Fit_Stream_Cursor_Value_Input = {
  bottom_padding?: InputMaybe<Scalars['numeric']>;
  fan_diameter?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  left_padding?: InputMaybe<Scalars['numeric']>;
  manufacturer?: InputMaybe<Scalars['String']>;
  middle_column_padding?: InputMaybe<Scalars['numeric']>;
  middle_row_padding?: InputMaybe<Scalars['numeric']>;
  right_padding?: InputMaybe<Scalars['numeric']>;
  top_padding?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Fan_Fit_Sum_Fields = {
  __typename?: 'fan_fit_sum_fields';
  bottom_padding?: Maybe<Scalars['numeric']>;
  fan_diameter?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  left_padding?: Maybe<Scalars['numeric']>;
  middle_column_padding?: Maybe<Scalars['numeric']>;
  middle_row_padding?: Maybe<Scalars['numeric']>;
  right_padding?: Maybe<Scalars['numeric']>;
  top_padding?: Maybe<Scalars['numeric']>;
};

/** update columns of table "fan_fit" */
export enum Fan_Fit_Update_Column {
  /** column name */
  BottomPadding = 'bottom_padding',
  /** column name */
  FanDiameter = 'fan_diameter',
  /** column name */
  Id = 'id',
  /** column name */
  LeftPadding = 'left_padding',
  /** column name */
  Manufacturer = 'manufacturer',
  /** column name */
  MiddleColumnPadding = 'middle_column_padding',
  /** column name */
  MiddleRowPadding = 'middle_row_padding',
  /** column name */
  RightPadding = 'right_padding',
  /** column name */
  TopPadding = 'top_padding'
}

export type Fan_Fit_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Fan_Fit_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Fan_Fit_Set_Input>;
  /** filter the rows which have to be updated */
  where: Fan_Fit_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Fan_Fit_Var_Pop_Fields = {
  __typename?: 'fan_fit_var_pop_fields';
  bottom_padding?: Maybe<Scalars['Float']>;
  fan_diameter?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  left_padding?: Maybe<Scalars['Float']>;
  middle_column_padding?: Maybe<Scalars['Float']>;
  middle_row_padding?: Maybe<Scalars['Float']>;
  right_padding?: Maybe<Scalars['Float']>;
  top_padding?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Fan_Fit_Var_Samp_Fields = {
  __typename?: 'fan_fit_var_samp_fields';
  bottom_padding?: Maybe<Scalars['Float']>;
  fan_diameter?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  left_padding?: Maybe<Scalars['Float']>;
  middle_column_padding?: Maybe<Scalars['Float']>;
  middle_row_padding?: Maybe<Scalars['Float']>;
  right_padding?: Maybe<Scalars['Float']>;
  top_padding?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Fan_Fit_Variance_Fields = {
  __typename?: 'fan_fit_variance_fields';
  bottom_padding?: Maybe<Scalars['Float']>;
  fan_diameter?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  left_padding?: Maybe<Scalars['Float']>;
  middle_column_padding?: Maybe<Scalars['Float']>;
  middle_row_padding?: Maybe<Scalars['Float']>;
  right_padding?: Maybe<Scalars['Float']>;
  top_padding?: Maybe<Scalars['Float']>;
};

/** Keeps track of the files for fan models */
export type Fan_Model_File = {
  __typename?: 'fan_model_file';
  front_file_id?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  is_safe: Scalars['Boolean'];
  model: Scalars['String'];
  side_file_id?: Maybe<Scalars['String']>;
  top_file_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "fan_model_file" */
export type Fan_Model_File_Aggregate = {
  __typename?: 'fan_model_file_aggregate';
  aggregate?: Maybe<Fan_Model_File_Aggregate_Fields>;
  nodes: Array<Fan_Model_File>;
};

/** aggregate fields of "fan_model_file" */
export type Fan_Model_File_Aggregate_Fields = {
  __typename?: 'fan_model_file_aggregate_fields';
  avg?: Maybe<Fan_Model_File_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Fan_Model_File_Max_Fields>;
  min?: Maybe<Fan_Model_File_Min_Fields>;
  stddev?: Maybe<Fan_Model_File_Stddev_Fields>;
  stddev_pop?: Maybe<Fan_Model_File_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Fan_Model_File_Stddev_Samp_Fields>;
  sum?: Maybe<Fan_Model_File_Sum_Fields>;
  var_pop?: Maybe<Fan_Model_File_Var_Pop_Fields>;
  var_samp?: Maybe<Fan_Model_File_Var_Samp_Fields>;
  variance?: Maybe<Fan_Model_File_Variance_Fields>;
};


/** aggregate fields of "fan_model_file" */
export type Fan_Model_File_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Fan_Model_File_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Fan_Model_File_Avg_Fields = {
  __typename?: 'fan_model_file_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "fan_model_file". All fields are combined with a logical 'AND'. */
export type Fan_Model_File_Bool_Exp = {
  _and?: InputMaybe<Array<Fan_Model_File_Bool_Exp>>;
  _not?: InputMaybe<Fan_Model_File_Bool_Exp>;
  _or?: InputMaybe<Array<Fan_Model_File_Bool_Exp>>;
  front_file_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_safe?: InputMaybe<Boolean_Comparison_Exp>;
  model?: InputMaybe<String_Comparison_Exp>;
  side_file_id?: InputMaybe<String_Comparison_Exp>;
  top_file_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "fan_model_file" */
export enum Fan_Model_File_Constraint {
  /** unique or primary key constraint on columns "model" */
  FanModelFileModelKey = 'fan_model_file_model_key',
  /** unique or primary key constraint on columns "id" */
  FanModelFilePkey = 'fan_model_file_pkey'
}

/** input type for incrementing numeric columns in table "fan_model_file" */
export type Fan_Model_File_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "fan_model_file" */
export type Fan_Model_File_Insert_Input = {
  front_file_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_safe?: InputMaybe<Scalars['Boolean']>;
  model?: InputMaybe<Scalars['String']>;
  side_file_id?: InputMaybe<Scalars['String']>;
  top_file_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Fan_Model_File_Max_Fields = {
  __typename?: 'fan_model_file_max_fields';
  front_file_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  model?: Maybe<Scalars['String']>;
  side_file_id?: Maybe<Scalars['String']>;
  top_file_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Fan_Model_File_Min_Fields = {
  __typename?: 'fan_model_file_min_fields';
  front_file_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  model?: Maybe<Scalars['String']>;
  side_file_id?: Maybe<Scalars['String']>;
  top_file_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "fan_model_file" */
export type Fan_Model_File_Mutation_Response = {
  __typename?: 'fan_model_file_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Fan_Model_File>;
};

/** on_conflict condition type for table "fan_model_file" */
export type Fan_Model_File_On_Conflict = {
  constraint: Fan_Model_File_Constraint;
  update_columns?: Array<Fan_Model_File_Update_Column>;
  where?: InputMaybe<Fan_Model_File_Bool_Exp>;
};

/** Ordering options when selecting data from "fan_model_file". */
export type Fan_Model_File_Order_By = {
  front_file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_safe?: InputMaybe<Order_By>;
  model?: InputMaybe<Order_By>;
  side_file_id?: InputMaybe<Order_By>;
  top_file_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: fan_model_file */
export type Fan_Model_File_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "fan_model_file" */
export enum Fan_Model_File_Select_Column {
  /** column name */
  FrontFileId = 'front_file_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsSafe = 'is_safe',
  /** column name */
  Model = 'model',
  /** column name */
  SideFileId = 'side_file_id',
  /** column name */
  TopFileId = 'top_file_id'
}

/** input type for updating data in table "fan_model_file" */
export type Fan_Model_File_Set_Input = {
  front_file_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_safe?: InputMaybe<Scalars['Boolean']>;
  model?: InputMaybe<Scalars['String']>;
  side_file_id?: InputMaybe<Scalars['String']>;
  top_file_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Fan_Model_File_Stddev_Fields = {
  __typename?: 'fan_model_file_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Fan_Model_File_Stddev_Pop_Fields = {
  __typename?: 'fan_model_file_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Fan_Model_File_Stddev_Samp_Fields = {
  __typename?: 'fan_model_file_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "fan_model_file" */
export type Fan_Model_File_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Fan_Model_File_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Fan_Model_File_Stream_Cursor_Value_Input = {
  front_file_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_safe?: InputMaybe<Scalars['Boolean']>;
  model?: InputMaybe<Scalars['String']>;
  side_file_id?: InputMaybe<Scalars['String']>;
  top_file_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Fan_Model_File_Sum_Fields = {
  __typename?: 'fan_model_file_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "fan_model_file" */
export enum Fan_Model_File_Update_Column {
  /** column name */
  FrontFileId = 'front_file_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsSafe = 'is_safe',
  /** column name */
  Model = 'model',
  /** column name */
  SideFileId = 'side_file_id',
  /** column name */
  TopFileId = 'top_file_id'
}

export type Fan_Model_File_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Fan_Model_File_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Fan_Model_File_Set_Input>;
  /** filter the rows which have to be updated */
  where: Fan_Model_File_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Fan_Model_File_Var_Pop_Fields = {
  __typename?: 'fan_model_file_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Fan_Model_File_Var_Samp_Fields = {
  __typename?: 'fan_model_file_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Fan_Model_File_Variance_Fields = {
  __typename?: 'fan_model_file_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** A job represents a product and quote for a client */
export type Job = {
  __typename?: 'job';
  created_on: Scalars['timestamptz'];
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  products: Array<Product>;
  /** An aggregate relationship */
  products_aggregate: Product_Aggregate;
  /** An array relationship */
  quotes: Array<Quote>;
  /** An aggregate relationship */
  quotes_aggregate: Quote_Aggregate;
  status: Scalars['String'];
  updated_on: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
};


/** A job represents a product and quote for a client */
export type JobProductsArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


/** A job represents a product and quote for a client */
export type JobProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


/** A job represents a product and quote for a client */
export type JobQuotesArgs = {
  distinct_on?: InputMaybe<Array<Quote_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Quote_Order_By>>;
  where?: InputMaybe<Quote_Bool_Exp>;
};


/** A job represents a product and quote for a client */
export type JobQuotes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Quote_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Quote_Order_By>>;
  where?: InputMaybe<Quote_Bool_Exp>;
};

/** aggregated selection of "job" */
export type Job_Aggregate = {
  __typename?: 'job_aggregate';
  aggregate?: Maybe<Job_Aggregate_Fields>;
  nodes: Array<Job>;
};

/** aggregate fields of "job" */
export type Job_Aggregate_Fields = {
  __typename?: 'job_aggregate_fields';
  avg?: Maybe<Job_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Job_Max_Fields>;
  min?: Maybe<Job_Min_Fields>;
  stddev?: Maybe<Job_Stddev_Fields>;
  stddev_pop?: Maybe<Job_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Job_Stddev_Samp_Fields>;
  sum?: Maybe<Job_Sum_Fields>;
  var_pop?: Maybe<Job_Var_Pop_Fields>;
  var_samp?: Maybe<Job_Var_Samp_Fields>;
  variance?: Maybe<Job_Variance_Fields>;
};


/** aggregate fields of "job" */
export type Job_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Job_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Job_Avg_Fields = {
  __typename?: 'job_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "job". All fields are combined with a logical 'AND'. */
export type Job_Bool_Exp = {
  _and?: InputMaybe<Array<Job_Bool_Exp>>;
  _not?: InputMaybe<Job_Bool_Exp>;
  _or?: InputMaybe<Array<Job_Bool_Exp>>;
  created_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  products?: InputMaybe<Product_Bool_Exp>;
  products_aggregate?: InputMaybe<Product_Aggregate_Bool_Exp>;
  quotes?: InputMaybe<Quote_Bool_Exp>;
  quotes_aggregate?: InputMaybe<Quote_Aggregate_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "job" */
export enum Job_Constraint {
  /** unique or primary key constraint on columns "id" */
  JobPkey = 'job_pkey'
}

/** input type for incrementing numeric columns in table "job" */
export type Job_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "job" */
export type Job_Insert_Input = {
  created_on?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<Product_Arr_Rel_Insert_Input>;
  quotes?: InputMaybe<Quote_Arr_Rel_Insert_Input>;
  status?: InputMaybe<Scalars['String']>;
  updated_on?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Job_Max_Fields = {
  __typename?: 'job_max_fields';
  created_on?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_on?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Job_Min_Fields = {
  __typename?: 'job_min_fields';
  created_on?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_on?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "job" */
export type Job_Mutation_Response = {
  __typename?: 'job_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Job>;
};

/** input type for inserting object relation for remote table "job" */
export type Job_Obj_Rel_Insert_Input = {
  data: Job_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Job_On_Conflict>;
};

/** on_conflict condition type for table "job" */
export type Job_On_Conflict = {
  constraint: Job_Constraint;
  update_columns?: Array<Job_Update_Column>;
  where?: InputMaybe<Job_Bool_Exp>;
};

/** Ordering options when selecting data from "job". */
export type Job_Order_By = {
  created_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  products_aggregate?: InputMaybe<Product_Aggregate_Order_By>;
  quotes_aggregate?: InputMaybe<Quote_Aggregate_Order_By>;
  status?: InputMaybe<Order_By>;
  updated_on?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: job */
export type Job_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "job" */
export enum Job_Select_Column {
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedOn = 'updated_on',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "job" */
export type Job_Set_Input = {
  created_on?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updated_on?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Job_Stddev_Fields = {
  __typename?: 'job_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Job_Stddev_Pop_Fields = {
  __typename?: 'job_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Job_Stddev_Samp_Fields = {
  __typename?: 'job_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "job" */
export type Job_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Job_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Job_Stream_Cursor_Value_Input = {
  created_on?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updated_on?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Job_Sum_Fields = {
  __typename?: 'job_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "job" */
export enum Job_Update_Column {
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedOn = 'updated_on',
  /** column name */
  UserId = 'user_id'
}

export type Job_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Job_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Job_Set_Input>;
  /** filter the rows which have to be updated */
  where: Job_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Job_Var_Pop_Fields = {
  __typename?: 'job_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Job_Var_Samp_Fields = {
  __typename?: 'job_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Job_Variance_Fields = {
  __typename?: 'job_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "money". All fields are combined with logical 'AND'. */
export type Money_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['money']>;
  _gt?: InputMaybe<Scalars['money']>;
  _gte?: InputMaybe<Scalars['money']>;
  _in?: InputMaybe<Array<Scalars['money']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['money']>;
  _lte?: InputMaybe<Scalars['money']>;
  _neq?: InputMaybe<Scalars['money']>;
  _nin?: InputMaybe<Array<Scalars['money']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "configuration" */
  delete_configuration?: Maybe<Configuration_Mutation_Response>;
  /** delete single row from the table: "configuration" */
  delete_configuration_by_pk?: Maybe<Configuration>;
  /** delete data from the table: "control_panel_sizing" */
  delete_control_panel_sizing?: Maybe<Control_Panel_Sizing_Mutation_Response>;
  /** delete single row from the table: "control_panel_sizing" */
  delete_control_panel_sizing_by_pk?: Maybe<Control_Panel_Sizing>;
  /** delete data from the table: "cost" */
  delete_cost?: Maybe<Cost_Mutation_Response>;
  /** delete single row from the table: "cost" */
  delete_cost_by_pk?: Maybe<Cost>;
  /** delete data from the table: "fan_fit" */
  delete_fan_fit?: Maybe<Fan_Fit_Mutation_Response>;
  /** delete single row from the table: "fan_fit" */
  delete_fan_fit_by_pk?: Maybe<Fan_Fit>;
  /** delete data from the table: "fan_model_file" */
  delete_fan_model_file?: Maybe<Fan_Model_File_Mutation_Response>;
  /** delete single row from the table: "fan_model_file" */
  delete_fan_model_file_by_pk?: Maybe<Fan_Model_File>;
  /** delete data from the table: "job" */
  delete_job?: Maybe<Job_Mutation_Response>;
  /** delete single row from the table: "job" */
  delete_job_by_pk?: Maybe<Job>;
  /** delete data from the table: "product" */
  delete_product?: Maybe<Product_Mutation_Response>;
  /** delete single row from the table: "product" */
  delete_product_by_pk?: Maybe<Product>;
  /** delete data from the table: "quote" */
  delete_quote?: Maybe<Quote_Mutation_Response>;
  /** delete single row from the table: "quote" */
  delete_quote_by_pk?: Maybe<Quote>;
  /** delete data from the table: "serial_number" */
  delete_serial_number?: Maybe<Serial_Number_Mutation_Response>;
  /** delete single row from the table: "serial_number" */
  delete_serial_number_by_pk?: Maybe<Serial_Number>;
  /** insert data into the table: "configuration" */
  insert_configuration?: Maybe<Configuration_Mutation_Response>;
  /** insert a single row into the table: "configuration" */
  insert_configuration_one?: Maybe<Configuration>;
  /** insert data into the table: "control_panel_sizing" */
  insert_control_panel_sizing?: Maybe<Control_Panel_Sizing_Mutation_Response>;
  /** insert a single row into the table: "control_panel_sizing" */
  insert_control_panel_sizing_one?: Maybe<Control_Panel_Sizing>;
  /** insert data into the table: "cost" */
  insert_cost?: Maybe<Cost_Mutation_Response>;
  /** insert a single row into the table: "cost" */
  insert_cost_one?: Maybe<Cost>;
  /** insert data into the table: "fan_fit" */
  insert_fan_fit?: Maybe<Fan_Fit_Mutation_Response>;
  /** insert a single row into the table: "fan_fit" */
  insert_fan_fit_one?: Maybe<Fan_Fit>;
  /** insert data into the table: "fan_model_file" */
  insert_fan_model_file?: Maybe<Fan_Model_File_Mutation_Response>;
  /** insert a single row into the table: "fan_model_file" */
  insert_fan_model_file_one?: Maybe<Fan_Model_File>;
  /** insert data into the table: "job" */
  insert_job?: Maybe<Job_Mutation_Response>;
  /** insert a single row into the table: "job" */
  insert_job_one?: Maybe<Job>;
  /** insert data into the table: "product" */
  insert_product?: Maybe<Product_Mutation_Response>;
  /** insert a single row into the table: "product" */
  insert_product_one?: Maybe<Product>;
  /** insert data into the table: "quote" */
  insert_quote?: Maybe<Quote_Mutation_Response>;
  /** insert a single row into the table: "quote" */
  insert_quote_one?: Maybe<Quote>;
  /** insert data into the table: "serial_number" */
  insert_serial_number?: Maybe<Serial_Number_Mutation_Response>;
  /** insert a single row into the table: "serial_number" */
  insert_serial_number_one?: Maybe<Serial_Number>;
  /** update data of the table: "configuration" */
  update_configuration?: Maybe<Configuration_Mutation_Response>;
  /** update single row of the table: "configuration" */
  update_configuration_by_pk?: Maybe<Configuration>;
  /** update multiples rows of table: "configuration" */
  update_configuration_many?: Maybe<Array<Maybe<Configuration_Mutation_Response>>>;
  /** update data of the table: "control_panel_sizing" */
  update_control_panel_sizing?: Maybe<Control_Panel_Sizing_Mutation_Response>;
  /** update single row of the table: "control_panel_sizing" */
  update_control_panel_sizing_by_pk?: Maybe<Control_Panel_Sizing>;
  /** update multiples rows of table: "control_panel_sizing" */
  update_control_panel_sizing_many?: Maybe<Array<Maybe<Control_Panel_Sizing_Mutation_Response>>>;
  /** update data of the table: "cost" */
  update_cost?: Maybe<Cost_Mutation_Response>;
  /** update single row of the table: "cost" */
  update_cost_by_pk?: Maybe<Cost>;
  /** update multiples rows of table: "cost" */
  update_cost_many?: Maybe<Array<Maybe<Cost_Mutation_Response>>>;
  /** update data of the table: "fan_fit" */
  update_fan_fit?: Maybe<Fan_Fit_Mutation_Response>;
  /** update single row of the table: "fan_fit" */
  update_fan_fit_by_pk?: Maybe<Fan_Fit>;
  /** update multiples rows of table: "fan_fit" */
  update_fan_fit_many?: Maybe<Array<Maybe<Fan_Fit_Mutation_Response>>>;
  /** update data of the table: "fan_model_file" */
  update_fan_model_file?: Maybe<Fan_Model_File_Mutation_Response>;
  /** update single row of the table: "fan_model_file" */
  update_fan_model_file_by_pk?: Maybe<Fan_Model_File>;
  /** update multiples rows of table: "fan_model_file" */
  update_fan_model_file_many?: Maybe<Array<Maybe<Fan_Model_File_Mutation_Response>>>;
  /** update data of the table: "job" */
  update_job?: Maybe<Job_Mutation_Response>;
  /** update single row of the table: "job" */
  update_job_by_pk?: Maybe<Job>;
  /** update multiples rows of table: "job" */
  update_job_many?: Maybe<Array<Maybe<Job_Mutation_Response>>>;
  /** update data of the table: "product" */
  update_product?: Maybe<Product_Mutation_Response>;
  /** update single row of the table: "product" */
  update_product_by_pk?: Maybe<Product>;
  /** update multiples rows of table: "product" */
  update_product_many?: Maybe<Array<Maybe<Product_Mutation_Response>>>;
  /** update data of the table: "quote" */
  update_quote?: Maybe<Quote_Mutation_Response>;
  /** update single row of the table: "quote" */
  update_quote_by_pk?: Maybe<Quote>;
  /** update multiples rows of table: "quote" */
  update_quote_many?: Maybe<Array<Maybe<Quote_Mutation_Response>>>;
  /** update data of the table: "serial_number" */
  update_serial_number?: Maybe<Serial_Number_Mutation_Response>;
  /** update single row of the table: "serial_number" */
  update_serial_number_by_pk?: Maybe<Serial_Number>;
  /** update multiples rows of table: "serial_number" */
  update_serial_number_many?: Maybe<Array<Maybe<Serial_Number_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootDelete_ConfigurationArgs = {
  where: Configuration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Configuration_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Control_Panel_SizingArgs = {
  where: Control_Panel_Sizing_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Control_Panel_Sizing_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CostArgs = {
  where: Cost_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Cost_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Fan_FitArgs = {
  where: Fan_Fit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Fan_Fit_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Fan_Model_FileArgs = {
  where: Fan_Model_File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Fan_Model_File_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_JobArgs = {
  where: Job_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Job_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ProductArgs = {
  where: Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Product_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_QuoteArgs = {
  where: Quote_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Quote_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Serial_NumberArgs = {
  where: Serial_Number_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Serial_Number_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootInsert_ConfigurationArgs = {
  objects: Array<Configuration_Insert_Input>;
  on_conflict?: InputMaybe<Configuration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Configuration_OneArgs = {
  object: Configuration_Insert_Input;
  on_conflict?: InputMaybe<Configuration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Control_Panel_SizingArgs = {
  objects: Array<Control_Panel_Sizing_Insert_Input>;
  on_conflict?: InputMaybe<Control_Panel_Sizing_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Control_Panel_Sizing_OneArgs = {
  object: Control_Panel_Sizing_Insert_Input;
  on_conflict?: InputMaybe<Control_Panel_Sizing_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CostArgs = {
  objects: Array<Cost_Insert_Input>;
  on_conflict?: InputMaybe<Cost_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Cost_OneArgs = {
  object: Cost_Insert_Input;
  on_conflict?: InputMaybe<Cost_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fan_FitArgs = {
  objects: Array<Fan_Fit_Insert_Input>;
  on_conflict?: InputMaybe<Fan_Fit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fan_Fit_OneArgs = {
  object: Fan_Fit_Insert_Input;
  on_conflict?: InputMaybe<Fan_Fit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fan_Model_FileArgs = {
  objects: Array<Fan_Model_File_Insert_Input>;
  on_conflict?: InputMaybe<Fan_Model_File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fan_Model_File_OneArgs = {
  object: Fan_Model_File_Insert_Input;
  on_conflict?: InputMaybe<Fan_Model_File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_JobArgs = {
  objects: Array<Job_Insert_Input>;
  on_conflict?: InputMaybe<Job_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Job_OneArgs = {
  object: Job_Insert_Input;
  on_conflict?: InputMaybe<Job_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProductArgs = {
  objects: Array<Product_Insert_Input>;
  on_conflict?: InputMaybe<Product_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_OneArgs = {
  object: Product_Insert_Input;
  on_conflict?: InputMaybe<Product_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_QuoteArgs = {
  objects: Array<Quote_Insert_Input>;
  on_conflict?: InputMaybe<Quote_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Quote_OneArgs = {
  object: Quote_Insert_Input;
  on_conflict?: InputMaybe<Quote_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Serial_NumberArgs = {
  objects: Array<Serial_Number_Insert_Input>;
  on_conflict?: InputMaybe<Serial_Number_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Serial_Number_OneArgs = {
  object: Serial_Number_Insert_Input;
  on_conflict?: InputMaybe<Serial_Number_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_ConfigurationArgs = {
  _inc?: InputMaybe<Configuration_Inc_Input>;
  _set?: InputMaybe<Configuration_Set_Input>;
  where: Configuration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Configuration_By_PkArgs = {
  _inc?: InputMaybe<Configuration_Inc_Input>;
  _set?: InputMaybe<Configuration_Set_Input>;
  pk_columns: Configuration_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Configuration_ManyArgs = {
  updates: Array<Configuration_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Control_Panel_SizingArgs = {
  _inc?: InputMaybe<Control_Panel_Sizing_Inc_Input>;
  _set?: InputMaybe<Control_Panel_Sizing_Set_Input>;
  where: Control_Panel_Sizing_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Control_Panel_Sizing_By_PkArgs = {
  _inc?: InputMaybe<Control_Panel_Sizing_Inc_Input>;
  _set?: InputMaybe<Control_Panel_Sizing_Set_Input>;
  pk_columns: Control_Panel_Sizing_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Control_Panel_Sizing_ManyArgs = {
  updates: Array<Control_Panel_Sizing_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CostArgs = {
  _inc?: InputMaybe<Cost_Inc_Input>;
  _set?: InputMaybe<Cost_Set_Input>;
  where: Cost_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Cost_By_PkArgs = {
  _inc?: InputMaybe<Cost_Inc_Input>;
  _set?: InputMaybe<Cost_Set_Input>;
  pk_columns: Cost_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Cost_ManyArgs = {
  updates: Array<Cost_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Fan_FitArgs = {
  _inc?: InputMaybe<Fan_Fit_Inc_Input>;
  _set?: InputMaybe<Fan_Fit_Set_Input>;
  where: Fan_Fit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Fan_Fit_By_PkArgs = {
  _inc?: InputMaybe<Fan_Fit_Inc_Input>;
  _set?: InputMaybe<Fan_Fit_Set_Input>;
  pk_columns: Fan_Fit_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Fan_Fit_ManyArgs = {
  updates: Array<Fan_Fit_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Fan_Model_FileArgs = {
  _inc?: InputMaybe<Fan_Model_File_Inc_Input>;
  _set?: InputMaybe<Fan_Model_File_Set_Input>;
  where: Fan_Model_File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Fan_Model_File_By_PkArgs = {
  _inc?: InputMaybe<Fan_Model_File_Inc_Input>;
  _set?: InputMaybe<Fan_Model_File_Set_Input>;
  pk_columns: Fan_Model_File_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Fan_Model_File_ManyArgs = {
  updates: Array<Fan_Model_File_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_JobArgs = {
  _inc?: InputMaybe<Job_Inc_Input>;
  _set?: InputMaybe<Job_Set_Input>;
  where: Job_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Job_By_PkArgs = {
  _inc?: InputMaybe<Job_Inc_Input>;
  _set?: InputMaybe<Job_Set_Input>;
  pk_columns: Job_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Job_ManyArgs = {
  updates: Array<Job_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ProductArgs = {
  _inc?: InputMaybe<Product_Inc_Input>;
  _set?: InputMaybe<Product_Set_Input>;
  where: Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Product_By_PkArgs = {
  _inc?: InputMaybe<Product_Inc_Input>;
  _set?: InputMaybe<Product_Set_Input>;
  pk_columns: Product_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Product_ManyArgs = {
  updates: Array<Product_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_QuoteArgs = {
  _inc?: InputMaybe<Quote_Inc_Input>;
  _set?: InputMaybe<Quote_Set_Input>;
  where: Quote_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Quote_By_PkArgs = {
  _inc?: InputMaybe<Quote_Inc_Input>;
  _set?: InputMaybe<Quote_Set_Input>;
  pk_columns: Quote_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Quote_ManyArgs = {
  updates: Array<Quote_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Serial_NumberArgs = {
  _inc?: InputMaybe<Serial_Number_Inc_Input>;
  _set?: InputMaybe<Serial_Number_Set_Input>;
  where: Serial_Number_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Serial_Number_By_PkArgs = {
  _inc?: InputMaybe<Serial_Number_Inc_Input>;
  _set?: InputMaybe<Serial_Number_Set_Input>;
  pk_columns: Serial_Number_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Serial_Number_ManyArgs = {
  updates: Array<Serial_Number_Updates>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** Products represent solutions for clients, they are associated with jobs and have configurations, and may be associates with quotes */
export type Product = {
  __typename?: 'product';
  BACnet?: Maybe<Scalars['String']>;
  air_flow: Scalars['numeric'];
  air_flow_orientation?: Maybe<Scalars['bpchar']>;
  backdraft_dampers: Scalars['Boolean'];
  /** An object relationship */
  configuration?: Maybe<Configuration>;
  configuration_id?: Maybe<Scalars['Int']>;
  configuration_quantity: Scalars['Int'];
  configured_fan_count?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  fan_layout?: Maybe<Scalars['String']>;
  /** An array relationship */
  has_serial_number: Array<Serial_Number>;
  /** An aggregate relationship */
  has_serial_number_aggregate: Serial_Number_Aggregate;
  height: Scalars['numeric'];
  id: Scalars['Int'];
  /** An object relationship */
  job?: Maybe<Job>;
  job_id: Scalars['Int'];
  local_HMI?: Maybe<Scalars['Boolean']>;
  mas_ec_plus?: Maybe<Scalars['Boolean']>;
  model_number?: Maybe<Scalars['String']>;
  outdoor_rating?: Maybe<Scalars['Boolean']>;
  over_one_hundred_percent_redundancy?: Maybe<Scalars['Boolean']>;
  power: Scalars['numeric'];
  pressure_transducers?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  quote?: Maybe<Quote>;
  quote_id?: Maybe<Scalars['Int']>;
  set_voltage?: Maybe<Scalars['numeric']>;
  tag: Scalars['String'];
  total_static_pressure: Scalars['numeric'];
  updated_at: Scalars['timestamptz'];
  voltage_override?: Maybe<Scalars['numeric']>;
  width: Scalars['numeric'];
};


/** Products represent solutions for clients, they are associated with jobs and have configurations, and may be associates with quotes */
export type ProductHas_Serial_NumberArgs = {
  distinct_on?: InputMaybe<Array<Serial_Number_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Serial_Number_Order_By>>;
  where?: InputMaybe<Serial_Number_Bool_Exp>;
};


/** Products represent solutions for clients, they are associated with jobs and have configurations, and may be associates with quotes */
export type ProductHas_Serial_Number_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Serial_Number_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Serial_Number_Order_By>>;
  where?: InputMaybe<Serial_Number_Bool_Exp>;
};

/** aggregated selection of "product" */
export type Product_Aggregate = {
  __typename?: 'product_aggregate';
  aggregate?: Maybe<Product_Aggregate_Fields>;
  nodes: Array<Product>;
};

export type Product_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Product_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Product_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Product_Aggregate_Bool_Exp_Count>;
};

export type Product_Aggregate_Bool_Exp_Bool_And = {
  arguments: Product_Select_Column_Product_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Product_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Product_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Product_Select_Column_Product_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Product_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Product_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Product_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Product_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "product" */
export type Product_Aggregate_Fields = {
  __typename?: 'product_aggregate_fields';
  avg?: Maybe<Product_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Product_Max_Fields>;
  min?: Maybe<Product_Min_Fields>;
  stddev?: Maybe<Product_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Sum_Fields>;
  var_pop?: Maybe<Product_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Var_Samp_Fields>;
  variance?: Maybe<Product_Variance_Fields>;
};


/** aggregate fields of "product" */
export type Product_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product" */
export type Product_Aggregate_Order_By = {
  avg?: InputMaybe<Product_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Max_Order_By>;
  min?: InputMaybe<Product_Min_Order_By>;
  stddev?: InputMaybe<Product_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Product_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Product_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Product_Sum_Order_By>;
  var_pop?: InputMaybe<Product_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Product_Var_Samp_Order_By>;
  variance?: InputMaybe<Product_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "product" */
export type Product_Arr_Rel_Insert_Input = {
  data: Array<Product_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_On_Conflict>;
};

/** aggregate avg on columns */
export type Product_Avg_Fields = {
  __typename?: 'product_avg_fields';
  air_flow?: Maybe<Scalars['Float']>;
  configuration_id?: Maybe<Scalars['Float']>;
  configuration_quantity?: Maybe<Scalars['Float']>;
  configured_fan_count?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  quote_id?: Maybe<Scalars['Float']>;
  set_voltage?: Maybe<Scalars['Float']>;
  total_static_pressure?: Maybe<Scalars['Float']>;
  voltage_override?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "product" */
export type Product_Avg_Order_By = {
  air_flow?: InputMaybe<Order_By>;
  configuration_id?: InputMaybe<Order_By>;
  configuration_quantity?: InputMaybe<Order_By>;
  configured_fan_count?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  power?: InputMaybe<Order_By>;
  quote_id?: InputMaybe<Order_By>;
  set_voltage?: InputMaybe<Order_By>;
  total_static_pressure?: InputMaybe<Order_By>;
  voltage_override?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product". All fields are combined with a logical 'AND'. */
export type Product_Bool_Exp = {
  BACnet?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Product_Bool_Exp>>;
  _not?: InputMaybe<Product_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Bool_Exp>>;
  air_flow?: InputMaybe<Numeric_Comparison_Exp>;
  air_flow_orientation?: InputMaybe<Bpchar_Comparison_Exp>;
  backdraft_dampers?: InputMaybe<Boolean_Comparison_Exp>;
  configuration?: InputMaybe<Configuration_Bool_Exp>;
  configuration_id?: InputMaybe<Int_Comparison_Exp>;
  configuration_quantity?: InputMaybe<Int_Comparison_Exp>;
  configured_fan_count?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  fan_layout?: InputMaybe<String_Comparison_Exp>;
  has_serial_number?: InputMaybe<Serial_Number_Bool_Exp>;
  has_serial_number_aggregate?: InputMaybe<Serial_Number_Aggregate_Bool_Exp>;
  height?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  job?: InputMaybe<Job_Bool_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  local_HMI?: InputMaybe<Boolean_Comparison_Exp>;
  mas_ec_plus?: InputMaybe<Boolean_Comparison_Exp>;
  model_number?: InputMaybe<String_Comparison_Exp>;
  outdoor_rating?: InputMaybe<Boolean_Comparison_Exp>;
  over_one_hundred_percent_redundancy?: InputMaybe<Boolean_Comparison_Exp>;
  power?: InputMaybe<Numeric_Comparison_Exp>;
  pressure_transducers?: InputMaybe<Boolean_Comparison_Exp>;
  quote?: InputMaybe<Quote_Bool_Exp>;
  quote_id?: InputMaybe<Int_Comparison_Exp>;
  set_voltage?: InputMaybe<Numeric_Comparison_Exp>;
  tag?: InputMaybe<String_Comparison_Exp>;
  total_static_pressure?: InputMaybe<Numeric_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  voltage_override?: InputMaybe<Numeric_Comparison_Exp>;
  width?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "product" */
export enum Product_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductPkey = 'product_pkey'
}

/** input type for incrementing numeric columns in table "product" */
export type Product_Inc_Input = {
  air_flow?: InputMaybe<Scalars['numeric']>;
  configuration_id?: InputMaybe<Scalars['Int']>;
  configuration_quantity?: InputMaybe<Scalars['Int']>;
  configured_fan_count?: InputMaybe<Scalars['Int']>;
  height?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  power?: InputMaybe<Scalars['numeric']>;
  quote_id?: InputMaybe<Scalars['Int']>;
  set_voltage?: InputMaybe<Scalars['numeric']>;
  total_static_pressure?: InputMaybe<Scalars['numeric']>;
  voltage_override?: InputMaybe<Scalars['numeric']>;
  width?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "product" */
export type Product_Insert_Input = {
  BACnet?: InputMaybe<Scalars['String']>;
  air_flow?: InputMaybe<Scalars['numeric']>;
  air_flow_orientation?: InputMaybe<Scalars['bpchar']>;
  backdraft_dampers?: InputMaybe<Scalars['Boolean']>;
  configuration?: InputMaybe<Configuration_Obj_Rel_Insert_Input>;
  configuration_id?: InputMaybe<Scalars['Int']>;
  configuration_quantity?: InputMaybe<Scalars['Int']>;
  configured_fan_count?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  fan_layout?: InputMaybe<Scalars['String']>;
  has_serial_number?: InputMaybe<Serial_Number_Arr_Rel_Insert_Input>;
  height?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  job?: InputMaybe<Job_Obj_Rel_Insert_Input>;
  job_id?: InputMaybe<Scalars['Int']>;
  local_HMI?: InputMaybe<Scalars['Boolean']>;
  mas_ec_plus?: InputMaybe<Scalars['Boolean']>;
  model_number?: InputMaybe<Scalars['String']>;
  outdoor_rating?: InputMaybe<Scalars['Boolean']>;
  over_one_hundred_percent_redundancy?: InputMaybe<Scalars['Boolean']>;
  power?: InputMaybe<Scalars['numeric']>;
  pressure_transducers?: InputMaybe<Scalars['Boolean']>;
  quote?: InputMaybe<Quote_Obj_Rel_Insert_Input>;
  quote_id?: InputMaybe<Scalars['Int']>;
  set_voltage?: InputMaybe<Scalars['numeric']>;
  tag?: InputMaybe<Scalars['String']>;
  total_static_pressure?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  voltage_override?: InputMaybe<Scalars['numeric']>;
  width?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Product_Max_Fields = {
  __typename?: 'product_max_fields';
  BACnet?: Maybe<Scalars['String']>;
  air_flow?: Maybe<Scalars['numeric']>;
  air_flow_orientation?: Maybe<Scalars['bpchar']>;
  configuration_id?: Maybe<Scalars['Int']>;
  configuration_quantity?: Maybe<Scalars['Int']>;
  configured_fan_count?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fan_layout?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  model_number?: Maybe<Scalars['String']>;
  power?: Maybe<Scalars['numeric']>;
  quote_id?: Maybe<Scalars['Int']>;
  set_voltage?: Maybe<Scalars['numeric']>;
  tag?: Maybe<Scalars['String']>;
  total_static_pressure?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  voltage_override?: Maybe<Scalars['numeric']>;
  width?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "product" */
export type Product_Max_Order_By = {
  BACnet?: InputMaybe<Order_By>;
  air_flow?: InputMaybe<Order_By>;
  air_flow_orientation?: InputMaybe<Order_By>;
  configuration_id?: InputMaybe<Order_By>;
  configuration_quantity?: InputMaybe<Order_By>;
  configured_fan_count?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  fan_layout?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  model_number?: InputMaybe<Order_By>;
  power?: InputMaybe<Order_By>;
  quote_id?: InputMaybe<Order_By>;
  set_voltage?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
  total_static_pressure?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  voltage_override?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Min_Fields = {
  __typename?: 'product_min_fields';
  BACnet?: Maybe<Scalars['String']>;
  air_flow?: Maybe<Scalars['numeric']>;
  air_flow_orientation?: Maybe<Scalars['bpchar']>;
  configuration_id?: Maybe<Scalars['Int']>;
  configuration_quantity?: Maybe<Scalars['Int']>;
  configured_fan_count?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fan_layout?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  model_number?: Maybe<Scalars['String']>;
  power?: Maybe<Scalars['numeric']>;
  quote_id?: Maybe<Scalars['Int']>;
  set_voltage?: Maybe<Scalars['numeric']>;
  tag?: Maybe<Scalars['String']>;
  total_static_pressure?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  voltage_override?: Maybe<Scalars['numeric']>;
  width?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "product" */
export type Product_Min_Order_By = {
  BACnet?: InputMaybe<Order_By>;
  air_flow?: InputMaybe<Order_By>;
  air_flow_orientation?: InputMaybe<Order_By>;
  configuration_id?: InputMaybe<Order_By>;
  configuration_quantity?: InputMaybe<Order_By>;
  configured_fan_count?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  fan_layout?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  model_number?: InputMaybe<Order_By>;
  power?: InputMaybe<Order_By>;
  quote_id?: InputMaybe<Order_By>;
  set_voltage?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
  total_static_pressure?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  voltage_override?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "product" */
export type Product_Mutation_Response = {
  __typename?: 'product_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product>;
};

/** input type for inserting object relation for remote table "product" */
export type Product_Obj_Rel_Insert_Input = {
  data: Product_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_On_Conflict>;
};

/** on_conflict condition type for table "product" */
export type Product_On_Conflict = {
  constraint: Product_Constraint;
  update_columns?: Array<Product_Update_Column>;
  where?: InputMaybe<Product_Bool_Exp>;
};

/** Ordering options when selecting data from "product". */
export type Product_Order_By = {
  BACnet?: InputMaybe<Order_By>;
  air_flow?: InputMaybe<Order_By>;
  air_flow_orientation?: InputMaybe<Order_By>;
  backdraft_dampers?: InputMaybe<Order_By>;
  configuration?: InputMaybe<Configuration_Order_By>;
  configuration_id?: InputMaybe<Order_By>;
  configuration_quantity?: InputMaybe<Order_By>;
  configured_fan_count?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  fan_layout?: InputMaybe<Order_By>;
  has_serial_number_aggregate?: InputMaybe<Serial_Number_Aggregate_Order_By>;
  height?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job?: InputMaybe<Job_Order_By>;
  job_id?: InputMaybe<Order_By>;
  local_HMI?: InputMaybe<Order_By>;
  mas_ec_plus?: InputMaybe<Order_By>;
  model_number?: InputMaybe<Order_By>;
  outdoor_rating?: InputMaybe<Order_By>;
  over_one_hundred_percent_redundancy?: InputMaybe<Order_By>;
  power?: InputMaybe<Order_By>;
  pressure_transducers?: InputMaybe<Order_By>;
  quote?: InputMaybe<Quote_Order_By>;
  quote_id?: InputMaybe<Order_By>;
  set_voltage?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
  total_static_pressure?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  voltage_override?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product */
export type Product_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "product" */
export enum Product_Select_Column {
  /** column name */
  BaCnet = 'BACnet',
  /** column name */
  AirFlow = 'air_flow',
  /** column name */
  AirFlowOrientation = 'air_flow_orientation',
  /** column name */
  BackdraftDampers = 'backdraft_dampers',
  /** column name */
  ConfigurationId = 'configuration_id',
  /** column name */
  ConfigurationQuantity = 'configuration_quantity',
  /** column name */
  ConfiguredFanCount = 'configured_fan_count',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FanLayout = 'fan_layout',
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  LocalHmi = 'local_HMI',
  /** column name */
  MasEcPlus = 'mas_ec_plus',
  /** column name */
  ModelNumber = 'model_number',
  /** column name */
  OutdoorRating = 'outdoor_rating',
  /** column name */
  OverOneHundredPercentRedundancy = 'over_one_hundred_percent_redundancy',
  /** column name */
  Power = 'power',
  /** column name */
  PressureTransducers = 'pressure_transducers',
  /** column name */
  QuoteId = 'quote_id',
  /** column name */
  SetVoltage = 'set_voltage',
  /** column name */
  Tag = 'tag',
  /** column name */
  TotalStaticPressure = 'total_static_pressure',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VoltageOverride = 'voltage_override',
  /** column name */
  Width = 'width'
}

/** select "product_aggregate_bool_exp_bool_and_arguments_columns" columns of table "product" */
export enum Product_Select_Column_Product_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  BackdraftDampers = 'backdraft_dampers',
  /** column name */
  LocalHmi = 'local_HMI',
  /** column name */
  MasEcPlus = 'mas_ec_plus',
  /** column name */
  OutdoorRating = 'outdoor_rating',
  /** column name */
  OverOneHundredPercentRedundancy = 'over_one_hundred_percent_redundancy',
  /** column name */
  PressureTransducers = 'pressure_transducers'
}

/** select "product_aggregate_bool_exp_bool_or_arguments_columns" columns of table "product" */
export enum Product_Select_Column_Product_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  BackdraftDampers = 'backdraft_dampers',
  /** column name */
  LocalHmi = 'local_HMI',
  /** column name */
  MasEcPlus = 'mas_ec_plus',
  /** column name */
  OutdoorRating = 'outdoor_rating',
  /** column name */
  OverOneHundredPercentRedundancy = 'over_one_hundred_percent_redundancy',
  /** column name */
  PressureTransducers = 'pressure_transducers'
}

/** input type for updating data in table "product" */
export type Product_Set_Input = {
  BACnet?: InputMaybe<Scalars['String']>;
  air_flow?: InputMaybe<Scalars['numeric']>;
  air_flow_orientation?: InputMaybe<Scalars['bpchar']>;
  backdraft_dampers?: InputMaybe<Scalars['Boolean']>;
  configuration_id?: InputMaybe<Scalars['Int']>;
  configuration_quantity?: InputMaybe<Scalars['Int']>;
  configured_fan_count?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  fan_layout?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  local_HMI?: InputMaybe<Scalars['Boolean']>;
  mas_ec_plus?: InputMaybe<Scalars['Boolean']>;
  model_number?: InputMaybe<Scalars['String']>;
  outdoor_rating?: InputMaybe<Scalars['Boolean']>;
  over_one_hundred_percent_redundancy?: InputMaybe<Scalars['Boolean']>;
  power?: InputMaybe<Scalars['numeric']>;
  pressure_transducers?: InputMaybe<Scalars['Boolean']>;
  quote_id?: InputMaybe<Scalars['Int']>;
  set_voltage?: InputMaybe<Scalars['numeric']>;
  tag?: InputMaybe<Scalars['String']>;
  total_static_pressure?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  voltage_override?: InputMaybe<Scalars['numeric']>;
  width?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Product_Stddev_Fields = {
  __typename?: 'product_stddev_fields';
  air_flow?: Maybe<Scalars['Float']>;
  configuration_id?: Maybe<Scalars['Float']>;
  configuration_quantity?: Maybe<Scalars['Float']>;
  configured_fan_count?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  quote_id?: Maybe<Scalars['Float']>;
  set_voltage?: Maybe<Scalars['Float']>;
  total_static_pressure?: Maybe<Scalars['Float']>;
  voltage_override?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "product" */
export type Product_Stddev_Order_By = {
  air_flow?: InputMaybe<Order_By>;
  configuration_id?: InputMaybe<Order_By>;
  configuration_quantity?: InputMaybe<Order_By>;
  configured_fan_count?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  power?: InputMaybe<Order_By>;
  quote_id?: InputMaybe<Order_By>;
  set_voltage?: InputMaybe<Order_By>;
  total_static_pressure?: InputMaybe<Order_By>;
  voltage_override?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_Stddev_Pop_Fields = {
  __typename?: 'product_stddev_pop_fields';
  air_flow?: Maybe<Scalars['Float']>;
  configuration_id?: Maybe<Scalars['Float']>;
  configuration_quantity?: Maybe<Scalars['Float']>;
  configured_fan_count?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  quote_id?: Maybe<Scalars['Float']>;
  set_voltage?: Maybe<Scalars['Float']>;
  total_static_pressure?: Maybe<Scalars['Float']>;
  voltage_override?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "product" */
export type Product_Stddev_Pop_Order_By = {
  air_flow?: InputMaybe<Order_By>;
  configuration_id?: InputMaybe<Order_By>;
  configuration_quantity?: InputMaybe<Order_By>;
  configured_fan_count?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  power?: InputMaybe<Order_By>;
  quote_id?: InputMaybe<Order_By>;
  set_voltage?: InputMaybe<Order_By>;
  total_static_pressure?: InputMaybe<Order_By>;
  voltage_override?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_Stddev_Samp_Fields = {
  __typename?: 'product_stddev_samp_fields';
  air_flow?: Maybe<Scalars['Float']>;
  configuration_id?: Maybe<Scalars['Float']>;
  configuration_quantity?: Maybe<Scalars['Float']>;
  configured_fan_count?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  quote_id?: Maybe<Scalars['Float']>;
  set_voltage?: Maybe<Scalars['Float']>;
  total_static_pressure?: Maybe<Scalars['Float']>;
  voltage_override?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "product" */
export type Product_Stddev_Samp_Order_By = {
  air_flow?: InputMaybe<Order_By>;
  configuration_id?: InputMaybe<Order_By>;
  configuration_quantity?: InputMaybe<Order_By>;
  configured_fan_count?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  power?: InputMaybe<Order_By>;
  quote_id?: InputMaybe<Order_By>;
  set_voltage?: InputMaybe<Order_By>;
  total_static_pressure?: InputMaybe<Order_By>;
  voltage_override?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "product" */
export type Product_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Stream_Cursor_Value_Input = {
  BACnet?: InputMaybe<Scalars['String']>;
  air_flow?: InputMaybe<Scalars['numeric']>;
  air_flow_orientation?: InputMaybe<Scalars['bpchar']>;
  backdraft_dampers?: InputMaybe<Scalars['Boolean']>;
  configuration_id?: InputMaybe<Scalars['Int']>;
  configuration_quantity?: InputMaybe<Scalars['Int']>;
  configured_fan_count?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  fan_layout?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  local_HMI?: InputMaybe<Scalars['Boolean']>;
  mas_ec_plus?: InputMaybe<Scalars['Boolean']>;
  model_number?: InputMaybe<Scalars['String']>;
  outdoor_rating?: InputMaybe<Scalars['Boolean']>;
  over_one_hundred_percent_redundancy?: InputMaybe<Scalars['Boolean']>;
  power?: InputMaybe<Scalars['numeric']>;
  pressure_transducers?: InputMaybe<Scalars['Boolean']>;
  quote_id?: InputMaybe<Scalars['Int']>;
  set_voltage?: InputMaybe<Scalars['numeric']>;
  tag?: InputMaybe<Scalars['String']>;
  total_static_pressure?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  voltage_override?: InputMaybe<Scalars['numeric']>;
  width?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Product_Sum_Fields = {
  __typename?: 'product_sum_fields';
  air_flow?: Maybe<Scalars['numeric']>;
  configuration_id?: Maybe<Scalars['Int']>;
  configuration_quantity?: Maybe<Scalars['Int']>;
  configured_fan_count?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  power?: Maybe<Scalars['numeric']>;
  quote_id?: Maybe<Scalars['Int']>;
  set_voltage?: Maybe<Scalars['numeric']>;
  total_static_pressure?: Maybe<Scalars['numeric']>;
  voltage_override?: Maybe<Scalars['numeric']>;
  width?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "product" */
export type Product_Sum_Order_By = {
  air_flow?: InputMaybe<Order_By>;
  configuration_id?: InputMaybe<Order_By>;
  configuration_quantity?: InputMaybe<Order_By>;
  configured_fan_count?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  power?: InputMaybe<Order_By>;
  quote_id?: InputMaybe<Order_By>;
  set_voltage?: InputMaybe<Order_By>;
  total_static_pressure?: InputMaybe<Order_By>;
  voltage_override?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** update columns of table "product" */
export enum Product_Update_Column {
  /** column name */
  BaCnet = 'BACnet',
  /** column name */
  AirFlow = 'air_flow',
  /** column name */
  AirFlowOrientation = 'air_flow_orientation',
  /** column name */
  BackdraftDampers = 'backdraft_dampers',
  /** column name */
  ConfigurationId = 'configuration_id',
  /** column name */
  ConfigurationQuantity = 'configuration_quantity',
  /** column name */
  ConfiguredFanCount = 'configured_fan_count',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FanLayout = 'fan_layout',
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  LocalHmi = 'local_HMI',
  /** column name */
  MasEcPlus = 'mas_ec_plus',
  /** column name */
  ModelNumber = 'model_number',
  /** column name */
  OutdoorRating = 'outdoor_rating',
  /** column name */
  OverOneHundredPercentRedundancy = 'over_one_hundred_percent_redundancy',
  /** column name */
  Power = 'power',
  /** column name */
  PressureTransducers = 'pressure_transducers',
  /** column name */
  QuoteId = 'quote_id',
  /** column name */
  SetVoltage = 'set_voltage',
  /** column name */
  Tag = 'tag',
  /** column name */
  TotalStaticPressure = 'total_static_pressure',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VoltageOverride = 'voltage_override',
  /** column name */
  Width = 'width'
}

export type Product_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Product_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Product_Var_Pop_Fields = {
  __typename?: 'product_var_pop_fields';
  air_flow?: Maybe<Scalars['Float']>;
  configuration_id?: Maybe<Scalars['Float']>;
  configuration_quantity?: Maybe<Scalars['Float']>;
  configured_fan_count?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  quote_id?: Maybe<Scalars['Float']>;
  set_voltage?: Maybe<Scalars['Float']>;
  total_static_pressure?: Maybe<Scalars['Float']>;
  voltage_override?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "product" */
export type Product_Var_Pop_Order_By = {
  air_flow?: InputMaybe<Order_By>;
  configuration_id?: InputMaybe<Order_By>;
  configuration_quantity?: InputMaybe<Order_By>;
  configured_fan_count?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  power?: InputMaybe<Order_By>;
  quote_id?: InputMaybe<Order_By>;
  set_voltage?: InputMaybe<Order_By>;
  total_static_pressure?: InputMaybe<Order_By>;
  voltage_override?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_Var_Samp_Fields = {
  __typename?: 'product_var_samp_fields';
  air_flow?: Maybe<Scalars['Float']>;
  configuration_id?: Maybe<Scalars['Float']>;
  configuration_quantity?: Maybe<Scalars['Float']>;
  configured_fan_count?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  quote_id?: Maybe<Scalars['Float']>;
  set_voltage?: Maybe<Scalars['Float']>;
  total_static_pressure?: Maybe<Scalars['Float']>;
  voltage_override?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "product" */
export type Product_Var_Samp_Order_By = {
  air_flow?: InputMaybe<Order_By>;
  configuration_id?: InputMaybe<Order_By>;
  configuration_quantity?: InputMaybe<Order_By>;
  configured_fan_count?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  power?: InputMaybe<Order_By>;
  quote_id?: InputMaybe<Order_By>;
  set_voltage?: InputMaybe<Order_By>;
  total_static_pressure?: InputMaybe<Order_By>;
  voltage_override?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_Variance_Fields = {
  __typename?: 'product_variance_fields';
  air_flow?: Maybe<Scalars['Float']>;
  configuration_id?: Maybe<Scalars['Float']>;
  configuration_quantity?: Maybe<Scalars['Float']>;
  configured_fan_count?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  quote_id?: Maybe<Scalars['Float']>;
  set_voltage?: Maybe<Scalars['Float']>;
  total_static_pressure?: Maybe<Scalars['Float']>;
  voltage_override?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "product" */
export type Product_Variance_Order_By = {
  air_flow?: InputMaybe<Order_By>;
  configuration_id?: InputMaybe<Order_By>;
  configuration_quantity?: InputMaybe<Order_By>;
  configured_fan_count?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  power?: InputMaybe<Order_By>;
  quote_id?: InputMaybe<Order_By>;
  set_voltage?: InputMaybe<Order_By>;
  total_static_pressure?: InputMaybe<Order_By>;
  voltage_override?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "configuration" */
  configuration: Array<Configuration>;
  /** fetch aggregated fields from the table: "configuration" */
  configuration_aggregate: Configuration_Aggregate;
  /** fetch data from the table: "configuration" using primary key columns */
  configuration_by_pk?: Maybe<Configuration>;
  /** fetch data from the table: "control_panel_sizing" */
  control_panel_sizing: Array<Control_Panel_Sizing>;
  /** fetch aggregated fields from the table: "control_panel_sizing" */
  control_panel_sizing_aggregate: Control_Panel_Sizing_Aggregate;
  /** fetch data from the table: "control_panel_sizing" using primary key columns */
  control_panel_sizing_by_pk?: Maybe<Control_Panel_Sizing>;
  /** fetch data from the table: "cost" */
  cost: Array<Cost>;
  /** fetch aggregated fields from the table: "cost" */
  cost_aggregate: Cost_Aggregate;
  /** fetch data from the table: "cost" using primary key columns */
  cost_by_pk?: Maybe<Cost>;
  /** fetch data from the table: "fan_fit" */
  fan_fit: Array<Fan_Fit>;
  /** fetch aggregated fields from the table: "fan_fit" */
  fan_fit_aggregate: Fan_Fit_Aggregate;
  /** fetch data from the table: "fan_fit" using primary key columns */
  fan_fit_by_pk?: Maybe<Fan_Fit>;
  /** fetch data from the table: "fan_model_file" */
  fan_model_file: Array<Fan_Model_File>;
  /** fetch aggregated fields from the table: "fan_model_file" */
  fan_model_file_aggregate: Fan_Model_File_Aggregate;
  /** fetch data from the table: "fan_model_file" using primary key columns */
  fan_model_file_by_pk?: Maybe<Fan_Model_File>;
  /** fetch data from the table: "job" */
  job: Array<Job>;
  /** fetch aggregated fields from the table: "job" */
  job_aggregate: Job_Aggregate;
  /** fetch data from the table: "job" using primary key columns */
  job_by_pk?: Maybe<Job>;
  /** fetch data from the table: "product" */
  product: Array<Product>;
  /** fetch aggregated fields from the table: "product" */
  product_aggregate: Product_Aggregate;
  /** fetch data from the table: "product" using primary key columns */
  product_by_pk?: Maybe<Product>;
  /** fetch data from the table: "quote" */
  quote: Array<Quote>;
  /** fetch aggregated fields from the table: "quote" */
  quote_aggregate: Quote_Aggregate;
  /** fetch data from the table: "quote" using primary key columns */
  quote_by_pk?: Maybe<Quote>;
  /** fetch data from the table: "serial_number" */
  serial_number: Array<Serial_Number>;
  /** fetch aggregated fields from the table: "serial_number" */
  serial_number_aggregate: Serial_Number_Aggregate;
  /** fetch data from the table: "serial_number" using primary key columns */
  serial_number_by_pk?: Maybe<Serial_Number>;
};


export type Query_RootConfigurationArgs = {
  distinct_on?: InputMaybe<Array<Configuration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Configuration_Order_By>>;
  where?: InputMaybe<Configuration_Bool_Exp>;
};


export type Query_RootConfiguration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Configuration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Configuration_Order_By>>;
  where?: InputMaybe<Configuration_Bool_Exp>;
};


export type Query_RootConfiguration_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootControl_Panel_SizingArgs = {
  distinct_on?: InputMaybe<Array<Control_Panel_Sizing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Control_Panel_Sizing_Order_By>>;
  where?: InputMaybe<Control_Panel_Sizing_Bool_Exp>;
};


export type Query_RootControl_Panel_Sizing_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Panel_Sizing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Control_Panel_Sizing_Order_By>>;
  where?: InputMaybe<Control_Panel_Sizing_Bool_Exp>;
};


export type Query_RootControl_Panel_Sizing_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCostArgs = {
  distinct_on?: InputMaybe<Array<Cost_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cost_Order_By>>;
  where?: InputMaybe<Cost_Bool_Exp>;
};


export type Query_RootCost_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cost_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cost_Order_By>>;
  where?: InputMaybe<Cost_Bool_Exp>;
};


export type Query_RootCost_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootFan_FitArgs = {
  distinct_on?: InputMaybe<Array<Fan_Fit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fan_Fit_Order_By>>;
  where?: InputMaybe<Fan_Fit_Bool_Exp>;
};


export type Query_RootFan_Fit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fan_Fit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fan_Fit_Order_By>>;
  where?: InputMaybe<Fan_Fit_Bool_Exp>;
};


export type Query_RootFan_Fit_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootFan_Model_FileArgs = {
  distinct_on?: InputMaybe<Array<Fan_Model_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fan_Model_File_Order_By>>;
  where?: InputMaybe<Fan_Model_File_Bool_Exp>;
};


export type Query_RootFan_Model_File_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fan_Model_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fan_Model_File_Order_By>>;
  where?: InputMaybe<Fan_Model_File_Bool_Exp>;
};


export type Query_RootFan_Model_File_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootJobArgs = {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
};


export type Query_RootJob_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
};


export type Query_RootJob_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootProductArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


export type Query_RootProduct_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


export type Query_RootProduct_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootQuoteArgs = {
  distinct_on?: InputMaybe<Array<Quote_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Quote_Order_By>>;
  where?: InputMaybe<Quote_Bool_Exp>;
};


export type Query_RootQuote_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Quote_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Quote_Order_By>>;
  where?: InputMaybe<Quote_Bool_Exp>;
};


export type Query_RootQuote_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootSerial_NumberArgs = {
  distinct_on?: InputMaybe<Array<Serial_Number_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Serial_Number_Order_By>>;
  where?: InputMaybe<Serial_Number_Bool_Exp>;
};


export type Query_RootSerial_Number_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Serial_Number_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Serial_Number_Order_By>>;
  where?: InputMaybe<Serial_Number_Bool_Exp>;
};


export type Query_RootSerial_Number_By_PkArgs = {
  id: Scalars['Int'];
};

/** A quote represents a bid to a client for a product. */
export type Quote = {
  __typename?: 'quote';
  final_lock: Scalars['Boolean'];
  firmware?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object relationship */
  job?: Maybe<Job>;
  job_id: Scalars['Int'];
  job_number?: Maybe<Scalars['String']>;
  locked: Scalars['Boolean'];
  name: Scalars['String'];
  /** An array relationship */
  products: Array<Product>;
  /** An aggregate relationship */
  products_aggregate: Product_Aggregate;
  software?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};


/** A quote represents a bid to a client for a product. */
export type QuoteProductsArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


/** A quote represents a bid to a client for a product. */
export type QuoteProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};

/** aggregated selection of "quote" */
export type Quote_Aggregate = {
  __typename?: 'quote_aggregate';
  aggregate?: Maybe<Quote_Aggregate_Fields>;
  nodes: Array<Quote>;
};

export type Quote_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Quote_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Quote_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Quote_Aggregate_Bool_Exp_Count>;
};

export type Quote_Aggregate_Bool_Exp_Bool_And = {
  arguments: Quote_Select_Column_Quote_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Quote_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Quote_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Quote_Select_Column_Quote_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Quote_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Quote_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Quote_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Quote_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "quote" */
export type Quote_Aggregate_Fields = {
  __typename?: 'quote_aggregate_fields';
  avg?: Maybe<Quote_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Quote_Max_Fields>;
  min?: Maybe<Quote_Min_Fields>;
  stddev?: Maybe<Quote_Stddev_Fields>;
  stddev_pop?: Maybe<Quote_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Quote_Stddev_Samp_Fields>;
  sum?: Maybe<Quote_Sum_Fields>;
  var_pop?: Maybe<Quote_Var_Pop_Fields>;
  var_samp?: Maybe<Quote_Var_Samp_Fields>;
  variance?: Maybe<Quote_Variance_Fields>;
};


/** aggregate fields of "quote" */
export type Quote_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Quote_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "quote" */
export type Quote_Aggregate_Order_By = {
  avg?: InputMaybe<Quote_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Quote_Max_Order_By>;
  min?: InputMaybe<Quote_Min_Order_By>;
  stddev?: InputMaybe<Quote_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Quote_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Quote_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Quote_Sum_Order_By>;
  var_pop?: InputMaybe<Quote_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Quote_Var_Samp_Order_By>;
  variance?: InputMaybe<Quote_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "quote" */
export type Quote_Arr_Rel_Insert_Input = {
  data: Array<Quote_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Quote_On_Conflict>;
};

/** aggregate avg on columns */
export type Quote_Avg_Fields = {
  __typename?: 'quote_avg_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "quote" */
export type Quote_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "quote". All fields are combined with a logical 'AND'. */
export type Quote_Bool_Exp = {
  _and?: InputMaybe<Array<Quote_Bool_Exp>>;
  _not?: InputMaybe<Quote_Bool_Exp>;
  _or?: InputMaybe<Array<Quote_Bool_Exp>>;
  final_lock?: InputMaybe<Boolean_Comparison_Exp>;
  firmware?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  job?: InputMaybe<Job_Bool_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  job_number?: InputMaybe<String_Comparison_Exp>;
  locked?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  products?: InputMaybe<Product_Bool_Exp>;
  products_aggregate?: InputMaybe<Product_Aggregate_Bool_Exp>;
  software?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "quote" */
export enum Quote_Constraint {
  /** unique or primary key constraint on columns "id" */
  QuotePkey = 'quote_pkey'
}

/** input type for incrementing numeric columns in table "quote" */
export type Quote_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "quote" */
export type Quote_Insert_Input = {
  final_lock?: InputMaybe<Scalars['Boolean']>;
  firmware?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  job?: InputMaybe<Job_Obj_Rel_Insert_Input>;
  job_id?: InputMaybe<Scalars['Int']>;
  job_number?: InputMaybe<Scalars['String']>;
  locked?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<Product_Arr_Rel_Insert_Input>;
  software?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Quote_Max_Fields = {
  __typename?: 'quote_max_fields';
  firmware?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  job_number?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  software?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "quote" */
export type Quote_Max_Order_By = {
  firmware?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  job_number?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  software?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Quote_Min_Fields = {
  __typename?: 'quote_min_fields';
  firmware?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  job_number?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  software?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "quote" */
export type Quote_Min_Order_By = {
  firmware?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  job_number?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  software?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "quote" */
export type Quote_Mutation_Response = {
  __typename?: 'quote_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Quote>;
};

/** input type for inserting object relation for remote table "quote" */
export type Quote_Obj_Rel_Insert_Input = {
  data: Quote_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Quote_On_Conflict>;
};

/** on_conflict condition type for table "quote" */
export type Quote_On_Conflict = {
  constraint: Quote_Constraint;
  update_columns?: Array<Quote_Update_Column>;
  where?: InputMaybe<Quote_Bool_Exp>;
};

/** Ordering options when selecting data from "quote". */
export type Quote_Order_By = {
  final_lock?: InputMaybe<Order_By>;
  firmware?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job?: InputMaybe<Job_Order_By>;
  job_id?: InputMaybe<Order_By>;
  job_number?: InputMaybe<Order_By>;
  locked?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  products_aggregate?: InputMaybe<Product_Aggregate_Order_By>;
  software?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: quote */
export type Quote_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "quote" */
export enum Quote_Select_Column {
  /** column name */
  FinalLock = 'final_lock',
  /** column name */
  Firmware = 'firmware',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  JobNumber = 'job_number',
  /** column name */
  Locked = 'locked',
  /** column name */
  Name = 'name',
  /** column name */
  Software = 'software',
  /** column name */
  Status = 'status'
}

/** select "quote_aggregate_bool_exp_bool_and_arguments_columns" columns of table "quote" */
export enum Quote_Select_Column_Quote_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  FinalLock = 'final_lock',
  /** column name */
  Locked = 'locked'
}

/** select "quote_aggregate_bool_exp_bool_or_arguments_columns" columns of table "quote" */
export enum Quote_Select_Column_Quote_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  FinalLock = 'final_lock',
  /** column name */
  Locked = 'locked'
}

/** input type for updating data in table "quote" */
export type Quote_Set_Input = {
  final_lock?: InputMaybe<Scalars['Boolean']>;
  firmware?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  job_number?: InputMaybe<Scalars['String']>;
  locked?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  software?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Quote_Stddev_Fields = {
  __typename?: 'quote_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "quote" */
export type Quote_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Quote_Stddev_Pop_Fields = {
  __typename?: 'quote_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "quote" */
export type Quote_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Quote_Stddev_Samp_Fields = {
  __typename?: 'quote_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "quote" */
export type Quote_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "quote" */
export type Quote_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Quote_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Quote_Stream_Cursor_Value_Input = {
  final_lock?: InputMaybe<Scalars['Boolean']>;
  firmware?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  job_number?: InputMaybe<Scalars['String']>;
  locked?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  software?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Quote_Sum_Fields = {
  __typename?: 'quote_sum_fields';
  id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "quote" */
export type Quote_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** update columns of table "quote" */
export enum Quote_Update_Column {
  /** column name */
  FinalLock = 'final_lock',
  /** column name */
  Firmware = 'firmware',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  JobNumber = 'job_number',
  /** column name */
  Locked = 'locked',
  /** column name */
  Name = 'name',
  /** column name */
  Software = 'software',
  /** column name */
  Status = 'status'
}

export type Quote_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Quote_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Quote_Set_Input>;
  /** filter the rows which have to be updated */
  where: Quote_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Quote_Var_Pop_Fields = {
  __typename?: 'quote_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "quote" */
export type Quote_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Quote_Var_Samp_Fields = {
  __typename?: 'quote_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "quote" */
export type Quote_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Quote_Variance_Fields = {
  __typename?: 'quote_variance_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "quote" */
export type Quote_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** generated for each product when quote is transmitted; can be many:1 for products with multiple quantities, not reused even if untrasmitted */
export type Serial_Number = {
  __typename?: 'serial_number';
  drc_job_number?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  job_id?: Maybe<Scalars['Int']>;
  pricing?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  serial_for_product?: Maybe<Product>;
  /** An object relationship */
  serial_in_job?: Maybe<Job>;
  serial_number: Scalars['Int'];
  unit_tag?: Maybe<Scalars['String']>;
};

/** aggregated selection of "serial_number" */
export type Serial_Number_Aggregate = {
  __typename?: 'serial_number_aggregate';
  aggregate?: Maybe<Serial_Number_Aggregate_Fields>;
  nodes: Array<Serial_Number>;
};

export type Serial_Number_Aggregate_Bool_Exp = {
  count?: InputMaybe<Serial_Number_Aggregate_Bool_Exp_Count>;
};

export type Serial_Number_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Serial_Number_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Serial_Number_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "serial_number" */
export type Serial_Number_Aggregate_Fields = {
  __typename?: 'serial_number_aggregate_fields';
  avg?: Maybe<Serial_Number_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Serial_Number_Max_Fields>;
  min?: Maybe<Serial_Number_Min_Fields>;
  stddev?: Maybe<Serial_Number_Stddev_Fields>;
  stddev_pop?: Maybe<Serial_Number_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Serial_Number_Stddev_Samp_Fields>;
  sum?: Maybe<Serial_Number_Sum_Fields>;
  var_pop?: Maybe<Serial_Number_Var_Pop_Fields>;
  var_samp?: Maybe<Serial_Number_Var_Samp_Fields>;
  variance?: Maybe<Serial_Number_Variance_Fields>;
};


/** aggregate fields of "serial_number" */
export type Serial_Number_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Serial_Number_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "serial_number" */
export type Serial_Number_Aggregate_Order_By = {
  avg?: InputMaybe<Serial_Number_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Serial_Number_Max_Order_By>;
  min?: InputMaybe<Serial_Number_Min_Order_By>;
  stddev?: InputMaybe<Serial_Number_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Serial_Number_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Serial_Number_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Serial_Number_Sum_Order_By>;
  var_pop?: InputMaybe<Serial_Number_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Serial_Number_Var_Samp_Order_By>;
  variance?: InputMaybe<Serial_Number_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "serial_number" */
export type Serial_Number_Arr_Rel_Insert_Input = {
  data: Array<Serial_Number_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Serial_Number_On_Conflict>;
};

/** aggregate avg on columns */
export type Serial_Number_Avg_Fields = {
  __typename?: 'serial_number_avg_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  serial_number?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "serial_number" */
export type Serial_Number_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "serial_number". All fields are combined with a logical 'AND'. */
export type Serial_Number_Bool_Exp = {
  _and?: InputMaybe<Array<Serial_Number_Bool_Exp>>;
  _not?: InputMaybe<Serial_Number_Bool_Exp>;
  _or?: InputMaybe<Array<Serial_Number_Bool_Exp>>;
  drc_job_number?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  pricing?: InputMaybe<String_Comparison_Exp>;
  product_id?: InputMaybe<Int_Comparison_Exp>;
  serial_for_product?: InputMaybe<Product_Bool_Exp>;
  serial_in_job?: InputMaybe<Job_Bool_Exp>;
  serial_number?: InputMaybe<Int_Comparison_Exp>;
  unit_tag?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "serial_number" */
export enum Serial_Number_Constraint {
  /** unique or primary key constraint on columns "id" */
  SerialNumberPkey = 'serial_number_pkey',
  /** unique or primary key constraint on columns "serial_number" */
  SerialNumberSerialNumberKey = 'serial_number_serial_number_key'
}

/** input type for incrementing numeric columns in table "serial_number" */
export type Serial_Number_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  product_id?: InputMaybe<Scalars['Int']>;
  serial_number?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "serial_number" */
export type Serial_Number_Insert_Input = {
  drc_job_number?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  pricing?: InputMaybe<Scalars['String']>;
  product_id?: InputMaybe<Scalars['Int']>;
  serial_for_product?: InputMaybe<Product_Obj_Rel_Insert_Input>;
  serial_in_job?: InputMaybe<Job_Obj_Rel_Insert_Input>;
  serial_number?: InputMaybe<Scalars['Int']>;
  unit_tag?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Serial_Number_Max_Fields = {
  __typename?: 'serial_number_max_fields';
  drc_job_number?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  pricing?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['Int']>;
  serial_number?: Maybe<Scalars['Int']>;
  unit_tag?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "serial_number" */
export type Serial_Number_Max_Order_By = {
  drc_job_number?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  pricing?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  unit_tag?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Serial_Number_Min_Fields = {
  __typename?: 'serial_number_min_fields';
  drc_job_number?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  pricing?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['Int']>;
  serial_number?: Maybe<Scalars['Int']>;
  unit_tag?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "serial_number" */
export type Serial_Number_Min_Order_By = {
  drc_job_number?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  pricing?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  unit_tag?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "serial_number" */
export type Serial_Number_Mutation_Response = {
  __typename?: 'serial_number_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Serial_Number>;
};

/** on_conflict condition type for table "serial_number" */
export type Serial_Number_On_Conflict = {
  constraint: Serial_Number_Constraint;
  update_columns?: Array<Serial_Number_Update_Column>;
  where?: InputMaybe<Serial_Number_Bool_Exp>;
};

/** Ordering options when selecting data from "serial_number". */
export type Serial_Number_Order_By = {
  drc_job_number?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  pricing?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  serial_for_product?: InputMaybe<Product_Order_By>;
  serial_in_job?: InputMaybe<Job_Order_By>;
  serial_number?: InputMaybe<Order_By>;
  unit_tag?: InputMaybe<Order_By>;
};

/** primary key columns input for table: serial_number */
export type Serial_Number_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "serial_number" */
export enum Serial_Number_Select_Column {
  /** column name */
  DrcJobNumber = 'drc_job_number',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  Pricing = 'pricing',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  SerialNumber = 'serial_number',
  /** column name */
  UnitTag = 'unit_tag'
}

/** input type for updating data in table "serial_number" */
export type Serial_Number_Set_Input = {
  drc_job_number?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  pricing?: InputMaybe<Scalars['String']>;
  product_id?: InputMaybe<Scalars['Int']>;
  serial_number?: InputMaybe<Scalars['Int']>;
  unit_tag?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Serial_Number_Stddev_Fields = {
  __typename?: 'serial_number_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  serial_number?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "serial_number" */
export type Serial_Number_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Serial_Number_Stddev_Pop_Fields = {
  __typename?: 'serial_number_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  serial_number?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "serial_number" */
export type Serial_Number_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Serial_Number_Stddev_Samp_Fields = {
  __typename?: 'serial_number_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  serial_number?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "serial_number" */
export type Serial_Number_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "serial_number" */
export type Serial_Number_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Serial_Number_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Serial_Number_Stream_Cursor_Value_Input = {
  drc_job_number?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  pricing?: InputMaybe<Scalars['String']>;
  product_id?: InputMaybe<Scalars['Int']>;
  serial_number?: InputMaybe<Scalars['Int']>;
  unit_tag?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Serial_Number_Sum_Fields = {
  __typename?: 'serial_number_sum_fields';
  id?: Maybe<Scalars['Int']>;
  job_id?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  serial_number?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "serial_number" */
export type Serial_Number_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
};

/** update columns of table "serial_number" */
export enum Serial_Number_Update_Column {
  /** column name */
  DrcJobNumber = 'drc_job_number',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  Pricing = 'pricing',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  SerialNumber = 'serial_number',
  /** column name */
  UnitTag = 'unit_tag'
}

export type Serial_Number_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Serial_Number_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Serial_Number_Set_Input>;
  /** filter the rows which have to be updated */
  where: Serial_Number_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Serial_Number_Var_Pop_Fields = {
  __typename?: 'serial_number_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  serial_number?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "serial_number" */
export type Serial_Number_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Serial_Number_Var_Samp_Fields = {
  __typename?: 'serial_number_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  serial_number?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "serial_number" */
export type Serial_Number_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Serial_Number_Variance_Fields = {
  __typename?: 'serial_number_variance_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  serial_number?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "serial_number" */
export type Serial_Number_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "configuration" */
  configuration: Array<Configuration>;
  /** fetch aggregated fields from the table: "configuration" */
  configuration_aggregate: Configuration_Aggregate;
  /** fetch data from the table: "configuration" using primary key columns */
  configuration_by_pk?: Maybe<Configuration>;
  /** fetch data from the table in a streaming manner: "configuration" */
  configuration_stream: Array<Configuration>;
  /** fetch data from the table: "control_panel_sizing" */
  control_panel_sizing: Array<Control_Panel_Sizing>;
  /** fetch aggregated fields from the table: "control_panel_sizing" */
  control_panel_sizing_aggregate: Control_Panel_Sizing_Aggregate;
  /** fetch data from the table: "control_panel_sizing" using primary key columns */
  control_panel_sizing_by_pk?: Maybe<Control_Panel_Sizing>;
  /** fetch data from the table in a streaming manner: "control_panel_sizing" */
  control_panel_sizing_stream: Array<Control_Panel_Sizing>;
  /** fetch data from the table: "cost" */
  cost: Array<Cost>;
  /** fetch aggregated fields from the table: "cost" */
  cost_aggregate: Cost_Aggregate;
  /** fetch data from the table: "cost" using primary key columns */
  cost_by_pk?: Maybe<Cost>;
  /** fetch data from the table in a streaming manner: "cost" */
  cost_stream: Array<Cost>;
  /** fetch data from the table: "fan_fit" */
  fan_fit: Array<Fan_Fit>;
  /** fetch aggregated fields from the table: "fan_fit" */
  fan_fit_aggregate: Fan_Fit_Aggregate;
  /** fetch data from the table: "fan_fit" using primary key columns */
  fan_fit_by_pk?: Maybe<Fan_Fit>;
  /** fetch data from the table in a streaming manner: "fan_fit" */
  fan_fit_stream: Array<Fan_Fit>;
  /** fetch data from the table: "fan_model_file" */
  fan_model_file: Array<Fan_Model_File>;
  /** fetch aggregated fields from the table: "fan_model_file" */
  fan_model_file_aggregate: Fan_Model_File_Aggregate;
  /** fetch data from the table: "fan_model_file" using primary key columns */
  fan_model_file_by_pk?: Maybe<Fan_Model_File>;
  /** fetch data from the table in a streaming manner: "fan_model_file" */
  fan_model_file_stream: Array<Fan_Model_File>;
  /** fetch data from the table: "job" */
  job: Array<Job>;
  /** fetch aggregated fields from the table: "job" */
  job_aggregate: Job_Aggregate;
  /** fetch data from the table: "job" using primary key columns */
  job_by_pk?: Maybe<Job>;
  /** fetch data from the table in a streaming manner: "job" */
  job_stream: Array<Job>;
  /** fetch data from the table: "product" */
  product: Array<Product>;
  /** fetch aggregated fields from the table: "product" */
  product_aggregate: Product_Aggregate;
  /** fetch data from the table: "product" using primary key columns */
  product_by_pk?: Maybe<Product>;
  /** fetch data from the table in a streaming manner: "product" */
  product_stream: Array<Product>;
  /** fetch data from the table: "quote" */
  quote: Array<Quote>;
  /** fetch aggregated fields from the table: "quote" */
  quote_aggregate: Quote_Aggregate;
  /** fetch data from the table: "quote" using primary key columns */
  quote_by_pk?: Maybe<Quote>;
  /** fetch data from the table in a streaming manner: "quote" */
  quote_stream: Array<Quote>;
  /** fetch data from the table: "serial_number" */
  serial_number: Array<Serial_Number>;
  /** fetch aggregated fields from the table: "serial_number" */
  serial_number_aggregate: Serial_Number_Aggregate;
  /** fetch data from the table: "serial_number" using primary key columns */
  serial_number_by_pk?: Maybe<Serial_Number>;
  /** fetch data from the table in a streaming manner: "serial_number" */
  serial_number_stream: Array<Serial_Number>;
};


export type Subscription_RootConfigurationArgs = {
  distinct_on?: InputMaybe<Array<Configuration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Configuration_Order_By>>;
  where?: InputMaybe<Configuration_Bool_Exp>;
};


export type Subscription_RootConfiguration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Configuration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Configuration_Order_By>>;
  where?: InputMaybe<Configuration_Bool_Exp>;
};


export type Subscription_RootConfiguration_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootConfiguration_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Configuration_Stream_Cursor_Input>>;
  where?: InputMaybe<Configuration_Bool_Exp>;
};


export type Subscription_RootControl_Panel_SizingArgs = {
  distinct_on?: InputMaybe<Array<Control_Panel_Sizing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Control_Panel_Sizing_Order_By>>;
  where?: InputMaybe<Control_Panel_Sizing_Bool_Exp>;
};


export type Subscription_RootControl_Panel_Sizing_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Panel_Sizing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Control_Panel_Sizing_Order_By>>;
  where?: InputMaybe<Control_Panel_Sizing_Bool_Exp>;
};


export type Subscription_RootControl_Panel_Sizing_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootControl_Panel_Sizing_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Control_Panel_Sizing_Stream_Cursor_Input>>;
  where?: InputMaybe<Control_Panel_Sizing_Bool_Exp>;
};


export type Subscription_RootCostArgs = {
  distinct_on?: InputMaybe<Array<Cost_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cost_Order_By>>;
  where?: InputMaybe<Cost_Bool_Exp>;
};


export type Subscription_RootCost_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cost_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cost_Order_By>>;
  where?: InputMaybe<Cost_Bool_Exp>;
};


export type Subscription_RootCost_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCost_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Cost_Stream_Cursor_Input>>;
  where?: InputMaybe<Cost_Bool_Exp>;
};


export type Subscription_RootFan_FitArgs = {
  distinct_on?: InputMaybe<Array<Fan_Fit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fan_Fit_Order_By>>;
  where?: InputMaybe<Fan_Fit_Bool_Exp>;
};


export type Subscription_RootFan_Fit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fan_Fit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fan_Fit_Order_By>>;
  where?: InputMaybe<Fan_Fit_Bool_Exp>;
};


export type Subscription_RootFan_Fit_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFan_Fit_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Fan_Fit_Stream_Cursor_Input>>;
  where?: InputMaybe<Fan_Fit_Bool_Exp>;
};


export type Subscription_RootFan_Model_FileArgs = {
  distinct_on?: InputMaybe<Array<Fan_Model_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fan_Model_File_Order_By>>;
  where?: InputMaybe<Fan_Model_File_Bool_Exp>;
};


export type Subscription_RootFan_Model_File_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fan_Model_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fan_Model_File_Order_By>>;
  where?: InputMaybe<Fan_Model_File_Bool_Exp>;
};


export type Subscription_RootFan_Model_File_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFan_Model_File_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Fan_Model_File_Stream_Cursor_Input>>;
  where?: InputMaybe<Fan_Model_File_Bool_Exp>;
};


export type Subscription_RootJobArgs = {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
};


export type Subscription_RootJob_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
};


export type Subscription_RootJob_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootJob_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Job_Stream_Cursor_Input>>;
  where?: InputMaybe<Job_Bool_Exp>;
};


export type Subscription_RootProductArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


export type Subscription_RootProduct_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


export type Subscription_RootProduct_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootProduct_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Product_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


export type Subscription_RootQuoteArgs = {
  distinct_on?: InputMaybe<Array<Quote_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Quote_Order_By>>;
  where?: InputMaybe<Quote_Bool_Exp>;
};


export type Subscription_RootQuote_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Quote_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Quote_Order_By>>;
  where?: InputMaybe<Quote_Bool_Exp>;
};


export type Subscription_RootQuote_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootQuote_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Quote_Stream_Cursor_Input>>;
  where?: InputMaybe<Quote_Bool_Exp>;
};


export type Subscription_RootSerial_NumberArgs = {
  distinct_on?: InputMaybe<Array<Serial_Number_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Serial_Number_Order_By>>;
  where?: InputMaybe<Serial_Number_Bool_Exp>;
};


export type Subscription_RootSerial_Number_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Serial_Number_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Serial_Number_Order_By>>;
  where?: InputMaybe<Serial_Number_Bool_Exp>;
};


export type Subscription_RootSerial_Number_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootSerial_Number_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Serial_Number_Stream_Cursor_Input>>;
  where?: InputMaybe<Serial_Number_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

export type ConfigurationFieldsFragment = { __typename: 'configuration', id: number, blade_diameter: any, blade_material: string, depth: any, fan_weight: any, fla_400: any, fla_460: any, manufacturer: string, max_rpm: any, model: string, nominal_hp: any, plate_dimensions: any, price: any, rmp_coefficients: string, voltage: any, damper_id?: any | null, k_factor?: number | null, has_changed: boolean, min_v_fla?: any | null, max_v_fla?: any | null };

export type GetConfigurationsQueryVariables = Exact<{
  voltage: Scalars['numeric'];
}>;


export type GetConfigurationsQuery = { __typename?: 'query_root', configuration: Array<{ __typename: 'configuration', id: number, blade_diameter: any, blade_material: string, depth: any, fan_weight: any, fla_400: any, fla_460: any, manufacturer: string, max_rpm: any, model: string, nominal_hp: any, plate_dimensions: any, price: any, rmp_coefficients: string, voltage: any, damper_id?: any | null, k_factor?: number | null, has_changed: boolean, min_v_fla?: any | null, max_v_fla?: any | null }> };

export type GetExistingConfigurationsQueryVariables = Exact<{
  where?: InputMaybe<Configuration_Bool_Exp>;
}>;


export type GetExistingConfigurationsQuery = { __typename?: 'query_root', configuration: Array<{ __typename?: 'configuration', id: number, model: string, manufacturer: string }> };

export type GetFanModelsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFanModelsQuery = { __typename?: 'query_root', configuration: Array<{ __typename?: 'configuration', model: string }> };

export type InsertConfigurationsMutationVariables = Exact<{
  objects?: InputMaybe<Array<Configuration_Insert_Input> | Configuration_Insert_Input>;
}>;


export type InsertConfigurationsMutation = { __typename?: 'mutation_root', insert_configuration?: { __typename?: 'configuration_mutation_response', returning: Array<{ __typename?: 'configuration', id: number, model: string, manufacturer: string }> } | null };

export type UpdateConfigurationMutationVariables = Exact<{
  pk_columns: Configuration_Pk_Columns_Input;
  _set: Configuration_Set_Input;
}>;


export type UpdateConfigurationMutation = { __typename?: 'mutation_root', update_configuration_by_pk?: { __typename?: 'configuration', id: number, model: string, manufacturer: string } | null };

export type UpsertConfigurationMutationVariables = Exact<{
  objects?: InputMaybe<Array<Configuration_Insert_Input> | Configuration_Insert_Input>;
}>;


export type UpsertConfigurationMutation = { __typename?: 'mutation_root', insert_configuration?: { __typename?: 'configuration_mutation_response', returning: Array<{ __typename: 'configuration', id: number, blade_diameter: any, blade_material: string, depth: any, fan_weight: any, fla_400: any, fla_460: any, manufacturer: string, max_rpm: any, model: string, nominal_hp: any, plate_dimensions: any, price: any, rmp_coefficients: string, voltage: any, damper_id?: any | null, k_factor?: number | null, has_changed: boolean, min_v_fla?: any | null, max_v_fla?: any | null, products_aggregate: { __typename?: 'product_aggregate', aggregate?: { __typename?: 'product_aggregate_fields', count: number } | null } }> } | null };

export type MarkConfigurationsChangedMutationVariables = Exact<{
  ids: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type MarkConfigurationsChangedMutation = { __typename?: 'mutation_root', update_configuration_many?: Array<{ __typename?: 'configuration_mutation_response', returning: Array<{ __typename?: 'configuration', id: number, has_changed: boolean }> } | null> | null };

export type SearchConfigurationByModelQueryVariables = Exact<{
  search: Scalars['String'];
}>;


export type SearchConfigurationByModelQuery = { __typename?: 'query_root', configuration: Array<{ __typename: 'configuration', id: number, blade_diameter: any, blade_material: string, depth: any, fan_weight: any, fla_400: any, fla_460: any, manufacturer: string, max_rpm: any, model: string, nominal_hp: any, plate_dimensions: any, price: any, rmp_coefficients: string, voltage: any, damper_id?: any | null, k_factor?: number | null, has_changed: boolean, min_v_fla?: any | null, max_v_fla?: any | null }> };

export type ControlPanelSizingFieldsFragment = { __typename: 'control_panel_sizing', id: number, amperage: number, cost: number, depth: number, fan_count: number, height: number, width: number, weight: number };

export type GetControlPanelSizingQueryVariables = Exact<{
  where?: InputMaybe<Control_Panel_Sizing_Bool_Exp>;
}>;


export type GetControlPanelSizingQuery = { __typename?: 'query_root', control_panel_sizing: Array<{ __typename: 'control_panel_sizing', id: number, amperage: number, cost: number, depth: number, fan_count: number, height: number, width: number, weight: number }> };

export type UpsertControlPanelSizingMutationVariables = Exact<{
  objects?: InputMaybe<Array<Control_Panel_Sizing_Insert_Input> | Control_Panel_Sizing_Insert_Input>;
}>;


export type UpsertControlPanelSizingMutation = { __typename?: 'mutation_root', insert_control_panel_sizing?: { __typename?: 'control_panel_sizing_mutation_response', returning: Array<{ __typename: 'control_panel_sizing', id: number, amperage: number, cost: number, depth: number, fan_count: number, height: number, width: number, weight: number }> } | null };

export type CostFieldsFragment = { __typename: 'cost', id: number, name: string, value: any };

export type GetCostsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCostsQuery = { __typename?: 'query_root', cost: Array<{ __typename: 'cost', id: number, name: string, value: any }> };

export type UpsertCostsMutationVariables = Exact<{
  objects?: InputMaybe<Array<Cost_Insert_Input> | Cost_Insert_Input>;
}>;


export type UpsertCostsMutation = { __typename?: 'mutation_root', insert_cost?: { __typename?: 'cost_mutation_response', returning: Array<{ __typename: 'cost', id: number, name: string, value: any }> } | null };

export type FanFitFieldsFragment = { __typename: 'fan_fit', id: number, manufacturer: string, fan_diameter: any, left_padding: any, right_padding: any, top_padding: any, bottom_padding: any, middle_column_padding: any, middle_row_padding: any };

export type GetFanFitsQueryVariables = Exact<{
  where?: InputMaybe<Fan_Fit_Bool_Exp>;
}>;


export type GetFanFitsQuery = { __typename?: 'query_root', fan_fit: Array<{ __typename: 'fan_fit', id: number, manufacturer: string, fan_diameter: any, left_padding: any, right_padding: any, top_padding: any, bottom_padding: any, middle_column_padding: any, middle_row_padding: any }> };

export type InsertFanFitsMutationVariables = Exact<{
  objects?: InputMaybe<Array<Fan_Fit_Insert_Input> | Fan_Fit_Insert_Input>;
}>;


export type InsertFanFitsMutation = { __typename?: 'mutation_root', insert_fan_fit?: { __typename?: 'fan_fit_mutation_response', returning: Array<{ __typename?: 'fan_fit', id: number, manufacturer: string, fan_diameter: any }> } | null };

export type UpdateFanFitMutationVariables = Exact<{
  pk_columns: Fan_Fit_Pk_Columns_Input;
  _set: Fan_Fit_Set_Input;
}>;


export type UpdateFanFitMutation = { __typename?: 'mutation_root', update_fan_fit_by_pk?: { __typename?: 'fan_fit', id: number, manufacturer: string, fan_diameter: any } | null };

export type UpsertFanFitMutationVariables = Exact<{
  objects?: InputMaybe<Array<Fan_Fit_Insert_Input> | Fan_Fit_Insert_Input>;
}>;


export type UpsertFanFitMutation = { __typename?: 'mutation_root', insert_fan_fit?: { __typename?: 'fan_fit_mutation_response', returning: Array<{ __typename: 'fan_fit', id: number, manufacturer: string, fan_diameter: any, left_padding: any, right_padding: any, top_padding: any, bottom_padding: any, middle_column_padding: any, middle_row_padding: any }> } | null };

export type FanModelFileFieldsFragment = { __typename: 'fan_model_file', id: number, model: string, front_file_id?: string | null, side_file_id?: string | null, top_file_id?: string | null, is_safe: boolean };

export type GetFanModelFileQueryVariables = Exact<{
  model: Scalars['String'];
}>;


export type GetFanModelFileQuery = { __typename?: 'query_root', fan_model_file: Array<{ __typename: 'fan_model_file', id: number, model: string, front_file_id?: string | null, side_file_id?: string | null, top_file_id?: string | null, is_safe: boolean }> };

export type UpsertFanModelFileMutationVariables = Exact<{
  object?: Fan_Model_File_Insert_Input;
}>;


export type UpsertFanModelFileMutation = { __typename?: 'mutation_root', insert_fan_model_file_one?: { __typename: 'fan_model_file', id: number, model: string, front_file_id?: string | null, side_file_id?: string | null, top_file_id?: string | null, is_safe: boolean } | null };

export type JobFieldsFragment = { __typename: 'job', id: number, user_id: any, name: string, status: string, created_on: any, updated_on: any };

export type GetJobsQueryVariables = Exact<{
  order_by?: InputMaybe<Array<Job_Order_By> | Job_Order_By>;
  where?: InputMaybe<Job_Bool_Exp>;
}>;


export type GetJobsQuery = { __typename?: 'query_root', job: Array<{ __typename?: 'job', id: number, user_id: any, name: string, status: string, created_on: any, updated_on: any, products_aggregate: { __typename?: 'product_aggregate', aggregate?: { __typename?: 'product_aggregate_fields', count: number } | null }, quotes_aggregate: { __typename?: 'quote_aggregate', aggregate?: { __typename?: 'quote_aggregate_fields', count: number } | null } }> };

export type GetJobByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetJobByIdQuery = { __typename?: 'query_root', job_by_pk?: { __typename?: 'job', id: number, user_id: any, name: string, status: string, created_on: any, updated_on: any, products: Array<{ __typename: 'product', air_flow: any, configuration_id?: number | null, configuration_quantity: number, height: any, id: number, job_id: number, power: any, quote_id?: number | null, tag: string, total_static_pressure: any, width: any, backdraft_dampers: boolean, over_one_hundred_percent_redundancy?: boolean | null, created_at: any, updated_at: any, configured_fan_count?: number | null, model_number?: string | null, mas_ec_plus?: boolean | null, outdoor_rating?: boolean | null, air_flow_orientation?: any | null, fan_layout?: string | null, set_voltage?: any | null, BACnet?: string | null, local_HMI?: boolean | null, pressure_transducers?: boolean | null, voltage_override?: any | null }>, quotes: Array<{ __typename: 'quote', id: number, job_id: number, name: string, status: string, locked: boolean, job_number?: string | null, final_lock: boolean, firmware?: string | null, software?: string | null }> } | null };

export type GetJobsForAdvancedSearchQueryVariables = Exact<{
  order_by?: InputMaybe<Array<Job_Order_By> | Job_Order_By>;
  where?: InputMaybe<Job_Bool_Exp>;
}>;


export type GetJobsForAdvancedSearchQuery = { __typename?: 'query_root', job: Array<{ __typename?: 'job', id: number, user_id: any, name: string, status: string, created_on: any, updated_on: any, products_aggregate: { __typename?: 'product_aggregate', aggregate?: { __typename?: 'product_aggregate_fields', count: number } | null }, quotes_aggregate: { __typename?: 'quote_aggregate', aggregate?: { __typename?: 'quote_aggregate_fields', count: number } | null }, products: Array<{ __typename?: 'product', model_number?: string | null, mas_ec_plus?: boolean | null, configured_fan_count?: number | null, tag: string, created_at: any, quote_id?: number | null, has_serial_number: Array<{ __typename?: 'serial_number', serial_number: number }>, configuration?: { __typename?: 'configuration', model: string, manufacturer: string } | null, quote?: { __typename?: 'quote', name: string } | null }>, quotes: Array<{ __typename?: 'quote', name: string, id: number, status: string, job_number?: string | null }> }> };

export type InsertJobMutationVariables = Exact<{
  object: Job_Insert_Input;
}>;


export type InsertJobMutation = { __typename?: 'mutation_root', insert_job_one?: { __typename: 'job', id: number, user_id: any, name: string, status: string, created_on: any, updated_on: any } | null };

export type UpdateJobMutationVariables = Exact<{
  pk_columns: Job_Pk_Columns_Input;
  _set: Job_Set_Input;
}>;


export type UpdateJobMutation = { __typename?: 'mutation_root', update_job_by_pk?: { __typename: 'job', id: number, user_id: any, name: string, status: string, created_on: any, updated_on: any } | null };

export type DeleteJobByIdMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteJobByIdMutation = { __typename?: 'mutation_root', delete_job_by_pk?: { __typename?: 'job', id: number } | null };

export type ProductFieldsFragment = { __typename: 'product', air_flow: any, configuration_id?: number | null, configuration_quantity: number, height: any, id: number, job_id: number, power: any, quote_id?: number | null, tag: string, total_static_pressure: any, width: any, backdraft_dampers: boolean, over_one_hundred_percent_redundancy?: boolean | null, created_at: any, updated_at: any, configured_fan_count?: number | null, model_number?: string | null, mas_ec_plus?: boolean | null, outdoor_rating?: boolean | null, air_flow_orientation?: any | null, fan_layout?: string | null, set_voltage?: any | null, BACnet?: string | null, local_HMI?: boolean | null, pressure_transducers?: boolean | null, voltage_override?: any | null };

export type GetProductQueryVariables = Exact<{
  product_id: Scalars['Int'];
}>;


export type GetProductQuery = { __typename?: 'query_root', product_by_pk?: { __typename: 'product', air_flow: any, configuration_id?: number | null, configuration_quantity: number, height: any, id: number, job_id: number, power: any, quote_id?: number | null, tag: string, total_static_pressure: any, width: any, backdraft_dampers: boolean, over_one_hundred_percent_redundancy?: boolean | null, created_at: any, updated_at: any, configured_fan_count?: number | null, model_number?: string | null, mas_ec_plus?: boolean | null, outdoor_rating?: boolean | null, air_flow_orientation?: any | null, fan_layout?: string | null, set_voltage?: any | null, BACnet?: string | null, local_HMI?: boolean | null, pressure_transducers?: boolean | null, voltage_override?: any | null } | null };

export type GetProductsQueryVariables = Exact<{
  where?: InputMaybe<Product_Bool_Exp>;
}>;


export type GetProductsQuery = { __typename?: 'query_root', product: Array<{ __typename: 'product', air_flow: any, configuration_id?: number | null, configuration_quantity: number, height: any, id: number, job_id: number, power: any, quote_id?: number | null, tag: string, total_static_pressure: any, width: any, backdraft_dampers: boolean, over_one_hundred_percent_redundancy?: boolean | null, created_at: any, updated_at: any, configured_fan_count?: number | null, model_number?: string | null, mas_ec_plus?: boolean | null, outdoor_rating?: boolean | null, air_flow_orientation?: any | null, fan_layout?: string | null, set_voltage?: any | null, BACnet?: string | null, local_HMI?: boolean | null, pressure_transducers?: boolean | null, voltage_override?: any | null, configuration?: { __typename: 'configuration', id: number, blade_diameter: any, blade_material: string, depth: any, fan_weight: any, fla_400: any, fla_460: any, manufacturer: string, max_rpm: any, model: string, nominal_hp: any, plate_dimensions: any, price: any, rmp_coefficients: string, voltage: any, damper_id?: any | null, k_factor?: number | null, has_changed: boolean, min_v_fla?: any | null, max_v_fla?: any | null } | null, has_serial_number: Array<{ __typename: 'serial_number', id: number, serial_number: number, product_id?: number | null, job_id?: number | null, drc_job_number?: string | null, pricing?: string | null, unit_tag?: string | null }> }> };

export type InsertProductMutationVariables = Exact<{
  object: Product_Insert_Input;
}>;


export type InsertProductMutation = { __typename?: 'mutation_root', insert_product_one?: { __typename: 'product', air_flow: any, configuration_id?: number | null, configuration_quantity: number, height: any, id: number, job_id: number, power: any, quote_id?: number | null, tag: string, total_static_pressure: any, width: any, backdraft_dampers: boolean, over_one_hundred_percent_redundancy?: boolean | null, created_at: any, updated_at: any, configured_fan_count?: number | null, model_number?: string | null, mas_ec_plus?: boolean | null, outdoor_rating?: boolean | null, air_flow_orientation?: any | null, fan_layout?: string | null, set_voltage?: any | null, BACnet?: string | null, local_HMI?: boolean | null, pressure_transducers?: boolean | null, voltage_override?: any | null, configuration?: { __typename: 'configuration', id: number, blade_diameter: any, blade_material: string, depth: any, fan_weight: any, fla_400: any, fla_460: any, manufacturer: string, max_rpm: any, model: string, nominal_hp: any, plate_dimensions: any, price: any, rmp_coefficients: string, voltage: any, damper_id?: any | null, k_factor?: number | null, has_changed: boolean, min_v_fla?: any | null, max_v_fla?: any | null } | null, has_serial_number: Array<{ __typename: 'serial_number', id: number, serial_number: number, product_id?: number | null, job_id?: number | null, drc_job_number?: string | null, pricing?: string | null, unit_tag?: string | null }> } | null };

export type InsertProductsMutationVariables = Exact<{
  objects?: InputMaybe<Array<Product_Insert_Input> | Product_Insert_Input>;
}>;


export type InsertProductsMutation = { __typename?: 'mutation_root', insert_product?: { __typename?: 'product_mutation_response', returning: Array<{ __typename?: 'product', id: number }> } | null };

export type UpdateProductMutationVariables = Exact<{
  pk_columns: Product_Pk_Columns_Input;
  _set: Product_Set_Input;
}>;


export type UpdateProductMutation = { __typename?: 'mutation_root', update_product_by_pk?: { __typename: 'product', air_flow: any, configuration_id?: number | null, configuration_quantity: number, height: any, id: number, job_id: number, power: any, quote_id?: number | null, tag: string, total_static_pressure: any, width: any, backdraft_dampers: boolean, over_one_hundred_percent_redundancy?: boolean | null, created_at: any, updated_at: any, configured_fan_count?: number | null, model_number?: string | null, mas_ec_plus?: boolean | null, outdoor_rating?: boolean | null, air_flow_orientation?: any | null, fan_layout?: string | null, set_voltage?: any | null, BACnet?: string | null, local_HMI?: boolean | null, pressure_transducers?: boolean | null, voltage_override?: any | null } | null };

export type DeleteProductMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteProductMutation = { __typename?: 'mutation_root', delete_product_by_pk?: { __typename: 'product', air_flow: any, configuration_id?: number | null, configuration_quantity: number, height: any, id: number, job_id: number, power: any, quote_id?: number | null, tag: string, total_static_pressure: any, width: any, backdraft_dampers: boolean, over_one_hundred_percent_redundancy?: boolean | null, created_at: any, updated_at: any, configured_fan_count?: number | null, model_number?: string | null, mas_ec_plus?: boolean | null, outdoor_rating?: boolean | null, air_flow_orientation?: any | null, fan_layout?: string | null, set_voltage?: any | null, BACnet?: string | null, local_HMI?: boolean | null, pressure_transducers?: boolean | null, voltage_override?: any | null } | null };

export type QuoteFieldsFragment = { __typename: 'quote', id: number, job_id: number, name: string, status: string, locked: boolean, job_number?: string | null, final_lock: boolean, firmware?: string | null, software?: string | null };

export type GetQuotesByJobIdQueryVariables = Exact<{
  jobId: Scalars['Int'];
}>;


export type GetQuotesByJobIdQuery = { __typename?: 'query_root', quote: Array<{ __typename: 'quote', id: number, job_id: number, name: string, status: string, locked: boolean, job_number?: string | null, final_lock: boolean, firmware?: string | null, software?: string | null, products: Array<{ __typename: 'product', air_flow: any, configuration_id?: number | null, configuration_quantity: number, height: any, id: number, job_id: number, power: any, quote_id?: number | null, tag: string, total_static_pressure: any, width: any, backdraft_dampers: boolean, over_one_hundred_percent_redundancy?: boolean | null, created_at: any, updated_at: any, configured_fan_count?: number | null, model_number?: string | null, mas_ec_plus?: boolean | null, outdoor_rating?: boolean | null, air_flow_orientation?: any | null, fan_layout?: string | null, set_voltage?: any | null, BACnet?: string | null, local_HMI?: boolean | null, pressure_transducers?: boolean | null, voltage_override?: any | null, configuration?: { __typename: 'configuration', id: number, blade_diameter: any, blade_material: string, depth: any, fan_weight: any, fla_400: any, fla_460: any, manufacturer: string, max_rpm: any, model: string, nominal_hp: any, plate_dimensions: any, price: any, rmp_coefficients: string, voltage: any, damper_id?: any | null, k_factor?: number | null, has_changed: boolean, min_v_fla?: any | null, max_v_fla?: any | null } | null, has_serial_number: Array<{ __typename: 'serial_number', id: number, serial_number: number, product_id?: number | null, job_id?: number | null, drc_job_number?: string | null, pricing?: string | null, unit_tag?: string | null }> }> }> };

export type InsertQuoteMutationVariables = Exact<{
  object: Quote_Insert_Input;
}>;


export type InsertQuoteMutation = { __typename?: 'mutation_root', insert_quote_one?: { __typename: 'quote', id: number, job_id: number, name: string, status: string, locked: boolean, job_number?: string | null, final_lock: boolean, firmware?: string | null, software?: string | null } | null };

export type InsertQuotesMutationVariables = Exact<{
  objects?: InputMaybe<Array<Quote_Insert_Input> | Quote_Insert_Input>;
}>;


export type InsertQuotesMutation = { __typename?: 'mutation_root', insert_quote?: { __typename?: 'quote_mutation_response', returning: Array<{ __typename?: 'quote', id: number }> } | null };

export type UpdateQuoteMutationVariables = Exact<{
  pk_columns: Quote_Pk_Columns_Input;
  _set: Quote_Set_Input;
}>;


export type UpdateQuoteMutation = { __typename?: 'mutation_root', update_quote_by_pk?: { __typename: 'quote', id: number, job_id: number, name: string, status: string, locked: boolean, job_number?: string | null, final_lock: boolean, firmware?: string | null, software?: string | null } | null };

export type DeleteQuoteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteQuoteMutation = { __typename?: 'mutation_root', delete_quote_by_pk?: { __typename: 'quote', id: number, job_id: number, name: string, status: string, locked: boolean, job_number?: string | null, final_lock: boolean, firmware?: string | null, software?: string | null } | null };

export type SerialNumberFieldsFragment = { __typename: 'serial_number', id: number, serial_number: number, product_id?: number | null, job_id?: number | null, drc_job_number?: string | null, pricing?: string | null, unit_tag?: string | null };

export type GetSerialNumberByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetSerialNumberByIdQuery = { __typename?: 'query_root', serial_number_by_pk?: { __typename?: 'serial_number', id: number, serial_number: number, product_id?: number | null, job_id?: number | null, drc_job_number?: string | null, pricing?: string | null, unit_tag?: string | null, serial_for_product?: { __typename: 'product', air_flow: any, configuration_id?: number | null, configuration_quantity: number, height: any, id: number, job_id: number, power: any, quote_id?: number | null, tag: string, total_static_pressure: any, width: any, backdraft_dampers: boolean, over_one_hundred_percent_redundancy?: boolean | null, created_at: any, updated_at: any, configured_fan_count?: number | null, model_number?: string | null, mas_ec_plus?: boolean | null, outdoor_rating?: boolean | null, air_flow_orientation?: any | null, fan_layout?: string | null, set_voltage?: any | null, BACnet?: string | null, local_HMI?: boolean | null, pressure_transducers?: boolean | null, voltage_override?: any | null } | null, serial_in_job?: { __typename: 'job', id: number, user_id: any, name: string, status: string, created_on: any, updated_on: any } | null } | null };

export type GetProductBySerialNumberQueryVariables = Exact<{
  _eq: Scalars['Int'];
}>;


export type GetProductBySerialNumberQuery = { __typename?: 'query_root', serial_number: Array<{ __typename?: 'serial_number', serial_for_product?: { __typename: 'product', air_flow: any, configuration_id?: number | null, configuration_quantity: number, height: any, id: number, job_id: number, power: any, quote_id?: number | null, tag: string, total_static_pressure: any, width: any, backdraft_dampers: boolean, over_one_hundred_percent_redundancy?: boolean | null, created_at: any, updated_at: any, configured_fan_count?: number | null, model_number?: string | null, mas_ec_plus?: boolean | null, outdoor_rating?: boolean | null, air_flow_orientation?: any | null, fan_layout?: string | null, set_voltage?: any | null, BACnet?: string | null, local_HMI?: boolean | null, pressure_transducers?: boolean | null, voltage_override?: any | null } | null, serial_in_job?: { __typename: 'job', id: number, user_id: any, name: string, status: string, created_on: any, updated_on: any } | null }> };

export type GetSerialNumbersForJobQueryVariables = Exact<{
  job_id: Scalars['Int'];
}>;


export type GetSerialNumbersForJobQuery = { __typename?: 'query_root', serial_number: Array<{ __typename?: 'serial_number', serial_number: number, product_id?: number | null }> };

export type InsertSerialNumberMutationVariables = Exact<{
  object: Serial_Number_Insert_Input;
}>;


export type InsertSerialNumberMutation = { __typename?: 'mutation_root', insert_serial_number_one?: { __typename: 'serial_number', id: number, serial_number: number, product_id?: number | null, job_id?: number | null, drc_job_number?: string | null, pricing?: string | null, unit_tag?: string | null } | null };

export type UpdateSerialNumberMutationVariables = Exact<{
  pk_columns: Serial_Number_Pk_Columns_Input;
  _set: Serial_Number_Set_Input;
}>;


export type UpdateSerialNumberMutation = { __typename?: 'mutation_root', update_serial_number_by_pk?: { __typename: 'serial_number', id: number, serial_number: number, product_id?: number | null, job_id?: number | null, drc_job_number?: string | null, pricing?: string | null, unit_tag?: string | null } | null };

export type ClearSerialNumberMutationVariables = Exact<{
  _eq: Scalars['Int'];
}>;


export type ClearSerialNumberMutation = { __typename?: 'mutation_root', update_serial_number_many?: Array<{ __typename?: 'serial_number_mutation_response', returning: Array<{ __typename?: 'serial_number', id: number, serial_number: number }> } | null> | null };

export type GetMaxSerialQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMaxSerialQuery = { __typename?: 'query_root', serial_number: Array<{ __typename?: 'serial_number', serial_number: number }> };

export const ConfigurationFieldsFragmentDoc = gql`
    fragment ConfigurationFields on configuration {
  __typename
  id
  blade_diameter
  blade_material
  depth
  fan_weight
  fla_400
  fla_460
  manufacturer
  max_rpm
  model
  nominal_hp
  plate_dimensions
  price
  rmp_coefficients
  voltage
  damper_id
  k_factor
  has_changed
  min_v_fla
  max_v_fla
}
    `;
export const ControlPanelSizingFieldsFragmentDoc = gql`
    fragment ControlPanelSizingFields on control_panel_sizing {
  __typename
  id
  amperage
  cost
  depth
  fan_count
  height
  width
  weight
}
    `;
export const CostFieldsFragmentDoc = gql`
    fragment CostFields on cost {
  __typename
  id
  name
  value
}
    `;
export const FanFitFieldsFragmentDoc = gql`
    fragment FanFitFields on fan_fit {
  __typename
  id
  manufacturer
  fan_diameter
  left_padding
  right_padding
  top_padding
  bottom_padding
  middle_column_padding
  middle_row_padding
}
    `;
export const FanModelFileFieldsFragmentDoc = gql`
    fragment FanModelFileFields on fan_model_file {
  __typename
  id
  model
  front_file_id
  side_file_id
  top_file_id
  is_safe
}
    `;
export const JobFieldsFragmentDoc = gql`
    fragment JobFields on job {
  __typename
  id
  user_id
  name
  status
  created_on
  updated_on
}
    `;
export const ProductFieldsFragmentDoc = gql`
    fragment ProductFields on product {
  __typename
  air_flow
  configuration_id
  configuration_quantity
  height
  id
  job_id
  power
  quote_id
  tag
  total_static_pressure
  width
  backdraft_dampers
  over_one_hundred_percent_redundancy
  created_at
  updated_at
  configured_fan_count
  model_number
  mas_ec_plus
  outdoor_rating
  air_flow_orientation
  fan_layout
  set_voltage
  BACnet
  local_HMI
  pressure_transducers
  voltage_override
}
    `;
export const QuoteFieldsFragmentDoc = gql`
    fragment QuoteFields on quote {
  __typename
  id
  job_id
  name
  status
  locked
  job_number
  final_lock
  firmware
  software
}
    `;
export const SerialNumberFieldsFragmentDoc = gql`
    fragment SerialNumberFields on serial_number {
  __typename
  id
  serial_number
  product_id
  job_id
  drc_job_number
  pricing
  unit_tag
}
    `;
export const GetConfigurationsDocument = gql`
    query GetConfigurations($voltage: numeric!) {
  configuration(where: {voltage: {_eq: $voltage}}) {
    ...ConfigurationFields
  }
}
    ${ConfigurationFieldsFragmentDoc}`;

/**
 * __useGetConfigurationsQuery__
 *
 * To run a query within a React component, call `useGetConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConfigurationsQuery({
 *   variables: {
 *      voltage: // value for 'voltage'
 *   },
 * });
 */
export function useGetConfigurationsQuery(baseOptions: Apollo.QueryHookOptions<GetConfigurationsQuery, GetConfigurationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConfigurationsQuery, GetConfigurationsQueryVariables>(GetConfigurationsDocument, options);
      }
export function useGetConfigurationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConfigurationsQuery, GetConfigurationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConfigurationsQuery, GetConfigurationsQueryVariables>(GetConfigurationsDocument, options);
        }
export type GetConfigurationsQueryHookResult = ReturnType<typeof useGetConfigurationsQuery>;
export type GetConfigurationsLazyQueryHookResult = ReturnType<typeof useGetConfigurationsLazyQuery>;
export type GetConfigurationsQueryResult = Apollo.QueryResult<GetConfigurationsQuery, GetConfigurationsQueryVariables>;
export const GetExistingConfigurationsDocument = gql`
    query GetExistingConfigurations($where: configuration_bool_exp) {
  configuration(where: $where) {
    id
    model
    manufacturer
  }
}
    `;

/**
 * __useGetExistingConfigurationsQuery__
 *
 * To run a query within a React component, call `useGetExistingConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExistingConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExistingConfigurationsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetExistingConfigurationsQuery(baseOptions?: Apollo.QueryHookOptions<GetExistingConfigurationsQuery, GetExistingConfigurationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExistingConfigurationsQuery, GetExistingConfigurationsQueryVariables>(GetExistingConfigurationsDocument, options);
      }
export function useGetExistingConfigurationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExistingConfigurationsQuery, GetExistingConfigurationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExistingConfigurationsQuery, GetExistingConfigurationsQueryVariables>(GetExistingConfigurationsDocument, options);
        }
export type GetExistingConfigurationsQueryHookResult = ReturnType<typeof useGetExistingConfigurationsQuery>;
export type GetExistingConfigurationsLazyQueryHookResult = ReturnType<typeof useGetExistingConfigurationsLazyQuery>;
export type GetExistingConfigurationsQueryResult = Apollo.QueryResult<GetExistingConfigurationsQuery, GetExistingConfigurationsQueryVariables>;
export const GetFanModelsDocument = gql`
    query GetFanModels {
  configuration {
    model
  }
}
    `;

/**
 * __useGetFanModelsQuery__
 *
 * To run a query within a React component, call `useGetFanModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFanModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFanModelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFanModelsQuery(baseOptions?: Apollo.QueryHookOptions<GetFanModelsQuery, GetFanModelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFanModelsQuery, GetFanModelsQueryVariables>(GetFanModelsDocument, options);
      }
export function useGetFanModelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFanModelsQuery, GetFanModelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFanModelsQuery, GetFanModelsQueryVariables>(GetFanModelsDocument, options);
        }
export type GetFanModelsQueryHookResult = ReturnType<typeof useGetFanModelsQuery>;
export type GetFanModelsLazyQueryHookResult = ReturnType<typeof useGetFanModelsLazyQuery>;
export type GetFanModelsQueryResult = Apollo.QueryResult<GetFanModelsQuery, GetFanModelsQueryVariables>;
export const InsertConfigurationsDocument = gql`
    mutation InsertConfigurations($objects: [configuration_insert_input!] = {}) {
  insert_configuration(objects: $objects) {
    returning {
      id
      model
      manufacturer
    }
  }
}
    `;
export type InsertConfigurationsMutationFn = Apollo.MutationFunction<InsertConfigurationsMutation, InsertConfigurationsMutationVariables>;

/**
 * __useInsertConfigurationsMutation__
 *
 * To run a mutation, you first call `useInsertConfigurationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertConfigurationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertConfigurationsMutation, { data, loading, error }] = useInsertConfigurationsMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertConfigurationsMutation(baseOptions?: Apollo.MutationHookOptions<InsertConfigurationsMutation, InsertConfigurationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertConfigurationsMutation, InsertConfigurationsMutationVariables>(InsertConfigurationsDocument, options);
      }
export type InsertConfigurationsMutationHookResult = ReturnType<typeof useInsertConfigurationsMutation>;
export type InsertConfigurationsMutationResult = Apollo.MutationResult<InsertConfigurationsMutation>;
export type InsertConfigurationsMutationOptions = Apollo.BaseMutationOptions<InsertConfigurationsMutation, InsertConfigurationsMutationVariables>;
export const UpdateConfigurationDocument = gql`
    mutation UpdateConfiguration($pk_columns: configuration_pk_columns_input!, $_set: configuration_set_input!) {
  update_configuration_by_pk(pk_columns: $pk_columns, _set: $_set) {
    id
    model
    manufacturer
  }
}
    `;
export type UpdateConfigurationMutationFn = Apollo.MutationFunction<UpdateConfigurationMutation, UpdateConfigurationMutationVariables>;

/**
 * __useUpdateConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConfigurationMutation, { data, loading, error }] = useUpdateConfigurationMutation({
 *   variables: {
 *      pk_columns: // value for 'pk_columns'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConfigurationMutation, UpdateConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateConfigurationMutation, UpdateConfigurationMutationVariables>(UpdateConfigurationDocument, options);
      }
export type UpdateConfigurationMutationHookResult = ReturnType<typeof useUpdateConfigurationMutation>;
export type UpdateConfigurationMutationResult = Apollo.MutationResult<UpdateConfigurationMutation>;
export type UpdateConfigurationMutationOptions = Apollo.BaseMutationOptions<UpdateConfigurationMutation, UpdateConfigurationMutationVariables>;
export const UpsertConfigurationDocument = gql`
    mutation UpsertConfiguration($objects: [configuration_insert_input!] = {}) {
  insert_configuration(
    objects: $objects
    on_conflict: {constraint: configuration_manufacturer_model_key, update_columns: [model, manufacturer, blade_diameter, blade_material, depth, fan_weight, fla_400, fla_460, max_rpm, nominal_hp, plate_dimensions, price, rmp_coefficients, voltage, k_factor, min_v_fla, max_v_fla]}
  ) {
    returning {
      ...ConfigurationFields
      products_aggregate {
        aggregate {
          count
        }
      }
    }
  }
}
    ${ConfigurationFieldsFragmentDoc}`;
export type UpsertConfigurationMutationFn = Apollo.MutationFunction<UpsertConfigurationMutation, UpsertConfigurationMutationVariables>;

/**
 * __useUpsertConfigurationMutation__
 *
 * To run a mutation, you first call `useUpsertConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertConfigurationMutation, { data, loading, error }] = useUpsertConfigurationMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpsertConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<UpsertConfigurationMutation, UpsertConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertConfigurationMutation, UpsertConfigurationMutationVariables>(UpsertConfigurationDocument, options);
      }
export type UpsertConfigurationMutationHookResult = ReturnType<typeof useUpsertConfigurationMutation>;
export type UpsertConfigurationMutationResult = Apollo.MutationResult<UpsertConfigurationMutation>;
export type UpsertConfigurationMutationOptions = Apollo.BaseMutationOptions<UpsertConfigurationMutation, UpsertConfigurationMutationVariables>;
export const MarkConfigurationsChangedDocument = gql`
    mutation MarkConfigurationsChanged($ids: [Int!]!) {
  update_configuration_many(
    updates: {where: {id: {_in: $ids}}, _set: {has_changed: true}}
  ) {
    returning {
      id
      has_changed
    }
  }
}
    `;
export type MarkConfigurationsChangedMutationFn = Apollo.MutationFunction<MarkConfigurationsChangedMutation, MarkConfigurationsChangedMutationVariables>;

/**
 * __useMarkConfigurationsChangedMutation__
 *
 * To run a mutation, you first call `useMarkConfigurationsChangedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkConfigurationsChangedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markConfigurationsChangedMutation, { data, loading, error }] = useMarkConfigurationsChangedMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useMarkConfigurationsChangedMutation(baseOptions?: Apollo.MutationHookOptions<MarkConfigurationsChangedMutation, MarkConfigurationsChangedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkConfigurationsChangedMutation, MarkConfigurationsChangedMutationVariables>(MarkConfigurationsChangedDocument, options);
      }
export type MarkConfigurationsChangedMutationHookResult = ReturnType<typeof useMarkConfigurationsChangedMutation>;
export type MarkConfigurationsChangedMutationResult = Apollo.MutationResult<MarkConfigurationsChangedMutation>;
export type MarkConfigurationsChangedMutationOptions = Apollo.BaseMutationOptions<MarkConfigurationsChangedMutation, MarkConfigurationsChangedMutationVariables>;
export const SearchConfigurationByModelDocument = gql`
    query SearchConfigurationByModel($search: String!) {
  configuration(where: {model: {_ilike: $search}}, order_by: {id: asc}) {
    ...ConfigurationFields
  }
}
    ${ConfigurationFieldsFragmentDoc}`;

/**
 * __useSearchConfigurationByModelQuery__
 *
 * To run a query within a React component, call `useSearchConfigurationByModelQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchConfigurationByModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchConfigurationByModelQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchConfigurationByModelQuery(baseOptions: Apollo.QueryHookOptions<SearchConfigurationByModelQuery, SearchConfigurationByModelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchConfigurationByModelQuery, SearchConfigurationByModelQueryVariables>(SearchConfigurationByModelDocument, options);
      }
export function useSearchConfigurationByModelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchConfigurationByModelQuery, SearchConfigurationByModelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchConfigurationByModelQuery, SearchConfigurationByModelQueryVariables>(SearchConfigurationByModelDocument, options);
        }
export type SearchConfigurationByModelQueryHookResult = ReturnType<typeof useSearchConfigurationByModelQuery>;
export type SearchConfigurationByModelLazyQueryHookResult = ReturnType<typeof useSearchConfigurationByModelLazyQuery>;
export type SearchConfigurationByModelQueryResult = Apollo.QueryResult<SearchConfigurationByModelQuery, SearchConfigurationByModelQueryVariables>;
export const GetControlPanelSizingDocument = gql`
    query GetControlPanelSizing($where: control_panel_sizing_bool_exp = {}) {
  control_panel_sizing(where: $where) {
    ...ControlPanelSizingFields
  }
}
    ${ControlPanelSizingFieldsFragmentDoc}`;

/**
 * __useGetControlPanelSizingQuery__
 *
 * To run a query within a React component, call `useGetControlPanelSizingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetControlPanelSizingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetControlPanelSizingQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetControlPanelSizingQuery(baseOptions?: Apollo.QueryHookOptions<GetControlPanelSizingQuery, GetControlPanelSizingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetControlPanelSizingQuery, GetControlPanelSizingQueryVariables>(GetControlPanelSizingDocument, options);
      }
export function useGetControlPanelSizingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetControlPanelSizingQuery, GetControlPanelSizingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetControlPanelSizingQuery, GetControlPanelSizingQueryVariables>(GetControlPanelSizingDocument, options);
        }
export type GetControlPanelSizingQueryHookResult = ReturnType<typeof useGetControlPanelSizingQuery>;
export type GetControlPanelSizingLazyQueryHookResult = ReturnType<typeof useGetControlPanelSizingLazyQuery>;
export type GetControlPanelSizingQueryResult = Apollo.QueryResult<GetControlPanelSizingQuery, GetControlPanelSizingQueryVariables>;
export const UpsertControlPanelSizingDocument = gql`
    mutation UpsertControlPanelSizing($objects: [control_panel_sizing_insert_input!] = {}) {
  insert_control_panel_sizing(
    objects: $objects
    on_conflict: {constraint: control_panel_sizing_fan_count_amperage_key, update_columns: [cost, depth, height, width, weight]}
  ) {
    returning {
      ...ControlPanelSizingFields
    }
  }
}
    ${ControlPanelSizingFieldsFragmentDoc}`;
export type UpsertControlPanelSizingMutationFn = Apollo.MutationFunction<UpsertControlPanelSizingMutation, UpsertControlPanelSizingMutationVariables>;

/**
 * __useUpsertControlPanelSizingMutation__
 *
 * To run a mutation, you first call `useUpsertControlPanelSizingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertControlPanelSizingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertControlPanelSizingMutation, { data, loading, error }] = useUpsertControlPanelSizingMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpsertControlPanelSizingMutation(baseOptions?: Apollo.MutationHookOptions<UpsertControlPanelSizingMutation, UpsertControlPanelSizingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertControlPanelSizingMutation, UpsertControlPanelSizingMutationVariables>(UpsertControlPanelSizingDocument, options);
      }
export type UpsertControlPanelSizingMutationHookResult = ReturnType<typeof useUpsertControlPanelSizingMutation>;
export type UpsertControlPanelSizingMutationResult = Apollo.MutationResult<UpsertControlPanelSizingMutation>;
export type UpsertControlPanelSizingMutationOptions = Apollo.BaseMutationOptions<UpsertControlPanelSizingMutation, UpsertControlPanelSizingMutationVariables>;
export const GetCostsDocument = gql`
    query GetCosts {
  cost {
    ...CostFields
  }
}
    ${CostFieldsFragmentDoc}`;

/**
 * __useGetCostsQuery__
 *
 * To run a query within a React component, call `useGetCostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCostsQuery(baseOptions?: Apollo.QueryHookOptions<GetCostsQuery, GetCostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCostsQuery, GetCostsQueryVariables>(GetCostsDocument, options);
      }
export function useGetCostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCostsQuery, GetCostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCostsQuery, GetCostsQueryVariables>(GetCostsDocument, options);
        }
export type GetCostsQueryHookResult = ReturnType<typeof useGetCostsQuery>;
export type GetCostsLazyQueryHookResult = ReturnType<typeof useGetCostsLazyQuery>;
export type GetCostsQueryResult = Apollo.QueryResult<GetCostsQuery, GetCostsQueryVariables>;
export const UpsertCostsDocument = gql`
    mutation UpsertCosts($objects: [cost_insert_input!] = {}) {
  insert_cost(
    objects: $objects
    on_conflict: {constraint: cost_name_key, update_columns: [value]}
  ) {
    returning {
      ...CostFields
    }
  }
}
    ${CostFieldsFragmentDoc}`;
export type UpsertCostsMutationFn = Apollo.MutationFunction<UpsertCostsMutation, UpsertCostsMutationVariables>;

/**
 * __useUpsertCostsMutation__
 *
 * To run a mutation, you first call `useUpsertCostsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCostsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCostsMutation, { data, loading, error }] = useUpsertCostsMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpsertCostsMutation(baseOptions?: Apollo.MutationHookOptions<UpsertCostsMutation, UpsertCostsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertCostsMutation, UpsertCostsMutationVariables>(UpsertCostsDocument, options);
      }
export type UpsertCostsMutationHookResult = ReturnType<typeof useUpsertCostsMutation>;
export type UpsertCostsMutationResult = Apollo.MutationResult<UpsertCostsMutation>;
export type UpsertCostsMutationOptions = Apollo.BaseMutationOptions<UpsertCostsMutation, UpsertCostsMutationVariables>;
export const GetFanFitsDocument = gql`
    query GetFanFits($where: fan_fit_bool_exp = {}) {
  fan_fit(where: $where) {
    ...FanFitFields
  }
}
    ${FanFitFieldsFragmentDoc}`;

/**
 * __useGetFanFitsQuery__
 *
 * To run a query within a React component, call `useGetFanFitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFanFitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFanFitsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetFanFitsQuery(baseOptions?: Apollo.QueryHookOptions<GetFanFitsQuery, GetFanFitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFanFitsQuery, GetFanFitsQueryVariables>(GetFanFitsDocument, options);
      }
export function useGetFanFitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFanFitsQuery, GetFanFitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFanFitsQuery, GetFanFitsQueryVariables>(GetFanFitsDocument, options);
        }
export type GetFanFitsQueryHookResult = ReturnType<typeof useGetFanFitsQuery>;
export type GetFanFitsLazyQueryHookResult = ReturnType<typeof useGetFanFitsLazyQuery>;
export type GetFanFitsQueryResult = Apollo.QueryResult<GetFanFitsQuery, GetFanFitsQueryVariables>;
export const InsertFanFitsDocument = gql`
    mutation InsertFanFits($objects: [fan_fit_insert_input!] = {}) {
  insert_fan_fit(objects: $objects) {
    returning {
      id
      manufacturer
      fan_diameter
    }
  }
}
    `;
export type InsertFanFitsMutationFn = Apollo.MutationFunction<InsertFanFitsMutation, InsertFanFitsMutationVariables>;

/**
 * __useInsertFanFitsMutation__
 *
 * To run a mutation, you first call `useInsertFanFitsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertFanFitsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertFanFitsMutation, { data, loading, error }] = useInsertFanFitsMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertFanFitsMutation(baseOptions?: Apollo.MutationHookOptions<InsertFanFitsMutation, InsertFanFitsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertFanFitsMutation, InsertFanFitsMutationVariables>(InsertFanFitsDocument, options);
      }
export type InsertFanFitsMutationHookResult = ReturnType<typeof useInsertFanFitsMutation>;
export type InsertFanFitsMutationResult = Apollo.MutationResult<InsertFanFitsMutation>;
export type InsertFanFitsMutationOptions = Apollo.BaseMutationOptions<InsertFanFitsMutation, InsertFanFitsMutationVariables>;
export const UpdateFanFitDocument = gql`
    mutation UpdateFanFit($pk_columns: fan_fit_pk_columns_input!, $_set: fan_fit_set_input!) {
  update_fan_fit_by_pk(pk_columns: $pk_columns, _set: $_set) {
    id
    manufacturer
    fan_diameter
  }
}
    `;
export type UpdateFanFitMutationFn = Apollo.MutationFunction<UpdateFanFitMutation, UpdateFanFitMutationVariables>;

/**
 * __useUpdateFanFitMutation__
 *
 * To run a mutation, you first call `useUpdateFanFitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFanFitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFanFitMutation, { data, loading, error }] = useUpdateFanFitMutation({
 *   variables: {
 *      pk_columns: // value for 'pk_columns'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateFanFitMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFanFitMutation, UpdateFanFitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFanFitMutation, UpdateFanFitMutationVariables>(UpdateFanFitDocument, options);
      }
export type UpdateFanFitMutationHookResult = ReturnType<typeof useUpdateFanFitMutation>;
export type UpdateFanFitMutationResult = Apollo.MutationResult<UpdateFanFitMutation>;
export type UpdateFanFitMutationOptions = Apollo.BaseMutationOptions<UpdateFanFitMutation, UpdateFanFitMutationVariables>;
export const UpsertFanFitDocument = gql`
    mutation UpsertFanFit($objects: [fan_fit_insert_input!] = {}) {
  insert_fan_fit(
    objects: $objects
    on_conflict: {constraint: fan_fit_manufacturer_fan_diameter_key, update_columns: [manufacturer, fan_diameter, left_padding, right_padding, top_padding, bottom_padding, middle_column_padding, middle_row_padding]}
  ) {
    returning {
      ...FanFitFields
    }
  }
}
    ${FanFitFieldsFragmentDoc}`;
export type UpsertFanFitMutationFn = Apollo.MutationFunction<UpsertFanFitMutation, UpsertFanFitMutationVariables>;

/**
 * __useUpsertFanFitMutation__
 *
 * To run a mutation, you first call `useUpsertFanFitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertFanFitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertFanFitMutation, { data, loading, error }] = useUpsertFanFitMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpsertFanFitMutation(baseOptions?: Apollo.MutationHookOptions<UpsertFanFitMutation, UpsertFanFitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertFanFitMutation, UpsertFanFitMutationVariables>(UpsertFanFitDocument, options);
      }
export type UpsertFanFitMutationHookResult = ReturnType<typeof useUpsertFanFitMutation>;
export type UpsertFanFitMutationResult = Apollo.MutationResult<UpsertFanFitMutation>;
export type UpsertFanFitMutationOptions = Apollo.BaseMutationOptions<UpsertFanFitMutation, UpsertFanFitMutationVariables>;
export const GetFanModelFileDocument = gql`
    query GetFanModelFile($model: String!) {
  fan_model_file(where: {model: {_eq: $model}}) {
    ...FanModelFileFields
  }
}
    ${FanModelFileFieldsFragmentDoc}`;

/**
 * __useGetFanModelFileQuery__
 *
 * To run a query within a React component, call `useGetFanModelFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFanModelFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFanModelFileQuery({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useGetFanModelFileQuery(baseOptions: Apollo.QueryHookOptions<GetFanModelFileQuery, GetFanModelFileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFanModelFileQuery, GetFanModelFileQueryVariables>(GetFanModelFileDocument, options);
      }
export function useGetFanModelFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFanModelFileQuery, GetFanModelFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFanModelFileQuery, GetFanModelFileQueryVariables>(GetFanModelFileDocument, options);
        }
export type GetFanModelFileQueryHookResult = ReturnType<typeof useGetFanModelFileQuery>;
export type GetFanModelFileLazyQueryHookResult = ReturnType<typeof useGetFanModelFileLazyQuery>;
export type GetFanModelFileQueryResult = Apollo.QueryResult<GetFanModelFileQuery, GetFanModelFileQueryVariables>;
export const UpsertFanModelFileDocument = gql`
    mutation UpsertFanModelFile($object: fan_model_file_insert_input! = {}) {
  insert_fan_model_file_one(
    object: $object
    on_conflict: {constraint: fan_model_file_model_key, update_columns: [top_file_id, side_file_id, front_file_id, is_safe]}
  ) {
    ...FanModelFileFields
  }
}
    ${FanModelFileFieldsFragmentDoc}`;
export type UpsertFanModelFileMutationFn = Apollo.MutationFunction<UpsertFanModelFileMutation, UpsertFanModelFileMutationVariables>;

/**
 * __useUpsertFanModelFileMutation__
 *
 * To run a mutation, you first call `useUpsertFanModelFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertFanModelFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertFanModelFileMutation, { data, loading, error }] = useUpsertFanModelFileMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useUpsertFanModelFileMutation(baseOptions?: Apollo.MutationHookOptions<UpsertFanModelFileMutation, UpsertFanModelFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertFanModelFileMutation, UpsertFanModelFileMutationVariables>(UpsertFanModelFileDocument, options);
      }
export type UpsertFanModelFileMutationHookResult = ReturnType<typeof useUpsertFanModelFileMutation>;
export type UpsertFanModelFileMutationResult = Apollo.MutationResult<UpsertFanModelFileMutation>;
export type UpsertFanModelFileMutationOptions = Apollo.BaseMutationOptions<UpsertFanModelFileMutation, UpsertFanModelFileMutationVariables>;
export const GetJobsDocument = gql`
    query GetJobs($order_by: [job_order_by!], $where: job_bool_exp) {
  job(order_by: $order_by, where: $where) {
    id
    user_id
    name
    status
    created_on
    updated_on
    products_aggregate {
      aggregate {
        count
      }
    }
    quotes_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetJobsQuery__
 *
 * To run a query within a React component, call `useGetJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsQuery({
 *   variables: {
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetJobsQuery(baseOptions?: Apollo.QueryHookOptions<GetJobsQuery, GetJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobsQuery, GetJobsQueryVariables>(GetJobsDocument, options);
      }
export function useGetJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobsQuery, GetJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobsQuery, GetJobsQueryVariables>(GetJobsDocument, options);
        }
export type GetJobsQueryHookResult = ReturnType<typeof useGetJobsQuery>;
export type GetJobsLazyQueryHookResult = ReturnType<typeof useGetJobsLazyQuery>;
export type GetJobsQueryResult = Apollo.QueryResult<GetJobsQuery, GetJobsQueryVariables>;
export const GetJobByIdDocument = gql`
    query GetJobById($id: Int!) {
  job_by_pk(id: $id) {
    id
    user_id
    name
    status
    created_on
    updated_on
    products {
      ...ProductFields
    }
    quotes {
      ...QuoteFields
    }
  }
}
    ${ProductFieldsFragmentDoc}
${QuoteFieldsFragmentDoc}`;

/**
 * __useGetJobByIdQuery__
 *
 * To run a query within a React component, call `useGetJobByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetJobByIdQuery(baseOptions: Apollo.QueryHookOptions<GetJobByIdQuery, GetJobByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobByIdQuery, GetJobByIdQueryVariables>(GetJobByIdDocument, options);
      }
export function useGetJobByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobByIdQuery, GetJobByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobByIdQuery, GetJobByIdQueryVariables>(GetJobByIdDocument, options);
        }
export type GetJobByIdQueryHookResult = ReturnType<typeof useGetJobByIdQuery>;
export type GetJobByIdLazyQueryHookResult = ReturnType<typeof useGetJobByIdLazyQuery>;
export type GetJobByIdQueryResult = Apollo.QueryResult<GetJobByIdQuery, GetJobByIdQueryVariables>;
export const GetJobsForAdvancedSearchDocument = gql`
    query GetJobsForAdvancedSearch($order_by: [job_order_by!], $where: job_bool_exp) {
  job(order_by: $order_by, where: $where) {
    id
    user_id
    name
    status
    created_on
    updated_on
    products_aggregate {
      aggregate {
        count
      }
    }
    quotes_aggregate {
      aggregate {
        count
      }
    }
    products {
      model_number
      mas_ec_plus
      has_serial_number(order_by: {serial_number: asc}) {
        serial_number
      }
      configured_fan_count
      configuration {
        model
        manufacturer
      }
      tag
      created_at
      quote_id
      quote {
        name
      }
    }
    quotes {
      name
      id
      status
      job_number
    }
  }
}
    `;

/**
 * __useGetJobsForAdvancedSearchQuery__
 *
 * To run a query within a React component, call `useGetJobsForAdvancedSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsForAdvancedSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsForAdvancedSearchQuery({
 *   variables: {
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetJobsForAdvancedSearchQuery(baseOptions?: Apollo.QueryHookOptions<GetJobsForAdvancedSearchQuery, GetJobsForAdvancedSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobsForAdvancedSearchQuery, GetJobsForAdvancedSearchQueryVariables>(GetJobsForAdvancedSearchDocument, options);
      }
export function useGetJobsForAdvancedSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobsForAdvancedSearchQuery, GetJobsForAdvancedSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobsForAdvancedSearchQuery, GetJobsForAdvancedSearchQueryVariables>(GetJobsForAdvancedSearchDocument, options);
        }
export type GetJobsForAdvancedSearchQueryHookResult = ReturnType<typeof useGetJobsForAdvancedSearchQuery>;
export type GetJobsForAdvancedSearchLazyQueryHookResult = ReturnType<typeof useGetJobsForAdvancedSearchLazyQuery>;
export type GetJobsForAdvancedSearchQueryResult = Apollo.QueryResult<GetJobsForAdvancedSearchQuery, GetJobsForAdvancedSearchQueryVariables>;
export const InsertJobDocument = gql`
    mutation InsertJob($object: job_insert_input!) {
  insert_job_one(object: $object) {
    ...JobFields
  }
}
    ${JobFieldsFragmentDoc}`;
export type InsertJobMutationFn = Apollo.MutationFunction<InsertJobMutation, InsertJobMutationVariables>;

/**
 * __useInsertJobMutation__
 *
 * To run a mutation, you first call `useInsertJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertJobMutation, { data, loading, error }] = useInsertJobMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertJobMutation(baseOptions?: Apollo.MutationHookOptions<InsertJobMutation, InsertJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertJobMutation, InsertJobMutationVariables>(InsertJobDocument, options);
      }
export type InsertJobMutationHookResult = ReturnType<typeof useInsertJobMutation>;
export type InsertJobMutationResult = Apollo.MutationResult<InsertJobMutation>;
export type InsertJobMutationOptions = Apollo.BaseMutationOptions<InsertJobMutation, InsertJobMutationVariables>;
export const UpdateJobDocument = gql`
    mutation UpdateJob($pk_columns: job_pk_columns_input!, $_set: job_set_input!) {
  update_job_by_pk(pk_columns: $pk_columns, _set: $_set) {
    ...JobFields
  }
}
    ${JobFieldsFragmentDoc}`;
export type UpdateJobMutationFn = Apollo.MutationFunction<UpdateJobMutation, UpdateJobMutationVariables>;

/**
 * __useUpdateJobMutation__
 *
 * To run a mutation, you first call `useUpdateJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobMutation, { data, loading, error }] = useUpdateJobMutation({
 *   variables: {
 *      pk_columns: // value for 'pk_columns'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateJobMutation(baseOptions?: Apollo.MutationHookOptions<UpdateJobMutation, UpdateJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateJobMutation, UpdateJobMutationVariables>(UpdateJobDocument, options);
      }
export type UpdateJobMutationHookResult = ReturnType<typeof useUpdateJobMutation>;
export type UpdateJobMutationResult = Apollo.MutationResult<UpdateJobMutation>;
export type UpdateJobMutationOptions = Apollo.BaseMutationOptions<UpdateJobMutation, UpdateJobMutationVariables>;
export const DeleteJobByIdDocument = gql`
    mutation DeleteJobById($id: Int!) {
  delete_job_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteJobByIdMutationFn = Apollo.MutationFunction<DeleteJobByIdMutation, DeleteJobByIdMutationVariables>;

/**
 * __useDeleteJobByIdMutation__
 *
 * To run a mutation, you first call `useDeleteJobByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJobByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJobByIdMutation, { data, loading, error }] = useDeleteJobByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteJobByIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteJobByIdMutation, DeleteJobByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteJobByIdMutation, DeleteJobByIdMutationVariables>(DeleteJobByIdDocument, options);
      }
export type DeleteJobByIdMutationHookResult = ReturnType<typeof useDeleteJobByIdMutation>;
export type DeleteJobByIdMutationResult = Apollo.MutationResult<DeleteJobByIdMutation>;
export type DeleteJobByIdMutationOptions = Apollo.BaseMutationOptions<DeleteJobByIdMutation, DeleteJobByIdMutationVariables>;
export const GetProductDocument = gql`
    query GetProduct($product_id: Int!) {
  product_by_pk(id: $product_id) {
    ...ProductFields
  }
}
    ${ProductFieldsFragmentDoc}`;

/**
 * __useGetProductQuery__
 *
 * To run a query within a React component, call `useGetProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductQuery({
 *   variables: {
 *      product_id: // value for 'product_id'
 *   },
 * });
 */
export function useGetProductQuery(baseOptions: Apollo.QueryHookOptions<GetProductQuery, GetProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductQuery, GetProductQueryVariables>(GetProductDocument, options);
      }
export function useGetProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductQuery, GetProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductQuery, GetProductQueryVariables>(GetProductDocument, options);
        }
export type GetProductQueryHookResult = ReturnType<typeof useGetProductQuery>;
export type GetProductLazyQueryHookResult = ReturnType<typeof useGetProductLazyQuery>;
export type GetProductQueryResult = Apollo.QueryResult<GetProductQuery, GetProductQueryVariables>;
export const GetProductsDocument = gql`
    query GetProducts($where: product_bool_exp = {}) {
  product(where: $where) {
    ...ProductFields
    configuration {
      ...ConfigurationFields
    }
    has_serial_number {
      ...SerialNumberFields
    }
  }
}
    ${ProductFieldsFragmentDoc}
${ConfigurationFieldsFragmentDoc}
${SerialNumberFieldsFragmentDoc}`;

/**
 * __useGetProductsQuery__
 *
 * To run a query within a React component, call `useGetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetProductsQuery(baseOptions?: Apollo.QueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, options);
      }
export function useGetProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, options);
        }
export type GetProductsQueryHookResult = ReturnType<typeof useGetProductsQuery>;
export type GetProductsLazyQueryHookResult = ReturnType<typeof useGetProductsLazyQuery>;
export type GetProductsQueryResult = Apollo.QueryResult<GetProductsQuery, GetProductsQueryVariables>;
export const InsertProductDocument = gql`
    mutation InsertProduct($object: product_insert_input!) {
  insert_product_one(object: $object) {
    ...ProductFields
    configuration {
      ...ConfigurationFields
    }
    has_serial_number {
      ...SerialNumberFields
    }
  }
}
    ${ProductFieldsFragmentDoc}
${ConfigurationFieldsFragmentDoc}
${SerialNumberFieldsFragmentDoc}`;
export type InsertProductMutationFn = Apollo.MutationFunction<InsertProductMutation, InsertProductMutationVariables>;

/**
 * __useInsertProductMutation__
 *
 * To run a mutation, you first call `useInsertProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertProductMutation, { data, loading, error }] = useInsertProductMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertProductMutation(baseOptions?: Apollo.MutationHookOptions<InsertProductMutation, InsertProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertProductMutation, InsertProductMutationVariables>(InsertProductDocument, options);
      }
export type InsertProductMutationHookResult = ReturnType<typeof useInsertProductMutation>;
export type InsertProductMutationResult = Apollo.MutationResult<InsertProductMutation>;
export type InsertProductMutationOptions = Apollo.BaseMutationOptions<InsertProductMutation, InsertProductMutationVariables>;
export const InsertProductsDocument = gql`
    mutation InsertProducts($objects: [product_insert_input!] = {}) {
  insert_product(objects: $objects) {
    returning {
      id
    }
  }
}
    `;
export type InsertProductsMutationFn = Apollo.MutationFunction<InsertProductsMutation, InsertProductsMutationVariables>;

/**
 * __useInsertProductsMutation__
 *
 * To run a mutation, you first call `useInsertProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertProductsMutation, { data, loading, error }] = useInsertProductsMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertProductsMutation(baseOptions?: Apollo.MutationHookOptions<InsertProductsMutation, InsertProductsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertProductsMutation, InsertProductsMutationVariables>(InsertProductsDocument, options);
      }
export type InsertProductsMutationHookResult = ReturnType<typeof useInsertProductsMutation>;
export type InsertProductsMutationResult = Apollo.MutationResult<InsertProductsMutation>;
export type InsertProductsMutationOptions = Apollo.BaseMutationOptions<InsertProductsMutation, InsertProductsMutationVariables>;
export const UpdateProductDocument = gql`
    mutation UpdateProduct($pk_columns: product_pk_columns_input!, $_set: product_set_input!) {
  update_product_by_pk(pk_columns: $pk_columns, _set: $_set) {
    ...ProductFields
  }
}
    ${ProductFieldsFragmentDoc}`;
export type UpdateProductMutationFn = Apollo.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      pk_columns: // value for 'pk_columns'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, options);
      }
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = Apollo.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = Apollo.BaseMutationOptions<UpdateProductMutation, UpdateProductMutationVariables>;
export const DeleteProductDocument = gql`
    mutation DeleteProduct($id: Int!) {
  delete_product_by_pk(id: $id) {
    ...ProductFields
  }
}
    ${ProductFieldsFragmentDoc}`;
export type DeleteProductMutationFn = Apollo.MutationFunction<DeleteProductMutation, DeleteProductMutationVariables>;

/**
 * __useDeleteProductMutation__
 *
 * To run a mutation, you first call `useDeleteProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductMutation, { data, loading, error }] = useDeleteProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProductMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductMutation, DeleteProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductMutation, DeleteProductMutationVariables>(DeleteProductDocument, options);
      }
export type DeleteProductMutationHookResult = ReturnType<typeof useDeleteProductMutation>;
export type DeleteProductMutationResult = Apollo.MutationResult<DeleteProductMutation>;
export type DeleteProductMutationOptions = Apollo.BaseMutationOptions<DeleteProductMutation, DeleteProductMutationVariables>;
export const GetQuotesByJobIdDocument = gql`
    query GetQuotesByJobId($jobId: Int!) {
  quote(where: {job_id: {_eq: $jobId}}) {
    ...QuoteFields
    products(order_by: {id: asc}) {
      ...ProductFields
      configuration {
        ...ConfigurationFields
      }
      has_serial_number(order_by: {serial_number: asc}) {
        ...SerialNumberFields
      }
    }
  }
}
    ${QuoteFieldsFragmentDoc}
${ProductFieldsFragmentDoc}
${ConfigurationFieldsFragmentDoc}
${SerialNumberFieldsFragmentDoc}`;

/**
 * __useGetQuotesByJobIdQuery__
 *
 * To run a query within a React component, call `useGetQuotesByJobIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuotesByJobIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuotesByJobIdQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useGetQuotesByJobIdQuery(baseOptions: Apollo.QueryHookOptions<GetQuotesByJobIdQuery, GetQuotesByJobIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuotesByJobIdQuery, GetQuotesByJobIdQueryVariables>(GetQuotesByJobIdDocument, options);
      }
export function useGetQuotesByJobIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuotesByJobIdQuery, GetQuotesByJobIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuotesByJobIdQuery, GetQuotesByJobIdQueryVariables>(GetQuotesByJobIdDocument, options);
        }
export type GetQuotesByJobIdQueryHookResult = ReturnType<typeof useGetQuotesByJobIdQuery>;
export type GetQuotesByJobIdLazyQueryHookResult = ReturnType<typeof useGetQuotesByJobIdLazyQuery>;
export type GetQuotesByJobIdQueryResult = Apollo.QueryResult<GetQuotesByJobIdQuery, GetQuotesByJobIdQueryVariables>;
export const InsertQuoteDocument = gql`
    mutation InsertQuote($object: quote_insert_input!) {
  insert_quote_one(object: $object) {
    ...QuoteFields
  }
}
    ${QuoteFieldsFragmentDoc}`;
export type InsertQuoteMutationFn = Apollo.MutationFunction<InsertQuoteMutation, InsertQuoteMutationVariables>;

/**
 * __useInsertQuoteMutation__
 *
 * To run a mutation, you first call `useInsertQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertQuoteMutation, { data, loading, error }] = useInsertQuoteMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertQuoteMutation(baseOptions?: Apollo.MutationHookOptions<InsertQuoteMutation, InsertQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertQuoteMutation, InsertQuoteMutationVariables>(InsertQuoteDocument, options);
      }
export type InsertQuoteMutationHookResult = ReturnType<typeof useInsertQuoteMutation>;
export type InsertQuoteMutationResult = Apollo.MutationResult<InsertQuoteMutation>;
export type InsertQuoteMutationOptions = Apollo.BaseMutationOptions<InsertQuoteMutation, InsertQuoteMutationVariables>;
export const InsertQuotesDocument = gql`
    mutation InsertQuotes($objects: [quote_insert_input!] = {}) {
  insert_quote(objects: $objects) {
    returning {
      id
    }
  }
}
    `;
export type InsertQuotesMutationFn = Apollo.MutationFunction<InsertQuotesMutation, InsertQuotesMutationVariables>;

/**
 * __useInsertQuotesMutation__
 *
 * To run a mutation, you first call `useInsertQuotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertQuotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertQuotesMutation, { data, loading, error }] = useInsertQuotesMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertQuotesMutation(baseOptions?: Apollo.MutationHookOptions<InsertQuotesMutation, InsertQuotesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertQuotesMutation, InsertQuotesMutationVariables>(InsertQuotesDocument, options);
      }
export type InsertQuotesMutationHookResult = ReturnType<typeof useInsertQuotesMutation>;
export type InsertQuotesMutationResult = Apollo.MutationResult<InsertQuotesMutation>;
export type InsertQuotesMutationOptions = Apollo.BaseMutationOptions<InsertQuotesMutation, InsertQuotesMutationVariables>;
export const UpdateQuoteDocument = gql`
    mutation UpdateQuote($pk_columns: quote_pk_columns_input!, $_set: quote_set_input!) {
  update_quote_by_pk(pk_columns: $pk_columns, _set: $_set) {
    ...QuoteFields
  }
}
    ${QuoteFieldsFragmentDoc}`;
export type UpdateQuoteMutationFn = Apollo.MutationFunction<UpdateQuoteMutation, UpdateQuoteMutationVariables>;

/**
 * __useUpdateQuoteMutation__
 *
 * To run a mutation, you first call `useUpdateQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuoteMutation, { data, loading, error }] = useUpdateQuoteMutation({
 *   variables: {
 *      pk_columns: // value for 'pk_columns'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateQuoteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuoteMutation, UpdateQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQuoteMutation, UpdateQuoteMutationVariables>(UpdateQuoteDocument, options);
      }
export type UpdateQuoteMutationHookResult = ReturnType<typeof useUpdateQuoteMutation>;
export type UpdateQuoteMutationResult = Apollo.MutationResult<UpdateQuoteMutation>;
export type UpdateQuoteMutationOptions = Apollo.BaseMutationOptions<UpdateQuoteMutation, UpdateQuoteMutationVariables>;
export const DeleteQuoteDocument = gql`
    mutation DeleteQuote($id: Int!) {
  delete_quote_by_pk(id: $id) {
    ...QuoteFields
  }
}
    ${QuoteFieldsFragmentDoc}`;
export type DeleteQuoteMutationFn = Apollo.MutationFunction<DeleteQuoteMutation, DeleteQuoteMutationVariables>;

/**
 * __useDeleteQuoteMutation__
 *
 * To run a mutation, you first call `useDeleteQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuoteMutation, { data, loading, error }] = useDeleteQuoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteQuoteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteQuoteMutation, DeleteQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteQuoteMutation, DeleteQuoteMutationVariables>(DeleteQuoteDocument, options);
      }
export type DeleteQuoteMutationHookResult = ReturnType<typeof useDeleteQuoteMutation>;
export type DeleteQuoteMutationResult = Apollo.MutationResult<DeleteQuoteMutation>;
export type DeleteQuoteMutationOptions = Apollo.BaseMutationOptions<DeleteQuoteMutation, DeleteQuoteMutationVariables>;
export const GetSerialNumberByIdDocument = gql`
    query GetSerialNumberById($id: Int!) {
  serial_number_by_pk(id: $id) {
    id
    serial_number
    product_id
    job_id
    drc_job_number
    pricing
    unit_tag
    serial_for_product {
      ...ProductFields
    }
    serial_in_job {
      ...JobFields
    }
  }
}
    ${ProductFieldsFragmentDoc}
${JobFieldsFragmentDoc}`;

/**
 * __useGetSerialNumberByIdQuery__
 *
 * To run a query within a React component, call `useGetSerialNumberByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSerialNumberByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSerialNumberByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSerialNumberByIdQuery(baseOptions: Apollo.QueryHookOptions<GetSerialNumberByIdQuery, GetSerialNumberByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSerialNumberByIdQuery, GetSerialNumberByIdQueryVariables>(GetSerialNumberByIdDocument, options);
      }
export function useGetSerialNumberByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSerialNumberByIdQuery, GetSerialNumberByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSerialNumberByIdQuery, GetSerialNumberByIdQueryVariables>(GetSerialNumberByIdDocument, options);
        }
export type GetSerialNumberByIdQueryHookResult = ReturnType<typeof useGetSerialNumberByIdQuery>;
export type GetSerialNumberByIdLazyQueryHookResult = ReturnType<typeof useGetSerialNumberByIdLazyQuery>;
export type GetSerialNumberByIdQueryResult = Apollo.QueryResult<GetSerialNumberByIdQuery, GetSerialNumberByIdQueryVariables>;
export const GetProductBySerialNumberDocument = gql`
    query GetProductBySerialNumber($_eq: Int!) {
  serial_number(where: {serial_number: {_eq: $_eq}}) {
    serial_for_product {
      ...ProductFields
    }
    serial_in_job {
      ...JobFields
    }
  }
}
    ${ProductFieldsFragmentDoc}
${JobFieldsFragmentDoc}`;

/**
 * __useGetProductBySerialNumberQuery__
 *
 * To run a query within a React component, call `useGetProductBySerialNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductBySerialNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductBySerialNumberQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGetProductBySerialNumberQuery(baseOptions: Apollo.QueryHookOptions<GetProductBySerialNumberQuery, GetProductBySerialNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductBySerialNumberQuery, GetProductBySerialNumberQueryVariables>(GetProductBySerialNumberDocument, options);
      }
export function useGetProductBySerialNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductBySerialNumberQuery, GetProductBySerialNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductBySerialNumberQuery, GetProductBySerialNumberQueryVariables>(GetProductBySerialNumberDocument, options);
        }
export type GetProductBySerialNumberQueryHookResult = ReturnType<typeof useGetProductBySerialNumberQuery>;
export type GetProductBySerialNumberLazyQueryHookResult = ReturnType<typeof useGetProductBySerialNumberLazyQuery>;
export type GetProductBySerialNumberQueryResult = Apollo.QueryResult<GetProductBySerialNumberQuery, GetProductBySerialNumberQueryVariables>;
export const GetSerialNumbersForJobDocument = gql`
    query GetSerialNumbersForJob($job_id: Int!) {
  serial_number(where: {job_id: {_eq: $job_id}}) {
    serial_number
    product_id
  }
}
    `;

/**
 * __useGetSerialNumbersForJobQuery__
 *
 * To run a query within a React component, call `useGetSerialNumbersForJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSerialNumbersForJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSerialNumbersForJobQuery({
 *   variables: {
 *      job_id: // value for 'job_id'
 *   },
 * });
 */
export function useGetSerialNumbersForJobQuery(baseOptions: Apollo.QueryHookOptions<GetSerialNumbersForJobQuery, GetSerialNumbersForJobQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSerialNumbersForJobQuery, GetSerialNumbersForJobQueryVariables>(GetSerialNumbersForJobDocument, options);
      }
export function useGetSerialNumbersForJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSerialNumbersForJobQuery, GetSerialNumbersForJobQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSerialNumbersForJobQuery, GetSerialNumbersForJobQueryVariables>(GetSerialNumbersForJobDocument, options);
        }
export type GetSerialNumbersForJobQueryHookResult = ReturnType<typeof useGetSerialNumbersForJobQuery>;
export type GetSerialNumbersForJobLazyQueryHookResult = ReturnType<typeof useGetSerialNumbersForJobLazyQuery>;
export type GetSerialNumbersForJobQueryResult = Apollo.QueryResult<GetSerialNumbersForJobQuery, GetSerialNumbersForJobQueryVariables>;
export const InsertSerialNumberDocument = gql`
    mutation InsertSerialNumber($object: serial_number_insert_input!) {
  insert_serial_number_one(object: $object) {
    ...SerialNumberFields
  }
}
    ${SerialNumberFieldsFragmentDoc}`;
export type InsertSerialNumberMutationFn = Apollo.MutationFunction<InsertSerialNumberMutation, InsertSerialNumberMutationVariables>;

/**
 * __useInsertSerialNumberMutation__
 *
 * To run a mutation, you first call `useInsertSerialNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSerialNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSerialNumberMutation, { data, loading, error }] = useInsertSerialNumberMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertSerialNumberMutation(baseOptions?: Apollo.MutationHookOptions<InsertSerialNumberMutation, InsertSerialNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertSerialNumberMutation, InsertSerialNumberMutationVariables>(InsertSerialNumberDocument, options);
      }
export type InsertSerialNumberMutationHookResult = ReturnType<typeof useInsertSerialNumberMutation>;
export type InsertSerialNumberMutationResult = Apollo.MutationResult<InsertSerialNumberMutation>;
export type InsertSerialNumberMutationOptions = Apollo.BaseMutationOptions<InsertSerialNumberMutation, InsertSerialNumberMutationVariables>;
export const UpdateSerialNumberDocument = gql`
    mutation UpdateSerialNumber($pk_columns: serial_number_pk_columns_input!, $_set: serial_number_set_input!) {
  update_serial_number_by_pk(pk_columns: $pk_columns, _set: $_set) {
    ...SerialNumberFields
  }
}
    ${SerialNumberFieldsFragmentDoc}`;
export type UpdateSerialNumberMutationFn = Apollo.MutationFunction<UpdateSerialNumberMutation, UpdateSerialNumberMutationVariables>;

/**
 * __useUpdateSerialNumberMutation__
 *
 * To run a mutation, you first call `useUpdateSerialNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSerialNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSerialNumberMutation, { data, loading, error }] = useUpdateSerialNumberMutation({
 *   variables: {
 *      pk_columns: // value for 'pk_columns'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateSerialNumberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSerialNumberMutation, UpdateSerialNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSerialNumberMutation, UpdateSerialNumberMutationVariables>(UpdateSerialNumberDocument, options);
      }
export type UpdateSerialNumberMutationHookResult = ReturnType<typeof useUpdateSerialNumberMutation>;
export type UpdateSerialNumberMutationResult = Apollo.MutationResult<UpdateSerialNumberMutation>;
export type UpdateSerialNumberMutationOptions = Apollo.BaseMutationOptions<UpdateSerialNumberMutation, UpdateSerialNumberMutationVariables>;
export const ClearSerialNumberDocument = gql`
    mutation ClearSerialNumber($_eq: Int!) {
  update_serial_number_many(
    updates: {where: {product_id: {_eq: $_eq}}, _set: {job_id: null, product_id: null}}
  ) {
    returning {
      id
      serial_number
    }
  }
}
    `;
export type ClearSerialNumberMutationFn = Apollo.MutationFunction<ClearSerialNumberMutation, ClearSerialNumberMutationVariables>;

/**
 * __useClearSerialNumberMutation__
 *
 * To run a mutation, you first call `useClearSerialNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearSerialNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearSerialNumberMutation, { data, loading, error }] = useClearSerialNumberMutation({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useClearSerialNumberMutation(baseOptions?: Apollo.MutationHookOptions<ClearSerialNumberMutation, ClearSerialNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearSerialNumberMutation, ClearSerialNumberMutationVariables>(ClearSerialNumberDocument, options);
      }
export type ClearSerialNumberMutationHookResult = ReturnType<typeof useClearSerialNumberMutation>;
export type ClearSerialNumberMutationResult = Apollo.MutationResult<ClearSerialNumberMutation>;
export type ClearSerialNumberMutationOptions = Apollo.BaseMutationOptions<ClearSerialNumberMutation, ClearSerialNumberMutationVariables>;
export const GetMaxSerialDocument = gql`
    query GetMaxSerial {
  serial_number(order_by: {serial_number: desc_nulls_last}, limit: 1) {
    serial_number
  }
}
    `;

/**
 * __useGetMaxSerialQuery__
 *
 * To run a query within a React component, call `useGetMaxSerialQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaxSerialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaxSerialQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMaxSerialQuery(baseOptions?: Apollo.QueryHookOptions<GetMaxSerialQuery, GetMaxSerialQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMaxSerialQuery, GetMaxSerialQueryVariables>(GetMaxSerialDocument, options);
      }
export function useGetMaxSerialLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMaxSerialQuery, GetMaxSerialQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMaxSerialQuery, GetMaxSerialQueryVariables>(GetMaxSerialDocument, options);
        }
export type GetMaxSerialQueryHookResult = ReturnType<typeof useGetMaxSerialQuery>;
export type GetMaxSerialLazyQueryHookResult = ReturnType<typeof useGetMaxSerialLazyQuery>;
export type GetMaxSerialQueryResult = Apollo.QueryResult<GetMaxSerialQuery, GetMaxSerialQueryVariables>;