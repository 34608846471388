import { parseInt, debounce, isEqual } from "lodash";
import { ChangeEvent, useCallback, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  FormFeedback,
  FormGroup,
  Input,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap";
import ConfigurationResults from "../components/ConfigurationResults";
import {
  Configuration,
  Control_Panel_Sizing,
  Product_Set_Input,
  useGetJobByIdQuery,
  useGetProductQuery,
  useUpdateConfigurationMutation,
  useUpdateProductMutation,
} from "../generated/graphql";
import featuredFans from "../config/featuredFans.json"

import {
  validatePressure,
  validateHeight,
  validateWidth,
  validateAirflow,
} from "../helpers/product";
import { ConfigFilter, shownFilters } from "../helpers/config-filters";

import "./PossibleConfigurations.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faCircleQuestion, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

// TODO: Can save if these are valid
// - Selected a configuration
// - Selected configuration is in the list of possible configurations based on the product details
// - Product details are still valid
const PossibleConfigurations = () => {
  const { product_id, job_id } = useParams();

  const [sort, setSort] = useState("count");
  const [order, setOrder] = useState("asc");
  const [expandedDetails, setExpandedDetails] = useState<{
    configuration: Configuration;
    fanCount: number;
    sizing?: Control_Panel_Sizing
  } | null>(null);

  const [selectedConfigurationId, setConfigurationId] = useState<number | null>(
    null
  );
  const [selectedFanCount, setSelectedFanCount] = useState<number | null>(null);

  const [productDetails, setProductDetails] = useState<Product_Set_Input>({
    tag: "",
    air_flow: 0,
    total_static_pressure: 0,
    width: 0,
    height: 0,
    power: "",
    backdraft_dampers: false,
    over_one_hundred_percent_redundancy: false,
    pressure_transducers: false,
    mas_ec_plus: false,
    outdoor_rating: null,
  });
  const [savedProductDetails, setSavedProductDetails] = useState<Product_Set_Input>({
    tag: "",
    air_flow: 0,
    total_static_pressure: 0,
    width: 0,
    height: 0,
    power: "",
    backdraft_dampers: false,
    over_one_hundred_percent_redundancy: false,
    pressure_transducers: false,
    mas_ec_plus: false,
    outdoor_rating: null,
  });
  const [touchedTag, setTouchedTag] = useState(false);
  const [touchedAirflow, setTouchedAirflow] = useState(false);
  const [touchedPressure, setTouchedPressure] = useState(false);
  const [touchedWidth, setTouchedWidth] = useState(false);
  const [touchedHeight, setTouchedHeight] = useState(false);

  const { data: job } = useGetJobByIdQuery({
    variables: { id: Number(job_id) },
  });

  const [productDetailsInputs, setproductDetailsInputs] = useState<Product_Set_Input>({
    tag: productDetails.tag ?? "",
    air_flow: productDetails.air_flow ?? 0,
    total_static_pressure: productDetails.total_static_pressure ?? 0,
    width: productDetails.width ?? 0,
    height: productDetails.height ?? 0,
    power: productDetails.power ?? "",
    backdraft_dampers: productDetails.backdraft_dampers ?? false,
    over_one_hundred_percent_redundancy: productDetails.over_one_hundred_percent_redundancy ?? false,
    pressure_transducers: productDetails.pressure_transducers ?? false,
    mas_ec_plus: productDetails.mas_ec_plus ?? false,
    outdoor_rating: productDetails.outdoor_rating ?? null,
  });

  const [configCardLimit, setConfigCardLimit] = useState<{active:boolean;amount:number}>({"active":true,"amount":10});

  const [viewExpanded, setViewExpanded] = useState<boolean>(false);

  // filters display/input state variabls
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false); // filter sidebar open
  const [visibleFilters, setVisibleFilters] = useState(shownFilters); // visible or not per filter type
  const [filtersInputs, setFiltersInputs] = useState<ConfigFilter>({
    fanManufacturer: null,
    efficiencyMax: null,
    redundancyMax: null,
    systemWeightMax: null,
    efficiencyMin: null,
    redundancyMin: null,
    systemWeightMin: null
  }); // state for actual filter inputs, seperate to allow debounce
  const [filters, setFilters] = useState<ConfigFilter>({}); // state for actual filters applied to configs

  // if the config in the expanded view is valid
  const [expandedValid, setExpandedValid] = useState<boolean>(null);

  const updateVisibleFilters = (index: number) => {
    setVisibleFilters(
      visibleFilters.map((filter, currentIndex) =>
        currentIndex === index
          ? { ...filter, checked: !filter.checked }
          : filter
      )
    );
  }

  const debounceFilterUpdate = (e: ChangeEvent<HTMLInputElement>) => {
    let value: any = "";
    if (e.target.type === "checkbox") {
      value = e.target.checked;
    } else if (e.target.type === "number") {
      try {
        value = parseFloat(e.target.value);
      } catch (error) {
        console.error("Parse error: ", error);
        return;
      }
    } else {
      if (e.target.name === "fanManufacturer" && e.target.value === "Any") value = null;
      else value = e.target.value;
    }

    setFiltersInputs({ ...filtersInputs, [e.target.name]: value });
    debouncedFilterUpdate({ ...filtersInputs, [e.target.name]: value });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFilterUpdate = useCallback(debounce((input) => setFilters(input), 700), []);

  const debounceDetailsUpdate = (e: ChangeEvent<HTMLInputElement>) => {
    let value: any = "";
    if (e.target.name === "outdoor_rating"){
      if (e.target.value === "indoor") value = false;
      if (e.target.value === "outdoor") value = true;
    } else if (e.target.type === "checkbox") {
      value = e.target.checked;
    } else if (e.target.type === "number") {
      try {
        value = parseFloat(e.target.value);
      } catch (error) {
        console.error("Parse error: ", error);
        return;
      }
    } else {
      value = e.target.value;
    }
    
    if (e.target.name === "mas_ec_plus" && !e.target.checked){
      setproductDetailsInputs({ ...productDetailsInputs, [e.target.name]: value, "outdoor_rating": null});
      debouncedDetailsUpdate({ ...productDetailsInputs, [e.target.name]: value, "outdoor_rating": null}, e.target.name);
    } else if (e.target.name === "mas_ec_plus" && e.target.checked) {
      setproductDetailsInputs({ ...productDetailsInputs, [e.target.name]: value, "outdoor_rating": savedProductDetails.outdoor_rating});
      debouncedDetailsUpdate({ ...productDetailsInputs, [e.target.name]: value, "outdoor_rating": savedProductDetails.outdoor_rating}, e.target.name);
    } else {
      setproductDetailsInputs({ ...productDetailsInputs, [e.target.name]: value });
      debouncedDetailsUpdate({ ...productDetailsInputs, [e.target.name]: value }, e.target.name);
    }
  };
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedDetailsUpdate = useCallback(debounce((input, name) => {setProductDetails(input); updatedConfigField(name);}, 700),[]);

  const updatedConfigField = (field: string = null) => {
    // When any config field is changed, need to revalidate
    switch(field){
      case 'tag':
        setTouchedTag(true);
        break;
      case 'air_flow':
        setTouchedAirflow(true);
        break;
      case 'total_static_pressure':
        setTouchedPressure(true);
        break;
      case 'width':
        setTouchedWidth(true);
        break;
      case 'height':
        setTouchedHeight(true);
        break;
    }
  }

  const { loading: productLoading } = useGetProductQuery({
    variables: { product_id: parseInt(product_id || "0") },
    onCompleted: (data) => {
      setProductDetails({
        ...data.product_by_pk,
        __typename: undefined,
        updated_at: undefined,
        created_at: undefined,
      } as Product_Set_Input);
      setproductDetailsInputs({
        ...data.product_by_pk,
        __typename: undefined,
        updated_at: undefined,
        created_at: undefined,
      } as Product_Set_Input);
      setSavedProductDetails({
        ...data.product_by_pk,
        __typename: undefined,
        updated_at: undefined,
        created_at: undefined,
      } as Product_Set_Input);

      if (!!data.product_by_pk?.configuration_id) {
        setConfigurationId(data.product_by_pk.configuration_id);
        setSelectedFanCount(!!data.product_by_pk.configured_fan_count ? data.product_by_pk.configured_fan_count : null);
        setViewExpanded(true);
      }
    },
  });
  const [updateProduct] = useUpdateProductMutation();
  const [updateConfiguration] = useUpdateConfigurationMutation();

  const handleSort = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setSort(e.target.value),
    []
  );
  const handleOrder = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setOrder(e.target.value),
    []
  );

  const selectConfig = useCallback(
    async (cId: number | null, fanCount: number | null) => {
      setConfigurationId(cId);
      setSelectedFanCount(fanCount);
    },
    []
  );
  
  const validTag = !touchedTag || !!productDetails.tag;
  const validAirflow =
    !touchedAirflow || validateAirflow(productDetails.air_flow);
  const validPressure =
    !touchedPressure || validatePressure(productDetails.total_static_pressure);
  const validWidth = !touchedWidth || validateWidth(productDetails.width);
  const validHeight = !touchedHeight || validateHeight(productDetails.height);
  const isValid =
    validTag && validAirflow && validPressure && validWidth && validHeight;

  const onSave = useCallback((configuration_id = selectedConfigurationId, configured_fan_count = selectedFanCount) => {
    if (!isValid) return;
    updateProduct({
      variables: {
        pk_columns: { id: parseInt(product_id!) },
        _set: {
          ...productDetails,
          configuration_id: configuration_id!,
          configured_fan_count,
        },
      },
      update: (cache, { data }) => {
        const updated = data?.update_product_by_pk;
        if (!updated) return;
        cache.evict({
          fieldName: "product",
          broadcast: false,
        });

        if (!!updated.quote_id) {
          cache.evict({
            fieldName: "quote",
            broadcast: false,
          });
        }
      },
    });

    updateConfiguration({
      variables: {
        pk_columns: { id: configuration_id! },
        _set: { has_changed: false },
      },
    });
  },[isValid, productDetails, product_id, selectedConfigurationId, selectedFanCount, updateConfiguration, updateProduct]);

  const updateProductConfig = useCallback(() => {
    if (!isValid) return;
    updateProduct({
      variables: {
        pk_columns: { id: parseInt(product_id!) },
        _set: {
          ...productDetails,
        },
      },
      update: (cache, { data }) => {
        const updated = data?.update_product_by_pk;
        if (!updated) return;
        cache.evict({
          fieldName: "product",
          broadcast: false,
        });

        if (!!updated.quote_id) {
          cache.evict({
            fieldName: "quote",
            broadcast: false,
          });
        }
      },
    });
  }, [isValid, productDetails, product_id, updateProduct]);

  const onSelect = async (configuration: Configuration | null, fanCount: number | null) => {
    if (configuration?.id) {
      setViewExpanded(true);
      if (!featuredFans.includes(configuration.model)) {
        toast.warn(`Fan ${configuration.model} isn't stocked. Please check availability.`)
      }
      await selectConfig(configuration?.id, fanCount);
      onSave(configuration?.id, fanCount);
    }
  }

  // reset touched variables when parameters are valid, otherwise it won't keep checking them
  if(isValid && (touchedTag || touchedAirflow || touchedHeight || touchedWidth || touchedPressure)){
    setTouchedTag(false);
    setTouchedAirflow(false);
    setTouchedHeight(false);
    setTouchedWidth(false);
    setTouchedPressure(false);
  }

  const updateConfigPossible = !isEqual(productDetails,savedProductDetails);

  const ConfigHeader = () => {
    if (expandedDetails && viewExpanded) {
      const { fanCount, configuration } = expandedDetails;

      const selected =
        selectedConfigurationId === configuration.id &&
        selectedFanCount === fanCount;

      return (<div className="details-info-content">
        {/* <span><b>{fanCount} - ({configuration?.model})</b></span> */}
        <Button
          className={selected ? 'selected' : 'not-selected'}
          onClick={() =>
            selected
              ? onSelect!(null, null)
              : onSelect!(configuration, fanCount)
          }>
          {selected && <FontAwesomeIcon icon="check" className="ps-1" />}
          {selected ? "Configuration selected" : "Select configuration"}
        </Button>
        {/* <button onClick={() => setExpandedDetails(null)}>View configurations</button> */}
      </div>)
    } else {
      return (
        <div className="details-info-content">
          <div>
            <label>Order</label>
            <Input
              type="select"
              className="mx-2"
              name="order"
              style={{ width: "125px" }}
              onChange={handleOrder}
              value={order}
            >
              <option value="asc">Asc</option>
              <option value="desc">Desc</option>
            </Input>
          </div>
          <div>
            <label>Sort By</label>
            <Input
              type="select"
              className="mx-2"
              name="filter"
              style={{ width: "125px" }}
              onChange={handleSort}
              value={sort}
            >
              <option value="count">Count</option>
              <option value="price">Price</option>
              <option value="efficiency">Efficiency</option>
              <option value="redundancy">Redundancy</option>
              <option value="weight">Weight</option>
              <option value="fla">FLA</option>
              <option value="mca">MCA</option>
              <option value="mocp">MOCP</option>
              <option value="motor_power">Motor Power</option>
              <option value="input_power">Input Power</option>
              <option value="inlet_sound">Inlet Sound</option>
              <option value="outlet_sound">Outlet Sound</option>
            </Input>
          </div>
        </div>
      )
    }
  }

  return (
    <div className="configurations page">
      <div className="actions">
        {/* <BackArrow redirectUrl={`/jobs/${job_id}`} /> */}
        <Link to={`/jobs/${job_id}`} className="job-link">{job?.job_by_pk?.name}</Link>
        <div>
          <FontAwesomeIcon className="separator" icon={faAngleRight} />
          <span onClick={() => { if (expandedDetails) setExpandedDetails(null); setViewExpanded(false); }} className={`job-link${!expandedDetails ? ".active" : ""}`}>{productDetails.tag} configurations</span>
        </div>
        {expandedDetails && viewExpanded && (<div>
          <FontAwesomeIcon className="separator" icon={faAngleRight} />
          {expandedDetails.fanCount}x {expandedDetails.configuration.model}
        </div>)}
        <div className="grow" />
        <ConfigHeader />
      </div>
      <div className="configurations-manager">
        <div className="inputs-container">
          <div>
            <div className="box-label">Tag</div>
            <FormGroup>
              <Input
                name="tag"
                value={productDetailsInputs.tag || ""}
                onChange={debounceDetailsUpdate}
                invalid={!validTag}
                placeholder="enter name"
              />
              <FormFeedback>Tag is required</FormFeedback>
            </FormGroup>
          </div>
          <div>
            <div className="box-label">Air Flow (CFM)</div>
            <FormGroup>
              <Input
                name="air_flow"
                type="number"
                placeholder="air flow"
                value={productDetailsInputs.air_flow}
                onChange={debounceDetailsUpdate}
                invalid={!validAirflow}
              />
              <FormFeedback>Air Flow is required</FormFeedback>
            </FormGroup>
          </div>
          <div>
            <div className="box-label">Total SP</div>
            <FormGroup>
              <Input
                name="total_static_pressure"
                type="number"
                value={productDetailsInputs.total_static_pressure}
                onChange={debounceDetailsUpdate}
                invalid={!validPressure}
                step="0.01"
              />
              <FormFeedback>2 decimal place only</FormFeedback>
            </FormGroup>
          </div>
          <div>
            <div className="box-label">Width</div>
            <FormGroup>
              <Input
                name="width"
                type="number"
                value={productDetailsInputs.width}
                onChange={debounceDetailsUpdate}
                invalid={!validWidth}
              />
              <FormFeedback>1 decimal place only</FormFeedback>
            </FormGroup>
          </div>
          <div>
            <div className="box-label">Height</div>
            <FormGroup>
              <Input
                name="height"
                type="number"
                value={productDetailsInputs.height}
                onChange={debounceDetailsUpdate}
                invalid={!validHeight}
              />
              <FormFeedback>1 decimal place only</FormFeedback>
            </FormGroup>
          </div>
          <div>
            <div className="box-label">Power</div>
            <Input
              name="power"
              type="select"
              value={productDetailsInputs.power}
              onChange={debounceDetailsUpdate}
            >
              <option value={460}>460V/3~/60HZ</option>
              <option value={208}>208V/3~/60HZ</option>
            </Input>
          </div>
          <div className="options-inputs">
            <div>
              <label className="box-label">
                <Input
                  name="backdraft_dampers"
                  type="checkbox"
                  checked={productDetailsInputs.backdraft_dampers || false}
                  onChange={debounceDetailsUpdate}
                /> Backdraft Dampers</label>
            </div>
            <div>
              <label className="box-label">
                <Input
                  name="over_one_hundred_percent_redundancy"
                  type="checkbox"
                  checked={!!productDetailsInputs.over_one_hundred_percent_redundancy}
                  onChange={debounceDetailsUpdate}
                /> Over 100% Redundancy?</label>
            </div>
            {/* <div>
              <label className="box-label">
                <Input
                  name="pressure_transducers"
                  type="checkbox"
                  checked={!!productDetailsInputs.pressure_transducers}
                  onChange={debounceDetailsUpdate}
                /> Pressure Transducers</label>
            </div>
            <div>
              <label className="box-label">
                <Input
                  name="mas_ec_plus"
                  type="checkbox"
                  checked={!!productDetailsInputs.mas_ec_plus}
                  onChange={debounceDetailsUpdate}
                /> MAS EC+</label>
            </div> */}
            <div className={!!productDetailsInputs.mas_ec_plus ? "collapse-open" : "collapse-closed"}>
              {/* <label className="radio-holder">
                <input 
                  type="radio" 
                  name="outdoor_rating" 
                  className="radio-button"
                  value="indoor"
                  checked={!productDetailsInputs.outdoor_rating} 
                  onChange={debounceDetailsUpdate}
                />
                Indoor
              </label>
              <label>
                <input 
                  type="radio" 
                  name="outdoor_rating" 
                  className="radio-button"
                  value="outdoor"
                  checked={!!productDetailsInputs.outdoor_rating} 
                  onChange={debounceDetailsUpdate}
                />
                Outdoor
              </label> */}
            </div>
            <div className={updateConfigPossible && isValid ? "update-holder" : "update-holder-closed"}>
              <button
                className="update-button"
                id="update-button"
                disabled={!expandedValid}
                onClick={updateProductConfig}>
                <FontAwesomeIcon icon={faPenToSquare} className="update-icon" />
                Update Product
              </button>
              <UncontrolledTooltip 
                placement="top" 
                target="update-button"
                disabled={expandedValid}>
                Cannot update product settings because they are not valid for selected configuration. If you wish to save new product settings, please select a new configuration.
              </UncontrolledTooltip>
            </div>
            <div className="input-section">
              Priority fans:
              <ul>
                {
                  featuredFans.map(model => <li key={`featured-${model}`}>{model}</li>)
                }
              </ul>
            </div>
          </div>
          <hr/>
          <div className="display-inputs">
            <div>
              <label className="box-label">
                <Input name="card_limit_checkbox" 
                  type="checkbox" 
                  checked={!!configCardLimit.active}
                  onChange={(e) => setConfigCardLimit({"active":e.target.checked,"amount":10})}
                /> Declutter results <FontAwesomeIcon id="declutter-tip" icon={faCircleQuestion}/>
                <UncontrolledTooltip placement="right" target="declutter-tip">
                  <strong>When this checkbox is selected:</strong>
                  <ul style={{textAlign : "left"}}>
                    <li>Only the first 10 configurations maximum will be returned</li>
                    <li>Only the 2 configurations with the smallest amount of fans for each model of fan will be returned</li>
                    {/* <li>For <strong>Rosenberg</strong> fans, only the single configurations with the smallest amount for each type of fan will be returned</li> */}
                  </ul>
                </UncontrolledTooltip>
              </label>
            </div>
          </div>
        </div>
        <div className="filters-container">
          {filtersOpen ? (!viewExpanded &&
            <>
              <div className="filters-holder">
                <div>
                  <div className="box-label">Manufacturer</div>
                  <Input 
                    type="select"
                    name={"fanManufacturer"}
                    onChange={debounceFilterUpdate}>
                      <option>Any</option>
                      <option>Rosenberg</option>
                      <option>Ziehl-Abegg</option>
                  </Input>
                </div>
                <hr/>
                <div className="box-label">Select Filter(s):</div>
                <div className="checkbox-holder">
                  {visibleFilters.map((filter, index) => (
                    <FilterCheckbox
                      key={`filter-checkbox-${filter.name}`}
                      isChecked={filter.checked}
                      checkHandler={() => updateVisibleFilters(index)}
                      label={filter.name}
                      index={index}
                    />
                  ))}
                </div>
                <hr/>
                  {visibleFilters.map((filter, index) => (
                    filter.checked ?
                    <FilterInput
                      key={`filter-input-${filter.name}`}
                      name={filter.name}
                      configName={filter.configName}
                      placeholder={filter.placeholder}
                      minimumVal={filtersInputs[filter.minimum] ?? ''}
                      maximumVal={filtersInputs[filter.maximum] ?? ''}
                      changeHandler={debounceFilterUpdate}
                      index={index}
                      minMaxBoth={filter.limitMaxMinBoth}
                    /> : <></>
                  ))}
              </div>
                <div className="filters-sidebar" onClick={() => {setFiltersOpen(!filtersOpen)}}>
                  <div className="filters-arrow arrow-right"><FontAwesomeIcon className="separator" icon={faAngleRight} /></div>
                  <div className="filters-sidebar-text">Filters</div>
                </div>
            </>
          ) : (!viewExpanded &&
            <div className="filters-sidebar" onClick={() => {setFiltersOpen(!filtersOpen)}}>
              <div className="arrow-down"><FontAwesomeIcon className="separator" icon={faAngleRight} /></div>
              <div className="filters-sidebar-text-down">Filters</div>
            </div>
          )}
        </div>
        <div className="configurations-container">
          {productLoading ? (
            <div style={{ paddingTop: 30 }}>
              <Spinner>Loading...</Spinner>
            </div>
            // ) : productError ? (
            //   <Alert color="danger">{productError.message}</Alert>
          ) : (
            <ConfigurationResults
              product={productDetails}
              sort={sort}
              order={order}
              selectedConfigurationId={selectedConfigurationId}
              selectedFanCount={selectedFanCount}
              setExpandedDetails={setExpandedDetails}
              expandedDetails={expandedDetails}
              onSelect={onSelect}
              declutter={configCardLimit.active}
              filtersConfig={filters}
              activeFilters={visibleFilters}
              filtersOpen={filtersOpen}
              viewExpanded={viewExpanded}
              setViewExpanded={setViewExpanded}
              expandedValid={expandedValid}
              setExpandedValid={setExpandedValid}
              />
          )}
        </div>
      </div>
    </div >
  );
};

const FilterCheckbox = ({ isChecked, label, checkHandler, index }) => {
  return (
  <div className="filter-toggle">
    <Input 
      type="checkbox" 
      id={`checkbox-${index}`}
      checked={isChecked}
      onChange={checkHandler}
    />
    <label htmlFor={`checkbox-${index}`}>{label}</label>
  </div>
)};

const FilterInput = ({ name, placeholder = "", minimumVal, maximumVal, index, configName, changeHandler, minMaxBoth}) => {
  return (
  <div>
    <div className="box-label">{name}</div>
    <div className="filter-number-two-wide">
      { (minMaxBoth === "min" || minMaxBoth === "both") && (
      <FormGroup className="filter-number-holder">
        <label htmlFor={`filter-min-${index}`}>Minimum</label>
        <Input 
          key={`filter-min-${index}`} 
          name={`${configName}Min`}
          type="number"
          value={minimumVal}
          placeholder={placeholder}
          onChange={changeHandler}
        ></Input>
      </FormGroup>
      )}
      { (minMaxBoth === "max" || minMaxBoth === "both") && (
      <FormGroup className="filter-number-holder">
        <label htmlFor={`filter-max-${index}`}>Maximum</label>
        <Input 
          key={`filter-max-${index}`} 
          name={`${configName}Max`}
          type="number"
          value={maximumVal}
          placeholder={placeholder}
          onChange={changeHandler}
        ></Input>
      </FormGroup>
      )}
    </div>
  </div>
)};

export default PossibleConfigurations;
