import React, { useCallback, useEffect, useState } from 'react';
import {
  Spinner,
} from "reactstrap";
import ConfigurationCard from "../components/ConfigurationCard";

import {
  Configuration,
  Control_Panel_Sizing,
  GetConfigurationsQuery,
  Product_Set_Input,
  useGetConfigurationsQuery,
  useGetControlPanelSizingQuery,
  useGetCostsQuery,
  useGetFanFitsQuery,
} from "../generated/graphql";

import { calculateEfficiency, calculateFanArrayWeight, calculateMCA, calculateMOCP, calculateMaximumNumberOfFans, calculateOperatingInputHorserpower, calculateProductCost, calculateRPMSoundPower, calculateRedundancy, calculateSoundPowerDischargeSum, calculateSoundPowerReturnSum, iterateFanCount } from "../helpers/generate-configuration-query";
import { RPMCoefficient } from "../helpers/file-checker";
import { DesignParameters, findMaxCFM } from "../helpers/fan-curve";
import parseMoney from "../helpers/money-utils";
import ExpandedConfigurationDetails from './ExpandedConfigurationDetails';
import configFilters, { shownFilters, ConfigFilter } from '../helpers/config-filters';
import { getPanelAll } from '../helpers/calculate-custom-controls';

interface Config {
  configuration: Configuration,
  designIteration: DesignParameters,
  fla: number,
  mca: number,
  mocp: number,
  inputPower: number,
  efficiency: number,
  redundancy: number,
  inletSound: number,
  outletSound: number,
  sizing?: Control_Panel_Sizing | undefined,
  unitCost?: number,
  arrayWeight?: number,
}

const ConfigurationResults = ({
  product,
  sort,
  order,
  selectedConfigurationId,
  selectedFanCount,
  onSelect,
  expandedDetails,
  setExpandedDetails,
  declutter,
  filtersConfig,
  activeFilters,
  viewExpanded,
  setViewExpanded,
  filtersOpen,
  expandedValid,
  setExpandedValid,
}: {
  product: Product_Set_Input;
  sort: string;
  order: string;
  selectedConfigurationId: number | null;
  selectedFanCount: number | null;
  onSelect: (configuration: Configuration | null, fanCount: number | null) => void;
  expandedDetails: {
    configuration: Configuration;
    fanCount: number;
    sizing?: Control_Panel_Sizing
  } | null,
  setExpandedDetails: Function;
  declutter: boolean;
  filtersConfig: ConfigFilter;
  activeFilters: typeof shownFilters;
  viewExpanded: boolean;
  setViewExpanded: Function;
  filtersOpen: boolean;
  expandedValid : boolean;
  setExpandedValid: Function;
}) => {
  const [configurations, setConfigurations] = useState<Config[]>([]);
  const { data: panelSizing } = useGetControlPanelSizingQuery();
  const { data: fanFitData } = useGetFanFitsQuery();
  const { data: costs } = useGetCostsQuery();

  const onQueryCompleted = useCallback((data: GetConfigurationsQuery) => {
    let possibleConfigurations: Config[] = [];

    for (let configuration of data?.configuration || []) {
      const fanFit = fanFitData?.fan_fit.find((f) => f.fan_diameter === configuration.blade_diameter && f.manufacturer === configuration.manufacturer);
      const fanCount = calculateMaximumNumberOfFans(
        {
          width: product.width || 0,
          height: product.height || 0,
          backdraft_dampers: product.backdraft_dampers || false,
        },
        configuration,
        fanFit
      );

      const rawCoefficients = !!configuration?.rmp_coefficients
        ? JSON.parse(configuration?.rmp_coefficients)
        : null;

      let coefficients: RPMCoefficient[] = rawCoefficients
        ? Object.values(rawCoefficients)
        : [];

      coefficients = product.backdraft_dampers ?
        coefficients.map(c => Object.assign({}, c, {
          pressure: {
            a: c.pressure.a,
            b: c.pressure.b,
            c: c.pressure.c,
            d: c.pressure.d - 0.3
          }
        })) :
        coefficients;

      const fla = configuration.fla_460 ?? configuration.fla_400;

      const designIterations = iterateFanCount(
        product?.air_flow ?? 0,
        product.total_static_pressure ?? 0,
        fanCount,
        coefficients,
        configuration.model
      );

      // const maxRPM = Math.max(...(Object.keys(coefficients).map((k) => parseInt(k))));
      const maxRPMCoefficients = coefficients.sort((a, b) => b.RPM - a.RPM)[0]; //.find((c: RPMCoefficient) => c.RPM === maxRPM)
      const designConfigurations = designIterations.map((di) => {
        const maxCFM = maxRPMCoefficients
          ? findMaxCFM({
            tsp: product.total_static_pressure ?? 0,
            coefficient: maxRPMCoefficients.pressure,
          })
          : 0;

        const iterationSp = di.lower.coefficient && di.upper.coefficient && calculateRPMSoundPower(di.fanCount, product.air_flow / di.fanCount, di.lower.coefficient, di.upper.coefficient, di.interpolation);
        const horsepower = di.upper.coefficient?.powerHp && di.lower.coefficient?.powerHp ? calculateOperatingInputHorserpower(
          product.air_flow,
          di.fanCount || 0,
          di.lower.coefficient?.powerHp,
          di.upper.coefficient?.powerHp,
          di.interpolation
        ) : 0;

        return ({
          configuration,
          designIteration: di,
          fla,
          mca: calculateMCA({ fla, fanCount: di.fanCount }),
          mocp: calculateMOCP({ fla, fanCount: di.fanCount }),
          inletSound: calculateSoundPowerReturnSum(iterationSp),
          outletSound: calculateSoundPowerDischargeSum(iterationSp),
          inputPower: horsepower,
          efficiency: calculateEfficiency({
            fanCount: di.fanCount ?? 0,
            tsp: product.total_static_pressure ?? 0,
            cfm: product?.air_flow ?? 0,
            horsepower,
          }),
          redundancy: calculateRedundancy({
            maxCFM,
            fanCount: di.fanCount,
            designCFM: product?.air_flow ?? 0,
          })
        });
      });

      possibleConfigurations = possibleConfigurations.concat(designConfigurations as any);
    }

    let foundExpandedConfig = false;

    possibleConfigurations = possibleConfigurations.map((pc) => {
      // needing custom size depends on fan count & amperage
      const sizing = panelSizing?.control_panel_sizing.find(
        (p) => (p.fan_count === pc.designIteration.fanCount && p.amperage === pc.mocp)
      );

      let customSizing : Control_Panel_Sizing = null;
      // If we don't find a sizing, need to calculate cost otherwise
      if (sizing === undefined){
        // If there are control panels with same number of fans, use that price
        customSizing = panelSizing?.control_panel_sizing.find(
          (p) => (p.fan_count === pc.designIteration.fanCount)
        );
        if (customSizing !== undefined && customSizing !== null){
        } else {
          // Otherwise we need to get max price, then add 100 for each fan over that amount
          let maxFans = 0;
          panelSizing?.control_panel_sizing.forEach(element => {
            if (element.fan_count > maxFans) maxFans = element.fan_count;
          });
          customSizing = panelSizing?.control_panel_sizing.find((p) => p.fan_count === maxFans);
        }
      }

      const productPanel = getPanelAll(product.configured_fan_count, pc.mocp, panelSizing?.control_panel_sizing, product.mas_ec_plus, product.outdoor_rating);
      
      const expandedSizing : Control_Panel_Sizing = sizing ?? customSizing;

      if (selectedConfigurationId === pc.configuration.id && selectedFanCount === pc.designIteration.fanCount) {
        setExpandedDetails({
          configuration: pc.configuration,
          sizing: expandedSizing,
          fanCount: pc.designIteration.fanCount
        });
        foundExpandedConfig = true;
      }

      return ({
        ...pc,
        sizing: expandedSizing,
        arrayWeight: calculateFanArrayWeight({
          fanCount: pc.designIteration.fanCount,
          fanWeight: pc.configuration.fan_weight,
          panelWeight: productPanel.sizing.weight || 0,
          bulkheadHeight: product.height || 0,
          bulkheadWidth: product.width || 0,
        }),
        unitCost: calculateProductCost({
          fanCost: parseMoney(pc.configuration?.price) ?? 0,
          fanCount: pc.designIteration.fanCount ?? 1,
          controlPanelCost: productPanel.cost,
          bulkheadHeight: product.height ?? 0,
          bulkheadWidth: product.width ?? 0,
          costPerSQFT:
            parseMoney(costs?.cost?.find((c) => c.name === "Bulkhead_Cost_per_SQFT")?.value) ?? 0,
          miscFlatCost:
            parseMoney(costs?.cost?.find((c) => c.name === "Misc_Flat")?.value) ?? 0,
          miscVariableCost:
            parseMoney(costs?.cost?.find((c) => c.name === "Misc_Variable:")?.value) ?? 0,
          hasTransducers: product.pressure_transducers ?? false,
          hasDampers: product.backdraft_dampers ?? false,
          damperCost:
            parseMoney(costs?.cost?.find((c) => c.name === "Backdraft_Damper")?.value) ?? 250,
        })
      });
    });
    
    // In case we havent chosen a configuration yet, set as valid so we can edit the properties
    if (selectedConfigurationId === null){
      foundExpandedConfig = true;
    }

    // Sort
    switch (sort) {
      case "count":
        possibleConfigurations.sort((a, b) => a.designIteration.fanCount - b.designIteration.fanCount);
        break;
      case "price":
        possibleConfigurations.sort((a, b) => (a?.unitCost ?? 0) - (b?.unitCost ?? 0));
        break;
      case "weight":
        possibleConfigurations.sort((a, b) => (a?.arrayWeight ?? 0) - (b?.arrayWeight ?? 0));
        break;
      case "efficiency":
        possibleConfigurations.sort((a, b) => a.efficiency - b.efficiency);
        break;
      case "redundancy":
        possibleConfigurations.sort((a, b) => a.redundancy - b.redundancy);
        break;
      case "fla":
        possibleConfigurations.sort((a, b) => a.fla - b.fla);
        break;
      case "mca":
        possibleConfigurations.sort((a, b) => a.mca - b.mca);
        break;
      case "mocp":
        possibleConfigurations.sort((a, b) => a.mocp - b.mocp);
        break;
      case "motor_power":
        possibleConfigurations.sort((a, b) => a.configuration.nominal_hp - b.configuration.nominal_hp);
        break;
      case "input_power":
        possibleConfigurations.sort((a, b) => a.inputPower - b.inputPower);
        break;
      case "inlet_sound":
        possibleConfigurations.sort((a, b) => a.inletSound - b.inletSound);
        break;
      case "outlet_sound":
        possibleConfigurations.sort((a, b) => a.outletSound - b.outletSound);
        break;
    }

    if (order === "desc") possibleConfigurations.reverse();

    setConfigurations(possibleConfigurations)

    setExpandedValid(foundExpandedConfig);

  }, [selectedConfigurationId, sort, order, setExpandedValid, fanFitData?.fan_fit, product.width, product.height, product.backdraft_dampers, product.air_flow, product.total_static_pressure, product.configured_fan_count, product.mas_ec_plus, product.outdoor_rating, product.pressure_transducers, panelSizing?.control_panel_sizing, selectedFanCount, costs?.cost, setExpandedDetails]);

  const { loading, refetch, error } = useGetConfigurationsQuery({
    variables: { voltage: product?.power },
    onCompleted: onQueryCompleted
  });

  useEffect(() => {
    refetch({
      voltage: product?.power
    }).then(({ data }) => {
      onQueryCompleted(data)
    }).catch((error) => {
      console.error(error);
    });
  }, [product, refetch, onQueryCompleted])

  const minFansAmt = configFilters.minFansPerModel(configurations);

  if (loading) return <div style={{ marginTop: 30 }}><Spinner>Loading...</Spinner></div>;
  if (error) return <div />;
  if (!configurations.length)
    return <div className="empty">No configurations found.</div>;
  if (!fanFitData?.fan_fit.length)
    return <div className="empty">No fan fits found.</div>;

  const expandView = function(input: any){
    setViewExpanded(true);
    setExpandedDetails(input);
  }

  return (
    <>
      {viewExpanded && (
        <ExpandedConfigurationDetails
          product={product}
          configuration={expandedDetails?.configuration}
          fanCount={expandedDetails?.fanCount}
          fanFit={fanFitData?.fan_fit.find(
            (f) =>
              f.fan_diameter === expandedDetails?.configuration.blade_diameter &&
              f.manufacturer === expandedDetails?.configuration.manufacturer
          )}
          sizing={expandedDetails?.sizing}
          allSizings={panelSizing?.control_panel_sizing}
          costs={costs?.cost ?? []}
          selectedConfigurationId={selectedConfigurationId}
          selectedFanCount={selectedFanCount}
          expandedValid={expandedValid}
        />
      )}
      {!viewExpanded && (<div className={!filtersOpen ? "configuration-list" : "configuration-list narrow-configurations"}>
        {
        configurations
          .filter((c) => !!product.over_one_hundred_percent_redundancy
            ? Math.round(Math.max(0.01, (c.redundancy * 100))) > 100
            : true)
          .filter(configFilters.customFilter(filtersOpen, activeFilters, filtersConfig))
          .filter(configFilters.declutterFilter(declutter, minFansAmt))
          .slice(0, declutter ? 10 : configurations.length )
          .map((c) => (
            <ConfigurationCard
              key={`${c.configuration.id}-${c.designIteration.fanCount}`}
              product={product}
              configuration={c.configuration as Configuration}
              designIteration={c.designIteration}
              controlPanel={c.sizing}
              mca={c.mca}
              mocp={c.mocp}
              unitCost={c.unitCost}
              arrayWeight={c.arrayWeight}
              inputPower={c.inputPower}
              redundancy={c.redundancy}
              efficiency={c.efficiency}
              inletSound={c.inletSound}
              outletSound={c.outletSound}
              selectedConfigurationId={selectedConfigurationId}
              selectedFanCount={selectedFanCount}
              onSelect={onSelect}
              onExpand={expandView}
            />
          ))}
      </div>)}
    </>
  );
};

export default ConfigurationResults;
