import { Control_Panel_Sizing, Product } from "../generated/graphql";
import { calculateInterpolatedFla, calculateMCA, calculateMOCP } from '../helpers/generate-configuration-query';
import { getPanelAll } from '../helpers/calculate-custom-controls';
import { round } from "lodash";

// Email helpers

const azureRoot = process.env.REACT_APP_AZURE_FUNCTIONS_URL || "";

const toList = "leo.orders@mas-hvac.com,x+1208404097774444@mail.asana.com";

const sendTransmitEmails = ({jobNumber, jobName, quoteName, jobId, username, modelNumbers, modelAmounts, controlsType}) =>{
    if (typeof(process.env.REACT_APP_ENV) !== "undefined" && !!process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "development"){
        console.log("No emails sent, just testing");
        return "testing";
    } 
    return fetch(`${azureRoot}/api/SendAlertEmail`, {
        method: "POST",
        body: JSON.stringify({
            mailerName: "Leo Orders - Automated Message",
            from: "leo.orders@mas-hvac.com",
            secret: "noIdeaIfThisWillWorkLol",
            toList,
            jobNumber,
            jobName,
            quoteName,
            jobId,
            username,
            modelNumbers,
            modelAmounts,
            controlsType,
            function: "transmit",
        }),
    }).then((response) => {
        if (!response.ok) console.error("Error:",response);
        else console.log("Successfully sent emails");
    }).catch((e) => {
        console.log("Error sending email");
    });
}

const sendUnTransmitEmails = ({jobNumber, jobName, quoteName, jobId}) =>{
    if (typeof(process.env.REACT_APP_ENV) !== "undefined" && !!process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "development"){
        console.log("No emails sent, just testing");
        return "testing";
    } 
    return fetch(`${azureRoot}/api/SendAlertEmail`, {
        method: "POST",
        body: JSON.stringify({
            mailerName: "Leo Orders - Automated Message",
            from: "leo.orders@mas-hvac.com",
            secret: "noIdeaIfThisWillWorkLol",
            toList,
            jobNumber,
            jobName,
            quoteName,
            jobId,
            function: "untransmit",
        })
    }).then((response) => {
        if (!response.ok) console.error("Error:",response);
        else console.log("Successfully sent emails");
    }).catch((e) => {
        console.log("Error sending email");
    });
}

// Other helpers

const magicNumberSerialStarting = 1175642;

const modelToNumberMap = {
    "N88-40327" : "01",
    "N88-45301" : "02",
    "N88-45316" : "03",
    "N88-50322" : "04",
    "N88-50701" : "05",
    "N88-56604" : "06",
    "N88-56605" : "07",
    "N88-56700" : "08",
    "N88-63314" : "09",
    "116553/A01" : "50",
    "116169/A01" : "51",
    "116175/A01" : "52",
    "117631/H01" : "53",
    "117050/A01" : "54",
    "116181/A01" : "55",
    "116165/A01" : "56",
    "116177/A01" : "57",
    null : "__",
};


const calculateModelNumber = (product: Product) => {
    if (product?.configuration === undefined || product?.configuration === null) return "INVALID_CONFIG";
    let modelNumber = "";

    // model number is made up of 11 components
    // 1  - Controls type
    if (product?.mas_ec_plus === true) modelNumber += "C";
    else if (product?.mas_ec_plus === false) modelNumber += "B";
    else modelNumber += "_";
    // 2  - Air flow orientation (chosen after lock)
    if (product?.air_flow_orientation !== null) modelNumber += product?.air_flow_orientation;
    else modelNumber += "_";
    // 3  - Dampers
    if (product?.backdraft_dampers) modelNumber += "1";
    else modelNumber += "0";
    
    modelNumber += "-";

    // 4  - Fan brand
    if (product?.configuration.manufacturer === "Rosenberg") modelNumber += "R";
    else if (product?.configuration.manufacturer === "Ziehl-Abegg") modelNumber += "Z";
    else modelNumber += "_";
    // 5  - Fan size/model
    if (modelToNumberMap.hasOwnProperty(product?.configuration.model)) modelNumber += modelToNumberMap[product?.configuration.model];
    else modelNumber += "__";

    modelNumber += "-";

    // 6  - Fan quantity
    if (!Number.isInteger(product.configured_fan_count)) modelNumber += "XX";
    else modelNumber += `${product.configured_fan_count}`.padStart(2, '0');
    // 7  - Fan layout (chosen after lock)
    if (product?.fan_layout !== null) modelNumber += product?.fan_layout;
    else modelNumber += "_";

    modelNumber += "-";

    // 8  - Voltage
    console.log("product.set_voltage",product.set_voltage);
    console.log("product.power",product.power);
    if (product?.voltage_override !== null){
        modelNumber += product?.voltage_override;
    } else if (product?.set_voltage !== null){
        if (product?.set_voltage === "460") modelNumber += "460";
        else if (product?.set_voltage === "230") modelNumber += "230";
        else if (product?.set_voltage === "208") modelNumber += "208";
        else modelNumber += "___";
    } else {
        if (product.power === 460) modelNumber += "460";
        else if (product.power === 208) modelNumber += "208";
        else modelNumber += "___";
    }

    modelNumber += "-";

    // 9  - Panel rating
    if (product?.outdoor_rating === true) modelNumber += "4";
    else modelNumber += "1";
    // 10 - BACnet (chosen after lock)
    if (product?.BACnet === null) modelNumber += "0";
    else if (product?.BACnet === "IP") modelNumber += "1";
    else if (product?.BACnet === "MSTP") modelNumber += "2";
    // 11 - HMI (chosen after lock)
    if (product?.local_HMI) modelNumber += "1";
    else modelNumber += "0";

    return modelNumber;
}

/**
 * Convert a 2D array into a CSV string
 */
function arrayToCsv(data: any[][]){
    return data.map(row =>
      row
      .map(String)  // convert every value to String
      .map((v: String) => v.replaceAll('"', '""'))  // escape double quotes
      .map((v: String) => `"${v}"`)  // quote it
      .join(',')  // comma-separated
    ).join('\r\n');  // rows starting on new lines
}

/**
 * Download contents as a file
 * Source: https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
 */
function downloadBlob(content: any, filename: string, contentType: any) {
    // Create a blob
    var blob = new Blob([content], { type: contentType });
    var url = URL.createObjectURL(blob);
  
    // Create a link to download it
    var pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', filename);
    pom.click();
    pom.remove();
}

const generateOrderingDoc = (products : Product[], jobName: string, quoteName: string, sizings: Control_Panel_Sizing[]) => {
    let productCsvAs2dArray = [["Leo Tag", "Unit Tag", "Mas Model Number", "MAS Serial Number", "Control Type", "Location", "Voltage", "Fan Quantity",
        "Fan FLA", "Fan HP", "MCA", "MOCP", "Panel Depth", "Panel Width", "Panel Height", "Fan Brand", "BACnet Protocol", "Local HMI",
        "Leo DRC Price", "Input DRC Price", "DRC Job Number"]];
    products.forEach(product => {
        // let configuredFla = product.configuration.fla_460 ?? product.configuration.fla_400;
        let fla = calculateInterpolatedFla(product.configuration.min_v_fla, product.configuration.max_v_fla, product.voltage_override);
        product.has_serial_number.forEach(serial=> {
            // new 
            let newRow = [];
            // - Leo Tag
            newRow.push(product.tag);
            // - Unit Tag
            newRow.push(!!serial.unit_tag ? serial.unit_tag : ""); 
            // - MAS model number 
            newRow.push(product.model_number);
            // - MAS serial number
            newRow.push(serial.serial_number);
            // EC basic or EC+
            newRow.push(product.mas_ec_plus ? "EC+" : "EC basic");
            // - Location (Indoor or Outdoor)
            if (product.outdoor_rating === true || !product.mas_ec_plus) newRow.push("Outdoor");
            else newRow.push("Indoor");
            // - Voltage
            newRow.push(product.voltage_override ?? product.set_voltage) 
            // - Fan Quantity
            newRow.push(product.configured_fan_count);
            // - Fan FLA
            // newRow.push(product.configuration.fla_460 ?? product.configuration.fla_400) 
            newRow.push(fla) 
            // - Fan HP (This is the nominal HP, not the operating HP)
            newRow.push(product.configuration.nominal_hp) 
            // - MCA
            // newRow.push(round(calculateMCA({fla: product.configuration.fla_460 ?? product.configuration.fla_400, fanCount: product.configured_fan_count}), 1));
            newRow.push(round(calculateMCA({fla: fla, fanCount: product.configured_fan_count}), 1));
            // - MOCP
            // const productMOCP = calculateMOCP({fla: product.configuration.fla_460 ?? product.configuration.fla_400, fanCount: product.configured_fan_count})
            newRow.push(calculateMOCP({fla: fla, fanCount: product.configured_fan_count}));
            // - Panel Size (Length, Width, Height)
            const productPanel = getPanelAll(product.configured_fan_count, calculateMOCP({fla: fla, fanCount: product.configured_fan_count}), sizings, product.mas_ec_plus);
            newRow.push(productPanel.sizing.depth);
            newRow.push(productPanel.sizing.width);
            newRow.push(productPanel.sizing.height);
            // - Fan Brand
            newRow.push(product.configuration.manufacturer);
            // - BACnet Protocol
            if (product.BACnet === null) newRow.push("None");
            else newRow.push(product.BACnet)
            // - Local HMI
            if (product.local_HMI === true) newRow.push(`10"`);
            else newRow.push("None");
            // - DRC Price (Leo)
            if(product.mas_ec_plus) newRow.push(productPanel.cost - 1750);
            else newRow.push(productPanel.cost);
            // - DRC Price
            newRow.push(!!serial.pricing ? serial.pricing : "");
            // - DRC Job Number
            newRow.push(!!serial.drc_job_number ? serial.drc_job_number : "");
            // Add row to csv
            productCsvAs2dArray.push(newRow);
        });
    });
    let csv = arrayToCsv(productCsvAs2dArray);
    const fileName = `[${jobName} - ${quoteName}] DRC Ordering.csv`;
    downloadBlob(csv, fileName, 'text/csv;charset=utf-8;');
}

const generateScheduleInfoDoc = (products : Product[], jobName: string, quoteName: string, quoteSoftware: string, quoteFirmware: string)  => {
    let productCsvAs2dArray = [["Leo Tag", "Unit Tag", "MAS Model Number", "MAS Serial Number", "Fan Brand", "Fan Model Number", "Fan Quantity", "Voltage",
        "FLA", "Fan HP", "MCA", "MOCP", "k Factor", "AFMS dP Max", "Total CFM", "CFM-PID-P", "CFM-PID-I", "Layout", "Firmware", "Software", "Communication Protocol"]];
    products.forEach(product => {
        let fla = calculateInterpolatedFla(product.configuration.min_v_fla, product.configuration.max_v_fla, product.voltage_override);
        product.has_serial_number.forEach(serial=> {
            let newRow = [];
            // - Leo Tag
            newRow.push(product.tag) 
            // - Unit Tag
            newRow.push(!!serial.unit_tag ? serial.unit_tag : ""); 
            // - MAS model number 
            newRow.push(product.model_number);
            // - MAS serial number 
            newRow.push(serial.serial_number);
            // - Fan Brand
            newRow.push(product.configuration.manufacturer) 
            // - Fan Model Number
            newRow.push(product.configuration.model) 
            // - Fan Quantity
            newRow.push(product.configured_fan_count) 
            // - Voltage
            newRow.push(product.voltage_override ?? product.set_voltage) 
            // - FLA
            // newRow.push(product.configuration.fla_460 ?? product.configuration.fla_400) 
            newRow.push(fla) 
            // - Fan HP (This is the nominal HP, not the operating HP)
            newRow.push(product.configuration.nominal_hp) 
            // - MCA 
            // newRow.push(round(calculateMCA({fla: product.configuration.fla_460 ?? product.configuration.fla_400, fanCount: product.configured_fan_count}),1));
            newRow.push(round(calculateMCA({fla: fla, fanCount: product.configured_fan_count}),1));
            // - MOCP
            // newRow.push(calculateMOCP({fla: product.configuration.fla_460 ?? product.configuration.fla_400, fanCount: product.configured_fan_count}));
            newRow.push(calculateMOCP({fla: fla, fanCount: product.configured_fan_count}));
            // - k Factor
            newRow.push(product.configuration.k_factor) 
            // - AFMS dP Max
            newRow.push(((product.air_flow / product.configured_fan_count) / product.configuration.k_factor) ** 2 > 4.5 ? "0 - 10" : "0 - 5") 
            // - Total CFM
            newRow.push(product.air_flow) 
            // - CFM-PID-P (Total CFM * 2)
            newRow.push((product.air_flow * 2)) 
            // - CFM-PID-I (Always listed as “20”)
            newRow.push(20) 
            // - Layout
            newRow.push(product.fan_layout) 
            // - Firmware
            if (product.mas_ec_plus) newRow.push(quoteFirmware);
            else newRow.push("");
            // - Software
            if (product.mas_ec_plus) newRow.push(quoteSoftware);
            else newRow.push("");
            // - Communication Protocol
            if (product.BACnet === null) newRow.push("None");
            else newRow.push(product.BACnet)
            // Add row to csv
            productCsvAs2dArray.push(newRow);
        });
    });
    const csv = arrayToCsv(productCsvAs2dArray);
    const fileName = `[${jobName} - ${quoteName}] Engineering Master.csv`;
    downloadBlob(csv, fileName, 'text/csv;charset=utf-8;');
}

const controlsTeamExportHelpers = {
    sendTransmitEmails,
    sendUnTransmitEmails,
    calculateModelNumber,
    magicNumberSerialStarting,
    generateOrderingDoc,
    generateScheduleInfoDoc,
};

export default controlsTeamExportHelpers;